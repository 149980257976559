import MyDropdown from "../MyDropdown"
import ViewTemplateDialog from "./dialogs/ViewTemplateDialog";
import { useEffect, useState } from "react";
import TemplateEditorDialog from "./dialogs/TemplateEditorDialog";
import useSubscriptionDetails from "../../hooks/useSubscriptionDetails";
import PublicTemplateSearchEngineVisibilityDialog from "../PublicTemplateSearchEngineVisibilityDialog";
import ShareTemplateDialog from "../dialogs/ShareTemplateDialog";
import ShareTemplateAccess from "../ShareTemplateAccess";
import DuplicateTemplateDialog from "./dialogs/DuplicateTemplateDialog";
import DeleteTemplateDialog from "./dialogs/DeleteTemplateDialog";
import ResetInstalledFromMarketplaceTemplateDialog from "./dialogs/ResetInstalledFromMarketplaceTemplateDialog";

const TemplateRowAction = ({ templates, setTemplates, iteratingTemplate, disabled = false, user, mode = "USER" }) => {
    const [selectedTemplate, setSelectedTemplate] = useState(iteratingTemplate);
    const [selectedTemplateStructure, setSelectedTemplateStructure] = useState([]);
    const [subscriptionDetail] = useSubscriptionDetails()

    useEffect(() => {
        setSelectedTemplate(iteratingTemplate);
    }, [iteratingTemplate])

    return (
        <MyDropdown position="right" disabled={disabled}>
            <div className='dropdown-item'>
                <ViewTemplateDialog
                    selectedTemplate={selectedTemplate}
                    selectedTemplateStructure={selectedTemplateStructure}
                    setSelectedTemplateStructure={setSelectedTemplateStructure}
                />
            </div>
            {!selectedTemplate?.is_shared
                &&
                <div className='dropdown-item'>
                    <TemplateEditorDialog
                        templates={templates}
                        setTemplates={setTemplates}
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                        selectedTemplateStructure={selectedTemplateStructure}
                        setSelectedTemplateStructure={setSelectedTemplateStructure}
                        user={user}
                        mode={mode}
                        subscriptionDetail={subscriptionDetail}
                    />
                </div>
            }
            {selectedTemplate?.is_public
                &&
                <div className='dropdown-item'>
                    <PublicTemplateSearchEngineVisibilityDialog
                        showAsLink={true}
                        template_id={selectedTemplate.template_id}
                        template_name={selectedTemplate.name}
                    />
                </div>
            }
            <div className='dropdown-item'>
                <ShareTemplateDialog
                    selectedUser={user}
                    showAsLink={true}
                    templateDetails={selectedTemplate}
                    onTemplateDetailsChange={data => {
                        setTemplates(prev => {
                            let updatedData = [...prev]
                            const indexOfSelectedTemplate = prev.findIndex(template => template.template_id === data.template_id)
                            updatedData[indexOfSelectedTemplate].is_sharing = data.is_sharing
                            return updatedData
                        })
                    }}
                />
            </div>
            {!selectedTemplate?.is_shared
                &&
                <div className='dropdown-item'>
                    <ShareTemplateAccess
                        showingShareTemplateModalAsLink={true}
                        template_id={selectedTemplate?.template_id}
                        is_shared={selectedTemplate?.is_shared}
                    />
                </div>
            }
            {!selectedTemplate?.is_shared
                &&
                <div className='dropdown-item'>
                    <DuplicateTemplateDialog
                        selectedTemplate={selectedTemplate}
                        setTemplates={setTemplates}
                    />
                </div>
            }
            {selectedTemplate?.was_installed_from_marketplace
                ?
                <div className='dropdown-item'>
                    <ResetInstalledFromMarketplaceTemplateDialog
                        selectedTemplate={selectedTemplate}
                        setTemplates={setTemplates}
                        user={user}
                    />
                </div>
                : ""
            }
            {!selectedTemplate?.is_shared
                &&
                <div className='dropdown-item'>
                    <DeleteTemplateDialog
                        selectedTemplate={selectedTemplate}
                        setTemplates={setTemplates}
                        user={user}
                    />
                </div>
            }
        </MyDropdown>
    )
}

export default TemplateRowAction