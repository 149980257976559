import React, { useContext, useState } from 'react';
import ProtectedAxios from '../api/protectedAxios';
import { toast } from 'react-hot-toast';
import { UserContext } from '../context/UserProvider';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { Dialog } from './ui/Dialog';

const DeleteUserButton = ({ showAsIcon = true, fetchAllUsers, selectedUser }) => {
    const [user] = useContext(UserContext)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true);

    const [deletingUser, setDeletingUser] = useState(false)

    const deleteUserAccount = async (e) => {
        e.preventDefault()
        setDeletingUser(true)
        ProtectedAxios.post('/admin/deleteUser', { user_id: user.user_id, selected_user_id: selectedUser.user_id, stripe_customer_id: selectedUser.stripe_customer_id })
            .then(res => {
                if (res.data) {
                    setDeletingUser(false)
                    toast.success(`account for user - '${selectedUser.name}' deleted successfully`)
                    fetchAllUsers()
                }
            })
            .catch(err => {
                setDeletingUser(false)
                console.log(err);
                toast.error("could not delete child account at the moment, please try again later.")
            })
    }

    return (
        <Dialog
            open={show}
            onOpenChange={handleClose}
            title="Delete user"
            hideFooter
            hideTrigger={false}
            trigger={
                showAsIcon
                    ?
                    <button className='edit-btn' id='share-button' title='Delete User' onClick={e => { handleShow() }}><RiDeleteBin5Line className='edit-icon' /></button>
                    :
                    <div
                        onClick={handleShow}
                    >
                        Delete User
                    </div>
            }
            body={
                <form onSubmit={deleteUserAccount}>
                    <div className='px-2 mt-2'>
                        <p>
                            <b>Are you sure you want to delete this user?</b>
                            <br />
                            <br />
                            Name: {selectedUser.name}
                            <br />
                            Username: {selectedUser.username}
                            <br />
                            Plan: {selectedUser.plan}
                        </p>
                    </div>
                    <button type='submit' className='button button-danger d-flex ms-auto gap-2 align-items-center mb-3' disabled={deletingUser}>
                        {deletingUser
                            &&
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="sr-only"></span>
                            </div>
                        }
                        Yes, Delete
                    </button>
                </form>
            }
        />
    )
}

export default DeleteUserButton