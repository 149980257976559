import { useState } from 'react';
import { Dialog } from '../../ui/Dialog';
import { RenderSelectedTemplateDetailsForm, renderSelectedTemplateElements, renderSelectedTemplatePreview, renderTemplateElements, setTemplateStructure, verifyTemplateBuilderStep } from '../utils';
import ProtectedAxios from '../../../api/protectedAxios';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { BsArrowLeftShort, BsArrowRightShort } from 'react-icons/bs';
import ConfirmTemplateBuilderClose from './ConfirmTemplateBuilderClose';

const TemplateEditorDialog = ({
    templates,
    setTemplates,
    selectedTemplate,
    setSelectedTemplate,
    selectedTemplateStructure,
    setSelectedTemplateStructure,
    user,
    mode,
    subscriptionDetail,
}) => {
    const [dialogState, setDialogState] = useState(false);
    const [savingTemplate, setSavingTemplate] = useState(false);
    const [templateBuilderError, setTemplateBuilderError] = useState("")
    const [verifyingTemplateStep, setVerifyingTemplateStep] = useState(false)
    const [templateEditorStep, setTemplateEditorStep] = useState(0);
    const [templateEditorCloseConfirmationDialog, setTemplateEditorCloseConfirmationDialog] = useState(false)

    const handleSelectedTemplateItemSort = (e) => {
        const dragItemIndex = e.dragData.index

        const targetElementId = e.target.querySelector(".template-element").getAttribute("id")
        const dragOverItemIndex = parseInt(targetElementId.split("-")[targetElementId.split("-").length - 1])

        // duplicate structure
        let new_structure = [...selectedTemplateStructure]

        //remove and save the dragged item content
        const draggedItemContent = new_structure.splice(dragItemIndex, 1)[0]

        //switch the position
        new_structure.splice(dragOverItemIndex, 0, draggedItemContent)

        //update the actual state
        setSelectedTemplateStructure(new_structure)

        e.target.scrollIntoView()
    }

    const updateTemplate = async (e) => {
        e.preventDefault()

        //check if company is selected
        if ((user.role_id === 1 || selectedTemplate.isOfficial) && (user.is_official_template_creator || user.role_id === 1) && selectedTemplate.selectedCompany === null) {
            toast.error("Official templates must have a company selected");
            return;
        }

        if (selectedTemplate.name.length > 200) {
            toast.error("name must be below 200 characters")
            return
        }
        if (selectedTemplate.description.length > 500) {
            toast.error("description must be below 500 characters")
            return
        }

        setSavingTemplate(true)
        let prompt = JSON.stringify(selectedTemplateStructure)

        let isEnterpriseAdmin =
            ((mode === "ADMIN" && user?.plan_id) ? user.plan_id === process.env.REACT_APP_PRICE_C_ID : subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_C_ID)
                ? true
                : false


        // using formData since want to add files too
        const formData = new FormData()
        formData.append('user_id', user.user_id)
        formData.append('selected_user_id', user.user_id)
        formData.append('selected_stripe_customer_id', user.stripe_customer_id)
        formData.append('selected_role_id', user.role_id)
        formData.append('isEnterpriseAdmin', isEnterpriseAdmin)
        formData.append('isSharingWithChildAccounts', selectedTemplate.isSharingWithChildAccounts)
        formData.append('prompt', prompt)
        formData.append('name', selectedTemplate.name)
        formData.append('description', selectedTemplate.description)
        formData.append('template_id', selectedTemplate.template_id)
        formData.append('showingAdditionalOptions', selectedTemplate.showing_additional_options)
        formData.append('selectedCategories', JSON.stringify(selectedTemplate.selectedCategories))
        formData.append('selectedCompany', JSON.stringify(selectedTemplate.selectedCompany))
        formData.append('isPublic', selectedTemplate.is_public)
        formData.append('notify_creator_on_use', selectedTemplate.notify_creator_on_use)
        formData.append('is_password_protected', selectedTemplate.is_password_protected)
        formData.append('password', selectedTemplate.password)

        selectedTemplateStructure.forEach((element, index) => {
            if ((element.type === 'pdf-context' || element.type === 'csv-context') && element.files) {
                formData.append(`files-${element.id}`, element.files)
            }
        })

        ProtectedAxios.post('/users/updateTemplate', formData)
            .then(res => {
                if (res.data) {
                    let indexOfSelectedTemplate = templates.findIndex(template => template.template_id === selectedTemplate.template_id);
                    setTemplates(prev => {
                        let updatedTemplates = [...prev];
                        const currentTimestamp = new Date().toISOString();
                        let updatedSelectedTemplate = {
                            ...selectedTemplate,
                            is_public: selectedTemplate.is_public,
                            password: selectedTemplate.is_public ? "" : selectedTemplate.password,
                            prompt: res.data.updated_prompt,
                            updated_at: currentTimestamp,
                            selectedCompany: selectedTemplate.selectedCompany,
                            company_name: selectedTemplate?.selectedCompany?.company_name,
                            company_url: selectedTemplate?.selectedCompany?.company_url,
                            selectedCategories: selectedTemplate.selectedCategories.sort((a, b) => {
                                return a.category.localeCompare(b.category);
                            }),
                        };
                        updatedTemplates[indexOfSelectedTemplate] = updatedSelectedTemplate;

                        return updatedTemplates;
                    });

                    handleConfirmCloseTemplateEditor();

                    setTemplateEditorStep(0)

                    setSavingTemplate(false)
                    setSelectedTemplate({ name: '', description: '', prompt: '', selectedCategories: [] })
                }
            })
            .catch(err => {
                console.log(err);
                setSavingTemplate(false)

                if (err.response.status === 403) {
                    return toast.error(<div><p className='m-0'>You are using premium elements. Remove the premium elements or upgrade to Standard plan.</p><div className='d-flex align-items-center gap-2'><button className='button-plain-text text-primary' onClick={() => setTemplateEditorStep(0)}><u>Edit</u></button> | <Link to="/upgrade">Upgrade</Link></div></div>)
                }

                toast.error(err.response.data.error || "could not update template at the moment, please try again later.")
            })
    }

    // const onBeforeUnload = (ev) => {
    //     ev.returnValue = "You have unsaved changes. Are you sure you want to leave?";
    //     return "You have unsaved changes. Are you sure you want to leave?";
    // };
    const showTemplateEditor = () => {
        setDialogState(true);
        // window.addEventListener("beforeunload", onBeforeUnload);
    }
    // Closes the template editor and shows confirm-close dialog
    const handleTemplateEditorDialogClose = async () => {
        setDialogState(false);
        setTemplateEditorCloseConfirmationDialog(true);
    };
    // Closes the confirm-close dialog and shows the template editor again
    const hideTemplateEditorCloseConfirmationDialog = () => {
        setDialogState(true);
        setTemplateEditorCloseConfirmationDialog(false);
    }
    // Closes the confirm-close dialog and also the template editor
    const handleConfirmCloseTemplateEditor = () => {
        setDialogState(false);
        setTemplateEditorCloseConfirmationDialog(false);
        // window.removeEventListener("beforeunload", onBeforeUnload);
    }

    return (
        <>
            <Dialog
                size="xl"
                open={dialogState}
                backdrop="static"
                onOpenChange={handleTemplateEditorDialogClose}
                hideTrigger={false}
                trigger={
                    <div
                        onClick={async () => {
                            await setTemplateStructure(selectedTemplate.template_id, setSelectedTemplateStructure, showTemplateEditor);
                        }}
                    >
                        Edit
                    </div>
                }
                title="Edit Template"
                bodyClass='p-0 rounded-bottom overflow-hidden'
                body={
                    <>
                        {templateEditorStep === 0
                            ?
                            <div id='template-builder'>
                                <div className='left-panel'>
                                    <h5>Add elements</h5>
                                    {renderTemplateElements(selectedTemplate, selectedTemplateStructure, setSelectedTemplateStructure, setTemplateBuilderError)}
                                </div>

                                <div className='right-panel'>
                                    {renderSelectedTemplateElements(selectedTemplateStructure, setSelectedTemplateStructure, handleSelectedTemplateItemSort, templateBuilderError, setTemplateBuilderError)}
                                </div>
                            </div>

                            :
                            <div id="template-builder">
                                <form className='left-panel p-0' onSubmit={updateTemplate}>
                                    <div className='form-container'>
                                        <h5>Set template details</h5>
                                        {RenderSelectedTemplateDetailsForm(selectedTemplate, setSelectedTemplate, user, mode, subscriptionDetail)}
                                    </div>

                                    <div className='sticky-footer d-flex justify-content-between align-items-center' id="sticky-footer">
                                        <button type="button" className='button-plain-text d-flex align-items-center justify-content-center gap-2 font-xxs' onClick={() => setTemplateEditorStep(0)}>
                                            <BsArrowLeftShort className='font-s' />
                                            Back
                                        </button>
                                        <div />
                                        <button className="button" disabled={savingTemplate}>
                                            {savingTemplate
                                                ?
                                                <>
                                                    <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                        <span className="sr-only"></span>
                                                    </div>
                                                </>

                                                : 'Update'
                                            }
                                        </button>
                                    </div>
                                </form>

                                <div className='right-panel template-elements-container h-100 overflow-scroll'>
                                    <h5>Preview</h5>
                                    <div>
                                        {renderSelectedTemplatePreview(selectedTemplateStructure, setSelectedTemplateStructure)}
                                    </div>
                                </div>
                            </div>
                        }
                    </>
                }
                footer={
                    <div className='d-flex flex-column-reverse flex-sm-row w-100  align-items-end align-items-sm-center justify-content-sm-between gap-2'>
                        <p className='m-0 font-us text-secondary text-end text-sm-start'><strong>Tip:</strong> drag an element up/down to change it's position.</p>
                        {templateEditorStep === 0
                            &&
                            <>
                                <span />
                                <button
                                    type="button"
                                    className='button d-flex align-items-center justify-content-center gap-2'
                                    onClick={() => {
                                        verifyTemplateBuilderStep(0, selectedTemplateStructure, setSelectedTemplateStructure, setTemplateEditorStep, setTemplateBuilderError, setVerifyingTemplateStep, selectedTemplate, setSelectedTemplate)
                                    }}
                                    disabled={verifyingTemplateStep}
                                >
                                    Next
                                    {verifyingTemplateStep
                                        ?
                                        <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                        :
                                        <BsArrowRightShort />
                                    }
                                </button>
                            </>
                        }
                    </div>
                }
                hideFooter={templateEditorStep === 1}
            />

            <ConfirmTemplateBuilderClose
                open={templateEditorCloseConfirmationDialog}
                onOpenChange={setTemplateEditorCloseConfirmationDialog}
                hideTemplateBuilderCloseConfirmationDialog={hideTemplateEditorCloseConfirmationDialog}
                handleConfirmCloseTemplateBuilder={handleConfirmCloseTemplateEditor}
                message={"Are you sure you want to close template editor?"}
            />
        </>
    )
}

export default TemplateEditorDialog;