import React, { useContext, useEffect, useRef, useState } from 'react'
import NewPrompt from '../components/NewChat'
import { UserContext } from '../context/UserProvider'
import Modal from 'react-bootstrap/Modal';
import Subscriptions from '../components/Subscriptions';
import ProtectedAxios from '../api/protectedAxios';
import { toast } from 'react-hot-toast';
import { HiClipboard, HiClipboardCheck } from 'react-icons/hi'
import { formatDate, formatDateWithDay } from '../utils/helper';
import { RiCloseLine, RiDeleteBin5Line, RiMailAddFill, RiMailCheckFill, RiMailCloseFill } from 'react-icons/ri';
import { GrFormAdd } from 'react-icons/gr';
import { FaPaperPlane, FaTicketAlt } from 'react-icons/fa';
import { MdBlock, MdMail, MdOutlineDone, MdOutlineError } from 'react-icons/md';
import AddOpenaiAPIkeyDialog from '../components/dialogs/AddOpenaiAPIkeyDialog';
import { AiOutlineMail, AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import AddGeminiAPIkeyDialog from '../components/dialogs/AddGeminiAPIkeyDialog';
import AddAzureOpenaiKeysDialog from '../components/dialogs/AddAzureOpenaiKeysDialog';
import { Helmet } from 'react-helmet';

const ManageEnterpriseChildAccounts = ({ selectedUser, setSelectedUser, showTitle = true }) => {
  const [user, setUser] = useContext(UserContext)

  const [subscriptionQuantity, setSubscriptionQuantity] = useState(null)
  const [fetchingSubscriptionQuantity, setFetchingSubscriptionQuantity] = useState(true)
  const [incrementQuantityBy, setIncrementQuantityBy] = useState(0)
  const [incrementingQuantity, setIncrementingQuantity] = useState(false)

  const [addInvitesModal, setAddInvitesModal] = useState(false)
  const closeAddInviteModal = () => { setAddInvitesModal(false) }
  const showAddInviteModal = () => { handleClose(); setAddInvitesModal(true) }


  const [fetchingChildInvites, setFetchingChildInvites] = useState(true)
  const [childInvites, setChildInvites] = useState([])
  const [backupChildInvites, setBackupChildInvites] = useState([])
  const [selectedChildInvite, setSelectedChildInvite] = useState(null)
  const [deletingChildInvites, setDeletingChildInvites] = useState(false)

  const [showOpenaiApiKeyModal, setShowOpenaiApiKeyModal] = useState(false)
  const [showGeminiApiKeyModal, setShowGeminiApiKeyModal] = useState(false)
  const [showAzureOpenaiKeysModal, setShowAzureOpenaiKeysModal] = useState(false)

  const [fetchingChildAccounts, setFetchingChildAccounts] = useState(true)
  const [childAccounts, setChildAccounts] = useState([])
  const [selectedChildAccount, setSelectedChildAccount] = useState(null)
  const [deletingChildAccount, setDeletingChildAccount] = useState(false)

  const [newChildEmail, setNewChildEmail] = useState('')
  const [showingList, setShowingList] = useState(false)
  const [newChildEmails, setNewChildEmails] = useState([])
  let successfulEmailCounts = 0
  const [inviteSuccess, setInviteSuccess] = useState([])
  const [invitingUser, setInvitingUser] = useState(false)
  const [previouslySentInviteId, setPreviouslySendInviteId] = useState('')
  const [error, setError] = useState('')

  const [show, setShow] = useState(false)
  const handleClose = () => { setShow(false) }
  const handleShow = () => setShow(true)

  const [searchChildInviteEmailtext, setSearchChildInviteEmailText] = useState('')
  const [invitesModal, setInvitesModal] = useState(false)
  const hideInvitesModal = () => { setInvitesModal(false); setChildInvites(backupChildInvites); setSearchChildInviteEmailText("") }
  const showInvitesModal = () => setInvitesModal(true)

  const [inviteErrors, setInviteErrors] = useState([])
  const [backupInviteErrors, setBackupInviteErrors] = useState([])
  const [searchChildInviteErrorEmailtext, setSearchChildInviteErrorEmailText] = useState('')
  const [inviteErrorModal, setInviteErrorModal] = useState(false)
  const hideInviteErrorModal = () => { setInviteErrorModal(false) }
  const showInviteErrorModal = () => setInviteErrorModal(true)

  let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  // const [selectedUser, setSelectedUser] = useState(null)
  const [togglingUserStatus, setTogglingUserStatus] = useState(false)


  //prevents input of -, + or e input field of text
  const countInputRef = useRef(null)
  var invalidChars = [
    "-",
    "+",
    "e",
  ];
  useEffect(() => {
    countInputRef.current?.addEventListener("keydown", function (e) {
      if (invalidChars.includes(e.key)) {
        e.preventDefault();
      }
    });
  }, [countInputRef])

  useEffect(() => {
    fetchSubscriptionQuantity()
    fetchChildInvites()
    fetchChildAccounts()
  }, [])

  const fetchSubscriptionQuantity = async () => {
    setFetchingSubscriptionQuantity(true)
    ProtectedAxios.post('/users/enterprise/fetchSubscriptionQuantity', { customer_id: user.stripe_customer_id, user_id: user.user_id, selected_customer_id: selectedUser?.stripe_customer_id, selected_user_id: selectedUser?.user_id })
      .then(res => {
        setSubscriptionQuantity(res.data.quantity)
        setFetchingSubscriptionQuantity(false)
      })
      .catch(err => {
        setFetchingSubscriptionQuantity(false)
        console.log(err);
        toast.error("could not fetch your invite counts at the moment, please try again later.")
      })
  }
  const updateSubscriptionQuantity = async () => {
    setIncrementingQuantity(true)
    ProtectedAxios.post('/users/enterprise/updateSubscriptionQuantity', { customer_id: user.stripe_customer_id, selected_customer_id: selectedUser?.stripe_customer_id, incrementQuantityBy: incrementQuantityBy })
      .then(res => {
        if (res.data) {
          setIncrementingQuantity(false)
          fetchSubscriptionQuantity()
          toast.success(`added +${incrementQuantityBy} more invites in your account`)
          closeAddInviteModal()
          setIncrementQuantityBy(0)
        }
      })
      .catch(err => {
        setIncrementingQuantity(false)
        console.log(err);
        toast.error("could not fetch your invite counts at the moment, please try again later.")
      })
  }

  const fetchChildInvites = async () => {
    setFetchingChildInvites(true)
    ProtectedAxios.post('/users/enterprise/childInvites', { enterprise_admin_user_id: selectedUser?.user_id ? selectedUser.user_id : user.user_id })
      .then(res => {
        if (res.data) {
          setChildInvites(res.data)
          setBackupChildInvites(res.data)
          setFetchingChildInvites(false)
        }
      })
      .catch(err => {
        setFetchingChildInvites(false)
        console.log(err);
        toast.error("could not fetch your child invites at the moment, please try again later.")
      })
  }

  const deleteChildInvites = async (enterprise_child_invite_id) => {
    setDeletingChildInvites(true)
    ProtectedAxios.post('/users/enterprise/deleteChildAccountInvite', { enterprise_admin_user_id: selectedUser?.user_id ? selectedUser.user_id : user.user_id, enterprise_child_invite_id: enterprise_child_invite_id })
      .then(res => {
        if (res.data) {
          setChildInvites(res.data)
          setBackupChildInvites(res.data)
          fetchSubscriptionQuantity()
          setDeletingChildInvites(false)
        }
      })
      .catch(err => {
        setDeletingChildInvites(false)
        console.log(err);
        toast.error("could not delete child invite at the moment, please try again later.")
      })
  }

  //search child invites - modal
  useEffect(() => {
    filterChildInvitesList()
  }, [searchChildInviteEmailtext])
  const filterChildInvitesList = () => {
    if (searchChildInviteEmailtext.length > 0) {
      const updatedList = backupChildInvites.filter(invite => {
        return invite.child_email.toLowerCase().includes(searchChildInviteEmailtext.toLowerCase())
      })
      setChildInvites(updatedList)
    } else {
      setChildInvites(backupChildInvites)
    }
  }

  //search child invites error - modal
  useEffect(() => {
    filterChildInviteErrorList()
  }, [searchChildInviteErrorEmailtext])
  const filterChildInviteErrorList = () => {
    if (searchChildInviteErrorEmailtext.length > 0) {
      const updatedList = backupInviteErrors.filter(error => {
        return error.email.toLowerCase().includes(searchChildInviteErrorEmailtext.toLowerCase())
      })
      setInviteErrors(updatedList)
    } else {
      setInviteErrors(backupInviteErrors)
    }
  }



  const fetchChildAccounts = async () => {
    setFetchingChildAccounts(true)
    ProtectedAxios.post('/users/enterprise/childAccounts', { enterprise_admin_user_id: selectedUser?.user_id ? selectedUser.user_id : user.user_id })
      .then(res => {
        if (res.data) {
          setChildAccounts(res.data)
          setFetchingChildAccounts(false)
        }
      })
      .catch(err => {
        setFetchingChildAccounts(false)
        console.log(err);
        toast.error("could not fetch your child accounts at the moment, please try again later.")
      })
  }
  const deleteChildAccount = async (user_id, stripe_customer_id) => {
    setDeletingChildAccount(true)
    ProtectedAxios.post('/users/enterprise/deleteChildAccount', { enterprise_admin_user_id: selectedUser?.user_id ? selectedUser.user_id : user.user_id, enterprise_child_user_id: user_id, enterprise_child_stripe_customer_id: stripe_customer_id })
      .then(res => {
        if (res.data) {
          setChildAccounts(res.data)
          fetchSubscriptionQuantity()
          setDeletingChildAccount(false)
        }
      })
      .catch(err => {
        setDeletingChildAccount(false)
        console.log(err);
        toast.error("could not delete child account at the moment, please try again later.")
      })
  }

  const handleEmailChange = (emailValue) => {
    setNewChildEmail(emailValue)
    if (emailRegex.test(emailValue)) {
      setShowingList(true)
      // setNewChildEmails(prev => prev.push(emailValue))
    }
    else {
      setShowingList(false)
    }
  }

  const addInInviteList = () => {
    if (emailRegex.test(newChildEmail) && !newChildEmails.includes(newChildEmail)) {
      let tempEmail = newChildEmails
      tempEmail.unshift(newChildEmail)
      setNewChildEmails(tempEmail)

      setNewChildEmail("")
      setShowingList(false)

      document.getElementById("invite-email").focus()
    }
  }


  const removeEmailFormInviteList = email => {
    setNewChildEmails(newChildEmails.filter(childEmail => childEmail !== email))
  }


  const sendInvites = () => {
    successfulEmailCounts = 0
    let tempChildEmails = newChildEmails
    tempChildEmails.forEach(async (email, i) => {
      await verifyEnterpriseInviteEmail(email, i)
    })
  }


  const verifyEnterpriseInviteEmail = async (_email, i) => {
    setInvitingUser(true)
    await ProtectedAxios.post('/users/enterprise/verifyEnterpriseChildInviteEmail', { enterprise_admin_user_id: selectedUser?.user_id ? selectedUser.user_id : user.user_id, email: _email })
      .then(res => {
        if (res.data.hasInviteAlreadySent) {
          sendEnterpriseChildInvite(true, res.data.enterprise_child_invites_id, _email, i)
        }
        else {
          sendEnterpriseChildInvite(false, res.data.enterprise_child_invites_id, _email, i)
        }
      })
      .catch(err => {
        setInvitingUser(false)
        if (err.response.status === 400) {
          let errors = inviteErrors
          const emailExists = errors.some(obj => obj.email === _email)
          if (!emailExists) {
            errors.push({ email: _email, error: err.response.data.error })
            setInviteErrors(errors)
            setBackupInviteErrors(errors)
            setNewChildEmails(prevState => prevState.filter(email => email !== _email))
            toast.error(<p className='m-0'>There were some errors during sending invite(s). Please check the errors <a className='link cursor-pointer' onClick={() => { toast.dismiss('errors'); showInviteErrorModal() }}>here</a>.</p>, { duration: Infinity, id: 'errors' })
          }
          handleClose()
        }
        else if (err.response.status === 500) {
          toast.error(err.response.data.error)
        }
        console.log(err);
      })
  }
  const sendEnterpriseChildInvite = (_reSendingEmail, _previouslySentInviteId, _email, i) => {
    setInvitingUser(true)
    ProtectedAxios.post('/users/enterprise/sendChildAccountInvite', { organization_name: selectedUser?.name ? selectedUser.name : user.name, enterprise_admin_user_id: selectedUser?.user_id ? selectedUser.user_id : user.user_id, email: _email, reSendingEmail: _reSendingEmail, previouslySentInviteId: _previouslySentInviteId })
      .then(res => {
        if (res.data) {
          setInvitingUser(false)
          successfulEmailCounts = successfulEmailCounts + 1
          let successMsg = `Successfully sent invite for the enterprise-child account. (${successfulEmailCounts}/${newChildEmails.length})`
          toast.success(successMsg, { id: '1', duration: Infinity })
          // setNewChildEmails(newChildEmails.filter(childEmail => childEmail !== _email))
          setNewChildEmail("")

          fetchChildInvites()
          fetchSubscriptionQuantity()

          handleClose()
          setNewChildEmails([])
        }
      })
      .catch(err => {
        setInvitingUser(false)
        toast.error(JSON.stringify(err.response.data.error))
      })
  }

  const handleFileUpload = (event) => {
    handleClose()
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      const rows = reader.result.split('\n');
      rows.forEach(_email => {
        if (emailRegex.test(_email.substring(0, _email.length - 1)) && !newChildEmails.includes(_email.substring(0, _email.length - 1))) {
          let tempEmail = newChildEmails
          tempEmail.unshift(_email.substring(0, _email.length - 1))
          setNewChildEmails(tempEmail)
          handleShow()
        }
      })
    }
  };

  const toggleUserStatus = (_status, _user_id, _stripe_customer_id) => {
    ProtectedAxios.post('/users/enterprise/toggleUserStatus', { status: _status, user_id: _user_id, selected_user_id: _user_id, customer_id: _stripe_customer_id })
      .then(res => {
        if (res.data) {
          fetchChildAccounts()
        }
      })
      .catch(err => {
        console.log(err);
        toast.error("could not update status at the moment, please try again later.")
      })
  }


  return (
    <div className='container py-2'>
      <Helmet>
        <title>Manage Enterprise Child Account - SageCollab</title>
      </Helmet>

      {fetchingSubscriptionQuantity
        ?
        <div className='d-flex justify-content-center align-items-center' style={{ height: '50vh' }}>
          <div className="spinner-border spinner-border" role="status">
            <span className="sr-only"></span>
          </div>
        </div>

        :
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start', paddingTop: '2vh' }}>
          {showTitle
            &&
            <>
              <h2 className='text-left w-100'>Child Accounts</h2>
              <p>
                You can send invites to user's email address so that they can set up their account and join SageCollab as a child user of your account. Once they complete their setup you can see their account in the list below. They will be using your API key for using SageCollab.
              </p>
            </>
          }
          <div className='w-100'>
            {fetchingChildInvites
              ? <>
                fetching child invites
                <div className="mx-2 spinner-border spinner-border-sm" role="status">
                  <span className="sr-only"></span>
                </div>
              </>
              :
              <>
                {backupChildInvites.length === 0
                  ?
                  <div>
                    <div className='d-flex flex-wrap gap-xs-0 gap-md-4 gap-lg-5 align-items-center mb-4'>
                      <div className='my-2'>
                        <RiMailCloseFill style={{ fill: '#383838', fontSize: '1.5rem', marginRight: '5px' }} />
                        No active invites found
                      </div>

                      <div className='my-2'>
                        <RiMailCheckFill style={{ fill: '#383838', fontSize: '1.5rem', marginRight: '5px' }} />
                        Invite accepted by {childAccounts.length} user(s).
                      </div>

                      <div className='my-2'>
                        <MdMail style={{ fill: '#383838', fontSize: '1.5rem', marginRight: '5px' }} />
                        You have {subscriptionQuantity} invites left.&nbsp; <a className='link cursor-pointer' onClick={showAddInviteModal}>add more invites</a>
                      </div>
                    </div>
                    <button className='button button-ghost' onClick={() => {
                      // !user?.azure_openai_api_key ? setShowAzureOpenaiKeysModal(true) : !user?.gemini_api_key ? setShowGeminiApiKeyModal(true) : handleShow()
                      if (selectedUser) {
                        if (!selectedUser.azure_openai_api_key) {
                          setShowAzureOpenaiKeysModal(true)
                        }
                        else if (!selectedUser.gemini_api_key) {
                          setShowGeminiApiKeyModal(true)
                        }
                        else {
                          handleShow()
                        }
                      } else {
                        if (!user.azure_openai_api_key) {
                          setShowAzureOpenaiKeysModal(true)
                        }
                        else if (!user.gemini_api_key) {
                          setShowGeminiApiKeyModal(true)
                        }
                        else {
                          handleShow()
                        }
                      }
                    }}
                    >
                      Send Invites
                    </button>
                  </div>
                  :
                  <>
                    <div >
                      <div className='d-flex flex-wrap justify-content-between mb-3'>
                        <div className='my-2'>
                          <MdMail style={{ fill: '#383838', fontSize: '1.5rem', marginRight: '5px' }} />
                          Invite sent to {backupChildInvites.length} email(s). <a className='link cursor-pointer' onClick={showInvitesModal}>show</a>
                        </div>

                        <div className='my-2'>
                          <RiMailCheckFill style={{ fill: '#383838', fontSize: '1.5rem', marginRight: '5px' }} />
                          Invite accepted by {childAccounts.length} user(s).
                        </div>

                        <div className='my-2'>
                          <RiMailAddFill style={{ fill: '#383838', fontSize: '1.5rem', marginRight: '5px' }} />
                          You have {subscriptionQuantity} invite(s) left.&nbsp; <a className='link cursor-pointer' onClick={showAddInviteModal}>add more invites</a>
                        </div>
                      </div>
                      <button className='button button-ghost' onClick={() => handleShow()}>Send more invites</button>
                      {backupInviteErrors.length > 0
                        &&
                        <div className='my-2'>
                          <MdOutlineError style={{ fill: 'red', fontSize: '1.5rem', marginRight: '5px' }} />
                          Couldn't send invites to {backupInviteErrors.length} email. <a className='link cursor-pointer' onClick={showInviteErrorModal}>check errors</a>
                        </div>
                      }
                    </div>
                  </>
                }
              </>
            }

            {/* <div className='w-100 d-flex justify-content-end my-4'>
                <button className='button button-ghost' onClick={() => handleShow()}>+ Add child account</button>
              </div> */}
            <div className='table-container overflow-auto' style={{ marginTop: '5rem' }}>
              <table className='api-keys-table'>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Username</th>
                    <th>Email</th>
                    <th>Created at</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {fetchingChildAccounts
                  ? <tbody>
                    <tr>
                      <td colSpan={6} className='py-4'>
                        fetching child accounts
                        <div className="mx-2 spinner-border spinner-border-sm" role="status">
                          <span className="sr-only"></span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  :
                  <>
                    {childAccounts.length === 0
                      ?
                      <tbody>
                        <tr>
                          <td colSpan={6} className='text-danger py-4'>No users have accepted your invitation yet.</td>
                        </tr>
                      </tbody>
                      :
                      <tbody>
                        {childAccounts.map((_user, i) => {
                          return (
                            <tr key={i}
                              className={`${((selectedChildAccount?.user_id === user.user_id || selectedChildAccount?.user_id === selectedUser?.user_id) && deletingChildAccount) ? 'deleting-key' : ''}`}
                            >
                              <td style={{ width: "3rem" }}>{i + 1}</td>
                              <td>
                                {_user.username}
                              </td>
                              <td>{_user.email}</td>
                              <td>{formatDate(new Date(_user.registration_timestamp))}</td>
                              <td>
                                {_user.is_active
                                  ? <span className='status-indicator active'>active</span>
                                  : <span className='status-indicator disabled'>disabled</span>

                                }
                              </td>
                              <td>
                                <div className='edit-btn-container justify-content-start align-start px-2'>
                                  {_user.is_active
                                    ?
                                    <button
                                      // disabled={selectedApiKey === key.api_key && deletingApiKey}
                                      className='edit-btn'
                                      onClick={() => toggleUserStatus(false, _user.user_id, _user.stripe_customer_id)}
                                      title='block account'
                                    >
                                      <MdBlock className='edit-icon text-danger' />
                                    </button>

                                    :
                                    <button
                                      // disabled={selectedApiKey === key.api_key && deletingApiKey}
                                      className='edit-btn'
                                      onClick={() => toggleUserStatus(true, _user.user_id)}
                                      title='enable account'
                                    >
                                      <MdOutlineDone className='edit-icon text-success' />
                                    </button>
                                  }
                                  <button
                                    disabled={selectedChildAccount?.user_id === _user.user_id}
                                    className='edit-btn'
                                    title='delete account'
                                    onClick={(e) => { setSelectedChildAccount(_user); deleteChildAccount(_user.user_id, _user.stripe_customer_id) }}
                                  >
                                    <RiDeleteBin5Line className='edit-icon' />
                                  </button>
                                </div>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    }
                  </>
                }
              </table>
            </div>
          </div>
        </div>
      }

      <AddOpenaiAPIkeyDialog
        open={showOpenaiApiKeyModal}
        onOpenChange={() => setShowOpenaiApiKeyModal(false)}
        message='Add your OpenAI API key to send invites for enterprise-child account'
      />

      <AddGeminiAPIkeyDialog
        open={showGeminiApiKeyModal}
        onOpenChange={() => setShowGeminiApiKeyModal(false)}
        title='Add/Edit API Key'
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        message={
          selectedUser
            ? <>Add/Edit Gemini API key for user: <br /><span className='fw-medium font-s'>{selectedUser.name} ({selectedUser?.username})</span></>
            : 'Add your Gemini API key to send invites for enterprise-child account'
        }

      />

      <AddAzureOpenaiKeysDialog
        open={showAzureOpenaiKeysModal}
        onOpenChange={() => setShowAzureOpenaiKeysModal(false)}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        heading="Azure keys not set"
        title={
          selectedUser
            ? <>Add/Edit Azure OpenAI keys for user: <br /><span className='fw-medium font-s'>{selectedUser.name} ({selectedUser?.username})</span></>
            : 'Add your Azure OpenAI keys to send invites for enterprise-child account'
        }
      />

      <Modal show={show} onHide={handleClose} centered size="md">
        <Modal.Header closeButton>
          <Modal.Title>Add child account</Modal.Title>
        </Modal.Header>
        {/* <form onSubmit={verifyEnterpriseInviteEmail}> */}
        <Modal.Body>
          <div>
            <div className='container'>
              <div className='input-with-icon position-relative py-3'>
                <div className='d-flex justify-content-between'><label htmlFor="invite-email" ><span>New child account Email <span className='text-danger'>*</span></span></label> <div className='file-upload-link'><label htmlFor='csv-upload' className=''>Upload via file(csv)</label><input type="file" id='csv-upload' accept=".csv" onChange={handleFileUpload} style={{}} /></div></div>
                <input type="email" id="invite-email" value={newChildEmail} onChange={e => { setError(""); handleEmailChange(e.target.value) }} />
                {showingList
                  &&
                  <div className='list-dropdown'>
                    <div className="d-flex justify-content-between align-items-center px-2">
                      <p className='m-0'>{newChildEmail}</p>
                      {newChildEmails.includes(newChildEmail)
                        ? <button type="button" className='edit-btn' title='already added'> <MdOutlineDone className='edit-icon accept-icon' /></button>
                        : <button type="submit" className='edit-btn' title='add in invite list' onClick={() => addInInviteList()} > <GrFormAdd className='edit-icon reject-icon' /></button>
                      }
                    </div>
                  </div>
                }
              </div>
              {newChildEmails.length > 1
                &&
                <div className='d-flex justify-content-end mb-3'><a className='link cursor-pointer' onClick={() => setNewChildEmails([])}>clear all({newChildEmails.length})</a></div>
              }
              <div className='wrapped-flex mb-3' style={{ maxHeight: '11.8rem', overflowY: 'scroll' }}>
                {newChildEmails.map(email => {
                  return (
                    <div className='selected-user'>
                      {email}
                      <button type="button" className='button-icon edit-btn' title='remove from list' onClick={() => removeEmailFormInviteList(email)}><RiCloseLine className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>
                    </div>
                  )
                })}
              </div>
              <p>Send an invite to your new child account user's email so that they can setup their username and password and start using their child account</p>
              {newChildEmails.length > (subscriptionQuantity)
                &&
                <span>
                  <p className='text-danger d-inline'>Please remove {newChildEmails.length - (subscriptionQuantity)} email(s) as you only have {subscriptionQuantity} invite(s) left</p><p className=' d-inline'> or <a className='link cursor-pointer' onClick={showAddInviteModal}>add invites</a></p>
                </span>
              }
              {error.length > 0
                &&
                <p className='text-danger'>{error}</p>
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' onClick={handleClose} className='button button-danger'>Close</button>
          <button type="submit" className='button' onClick={() => sendInvites()} disabled={invitingUser || newChildEmails.length > (subscriptionQuantity)}>
            {invitingUser
              ?
              <>
                Send Invite
                <div className="mx-2 spinner-border spinner-border-sm" role="status">
                  <span className="sr-only"></span>
                </div>
              </>
              : `Send Invite ${newChildEmails.length > 0 ? `(${newChildEmails.length})` : ''}`
            }
          </button>
        </Modal.Footer>
        {/* </form> */}
      </Modal>

      {/* INVITE LIST MODAL */}
      <Modal show={invitesModal} onHide={hideInvitesModal} centered size="md">
        <Modal.Header closeButton>
          <Modal.Title>Invite List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=''>
            <input type='text' placeholder='search email' value={searchChildInviteEmailtext} onChange={e => setSearchChildInviteEmailText(e.target.value)} />
            <div className='my-2 container' style={{ maxHeight: '15rem', overflowY: 'scroll' }}>
              {childInvites.length === 0
                ? <p className='my-2 py-2 text-danger'>No email found.</p>
                :
                <>
                  {childInvites.map((invite, i) => {
                    return (
                      <div key={i} style={{ padding: '0 1rem', borderRadius: '10px' }} className={deletingChildInvites && selectedChildInvite.child_email === invite.child_email ? 'deleting-saved-prompt user-select-none opacity-75' : ''}>
                        <div className={`d-flex justify-content-between align-items-start pt-3 ${deletingChildInvites && selectedChildInvite.child_email === invite.child_email ? 'border-none' : 'border-bottom'}`}>
                          <div>
                            <p className='m-1'>{invite.child_email}</p>
                            <p className='subtitle'>sent {formatDate(new Date(invite.invite_timestamp))}</p>
                          </div>
                          <button type="button" className='button-icon edit-btn' title='delete invite' onClick={() => { setSelectedChildInvite(invite); deleteChildInvites(invite.enterprise_child_invite_id) }}><RiCloseLine className='edit-icon reject-icon ' style={{}} /></button>
                        </div>
                      </div>
                    )
                  })}
                </>
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' onClick={hideInvitesModal} className='button button-danger'>Close</button>
        </Modal.Footer>
      </Modal>

      {/* INVITES ERROR MODAL */}
      <Modal show={inviteErrorModal} onHide={hideInviteErrorModal} centered size="md">
        <Modal.Header closeButton>
          <Modal.Title>Invite Errors</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=''>
            <input type='text' placeholder='search email' value={searchChildInviteErrorEmailtext} onChange={e => setSearchChildInviteErrorEmailText(e.target.value)} />
            {inviteErrors.length > 0
              &&
              < div className='d-flex justify-content-end mt-3 container'><a className='link cursor-pointer' onClick={() => { setBackupInviteErrors([]); hideInviteErrorModal() }}>clear all({inviteErrors.length})</a></div>
            }
            <div className='my-2 container' style={{ maxHeight: '15rem', overflowY: 'scroll' }}>
              {inviteErrors.length === 0
                ? <p className='my-2 py-2 text-danger'>No email found.</p>
                :
                <>
                  {inviteErrors.map((error, i) => {
                    return (
                      <div key={i} style={{ borderBottom: '1px solid #8080804f' }}>
                        <div className="d-flex flex-column pt-3">
                          <p className='m-1'>{error.email}</p>
                          <p>Error - <span className='text-danger'>{error.error}</span></p>
                        </div>
                      </div>
                    )
                  })}
                </>
              }
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' onClick={hideInviteErrorModal} className='button button-danger'>Close</button>
        </Modal.Footer>
      </Modal>

      {/* ADD INVITES MODAL */}
      <Modal show={addInvitesModal} onHide={closeAddInviteModal} centered size="md">
        <Modal.Header closeButton>
          <Modal.Title>Add Invites</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container'>
            <p>You can add a new child user for your organization by sending them invites. Each invite/child-account will only add $5 USD in your monthly subscription.</p>
            <div className='d-flex justify-content-between align-items-center'>
              <label>Add more invites :</label>
              <div className='counter-input'>
                <button className='edit-btn' onClick={() => setIncrementQuantityBy(prev => prev - 1)} title={`${incrementQuantityBy === 0 ? 'this value cannot be lower than zero' : ''}`} disabled={incrementQuantityBy === 0}><AiOutlineMinus className='edit-icon' /></button>
                <input maxLength={2} ref={countInputRef} type='number' value={incrementQuantityBy ? parseInt(incrementQuantityBy) : parseInt(incrementQuantityBy * 0)} onChange={e => setIncrementQuantityBy(e.target.value)} className='no-hover-input' />
                <button className='edit-btn' onClick={() => setIncrementQuantityBy(prev => parseInt(prev) + 1)} title=''><AiOutlinePlus className='edit-icon' /></button>
              </div>
            </div>
            <div className='d-flex justify-content-end'>
              <span className='w-50 border-top mt-4 pt-2 d-flex justify-content-center'>
                Total :&nbsp;&nbsp; <span style={{ fontWeight: '500' }}>+${incrementQuantityBy * 5} USD/month</span>
              </span>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type='button' onClick={closeAddInviteModal} className='button button-danger'>Close</button>
          <button type="submit" className='button' onClick={() => updateSubscriptionQuantity()} disabled={incrementingQuantity || incrementQuantityBy <= 0}>
            {incrementingQuantity
              ?
              <>
                Add Invites
                <div className="mx-2 spinner-border spinner-border-sm" role="status">
                  <span className="sr-only"></span>
                </div>
              </>

              : <>Add Invites</>
            }
          </button>
        </Modal.Footer>
      </Modal>
    </div >
  )
}

export default ManageEnterpriseChildAccounts