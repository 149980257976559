import React, { useEffect, useRef, useState } from 'react'
import { MdEdit } from 'react-icons/md'
import ProtectedAxios from '../api/protectedAxios';
import { toast } from 'react-hot-toast';
import { GrFormAdd, GrFormClose } from 'react-icons/gr'
import LoadingSkeleton from './LoadingSkeleton';
import { Dialog } from './ui/Dialog';

const ManageTags = ({ setRefreshTags, noTags = false, viewAsLisk = false, chat_id, isViewingWithSharedAccess, isEnterpriseAdmin }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); window.removeEventListener("keydown", addTagByEnterButton); setTagText(''); setRefreshTags(prev => !prev) };
    const handleShow = () => setShow(true);

    const inputRef = useRef(null)

    const [selectedTags, setSelectedTags] = useState([])
    const [tagText, setTagText] = useState('')

    const [loadingTags, setLoadingTags] = useState(true)
    const [addingTag, setAddingTag] = useState(false)

    const [removingTag, setRemovingTag] = useState(false)
    const [selectedTagId, setSelectedTagId] = useState("")

    const [searchingMyTags, setSearchingMyTags] = useState(false)
    const [showingDropdownList, setShowingDropdownList] = useState(false)
    const [myTagsSearchResults, setMyTagsSearchResults] = useState([])


    const [refresh, setRefresh] = useState(false)
    useEffect(() => {
        fetchPromptTags(true)
    }, [chat_id, refresh])

    const fetchPromptTags = (load = false) => {
        setLoadingTags(load)
        ProtectedAxios.post('/users/chatTags', { chat_id })
            .then(res => {
                setLoadingTags(false)
                setAddingTag(false)
                setSelectedTags(res.data)
            })
            .catch(err => {
                setLoadingTags(false)
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                } else {
                    console.log(err);
                }
            })
    }

    const addTag = () => {
        if (inputRef.current.value && !isViewingWithSharedAccess) {
            setAddingTag(true)
            setShowingDropdownList(false)
            ProtectedAxios.post('/users/addTag', { chat_id, tagName: inputRef.current.value })
                .then(res => {
                    if (res.data) {
                        fetchPromptTags()
                        setTagText('')
                    }
                })
                .catch(err => {
                    setAddingTag(false)
                    if (err.response.status === 500) {
                        toast.error(err.response.data.error)
                    } else {
                        console.log(err);
                    }
                })
        }
    }

    const addTagByEnterButton = (e) => {
        if (e.key === "Enter") {
            addTag()
        }
    }
    useEffect(() => {
        if (show) {
            window.addEventListener("keydown", addTagByEnterButton)
        }

        return () => {
            window.removeEventListener("keydown", addTagByEnterButton)
        }
    }, [show])

    const removeTag = tag_id => {
        setRemovingTag(true)
        ProtectedAxios.post('/users/removeTag', { chat_id, tag_id: tag_id })
            .then(res => {
                if (res.data) {
                    setRefresh(!refresh)
                    setRemovingTag(false)
                }
            })
            .catch(err => {
                setRemovingTag(false)
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                } else {
                    console.log(err);
                }
            })
    }

    useEffect(() => {
        if (tagText.length === 0) {
            setShowingDropdownList(false)
        }
        else if (tagText.length >= 3) {
            searchTags()
        }

    }, [tagText])

    const searchTags = () => {
        setSearchingMyTags(true)
        setShowingDropdownList(true)
        ProtectedAxios.get(`/users/tags/my/search?text=${tagText}`)
            .then(res => {
                setMyTagsSearchResults(res.data)
                setSearchingMyTags(false)
            })
            .catch(err => {
                setSearchingMyTags(false)
                console.log(err);
                toast.error("Could not get tag at the moment")
            })
    }

    const dropdownRef = useRef(null)
    useClickOutside(dropdownRef)

    function useClickOutside(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowingDropdownList(false)
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    return (
        <Dialog
            open={show}
            onOpenChange={handleClose}
            title='Manage tags'
            hideFooter
            hideTrigger={false}
            trigger={
                viewAsLisk
                    ?
                    <div
                        onClick={handleShow}
                    >
                        Manage Tags
                    </div>
                    :
                    noTags
                        ?
                        <>
                            <button type='button' id='add-tag-button' className='edit-btn noTag-addBtn add-note-button' title='add tag' onClick={e => { handleShow() }}><MdEdit className='font-s' />Add Tags</button>
                        </>
                        :
                        <button className='button-icon' id='share-button' title='edit tags' onClick={e => { handleShow() }}><MdEdit className='font-s' /></button>
            }
            body={
                <div className='my-3 px-2 pb-3'>
                    <div className='input-grp'>
                        <label htmlFor="chat-name">Add Tag</label>
                        <div className='tag-input-container full' style={{ display: 'flex' }}>
                            <input autoFocus={true} ref={inputRef} type='text' placeholder='type tag name here' id='tag-input' value={tagText.toLowerCase()} onChange={e => { setTagText(e.target.value.toLowerCase()) }} />
                            <button type='button' className='button-icon' title='add tag' disabled={addingTag} onClick={() => addTag()}>
                                {addingTag
                                    ?
                                    <div className="spinner-border spinner-border-sm" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                    :
                                    <GrFormAdd className='font-s' />
                                }
                            </button>

                            {showingDropdownList
                                &&

                                <div ref={dropdownRef} className='list-dropdown'>
                                    <div className='list-container'>
                                        {searchingMyTags
                                            ?
                                            <div className='py-3 opacity-50'>
                                                <div className="d-flex m-auto spinner-border spinner-border-sm" role="status">
                                                    <span className="sr-only"></span>
                                                </div>
                                            </div>

                                            :
                                            myTagsSearchResults.length === 0
                                                ? <div><p className='m-0 text-secondary text-center font-xxs'>No tag found, click the + icon to add this new tag</p></div>

                                                :
                                                myTagsSearchResults.map((tag, i) => (
                                                    <div
                                                        className='list-item'
                                                        onClick={() => {
                                                            inputRef.current.value = tag.tag_ref.tag;
                                                            addTag()
                                                        }}
                                                    >
                                                        {tag.tag_ref.tag}
                                                    </div>
                                                ))
                                        }
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                    <div className='tags-container'>
                        <div className='selected-tag-container' style={{ display: 'flex', gap: '1rem', padding: '1rem 0.5rem', flexWrap: 'wrap' }}>
                            {loadingTags
                                ?
                                <div className='d-flex gap-2 mt-3'>
                                    <LoadingSkeleton type="tags" />
                                    <LoadingSkeleton type="tags" />
                                    <LoadingSkeleton type="tags" />
                                </div>
                                :
                                selectedTags.length > 0
                                &&
                                <>
                                    {
                                        selectedTags.map((tag, i) => {
                                            return (
                                                <span
                                                    key={i}
                                                    style={{ borderRadius: '30px', fontSize: '0.9rem', }}
                                                    className={`tag tag-color-${i % 4 + 1} editing`}
                                                >
                                                    {tag.tag}
                                                    <button
                                                        type='button'
                                                        title='remove tag'
                                                        id='remove-tag-button'
                                                        className={`edit-btn remove-tag-button`}
                                                        value={tag.tag_id}
                                                        onClick={e => { setSelectedTagId(e.target.value); removeTag(e.target.value) }}
                                                    >
                                                        {removingTag && selectedTagId == tag.tag_id
                                                            ?
                                                            <div className="d-flex m-auto spinner-border spinner-border-sm" role="status">
                                                                <span className="sr-only"></span>
                                                            </div>

                                                            : <GrFormClose className={`edit-icon`} />
                                                        }
                                                    </button>
                                                </span>
                                            )
                                        })
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            }
        />
    )
}

export default ManageTags