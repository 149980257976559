import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { UserContext } from '../../context/UserProvider';
import { PiShareFill } from "react-icons/pi";
import SwitchButton from '../ui/SwitchButton';
import ProtectedAxios from '../../api/protectedAxios';
import toast from 'react-hot-toast';
import { IoCheckmark, IoCopy } from 'react-icons/io5';
import { TbWorldShare } from "react-icons/tb";
import { BiLink } from 'react-icons/bi';

const ShareTemplateDialog = ({ selectedUser, title, showAsLink, templateDetails, onTemplateDetailsChange, className }) => {
    const [user] = useContext(UserContext)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [sharing, setSharing] = useState(false)
    const [updatingTemplateSharingStatus, setUpdatingTemplateSharingStatus] = useState(false)

    const [templateLink, setTemplateLink] = useState("")
    const [copied, setCopied] = useState(false)
    useEffect(() => {
        if (copied === true) {
            setTimeout(() => {
                setCopied(false)
            }, 1000)
        }
    }, [copied])

    useEffect(() => {
        setSharing(templateDetails?.is_sharing)
        setTemplateLink(`${window.location.origin}/start-chat?templateId=${templateDetails?.template_id}`)
    }, [templateDetails])

    const toggleTemplateSharing = () => {
        setUpdatingTemplateSharingStatus(true)
        ProtectedAxios.post("/users/toggleTemplateSharing", { user_id: user.user_id, selected_user_id: selectedUser?.user_id, template_id: templateDetails?.template_id })
            .then(res => {
                setUpdatingTemplateSharingStatus(false)

                setSharing(res.data.is_sharing)

                onTemplateDetailsChange({ template_id: templateDetails?.template_id, is_sharing: res.data.is_sharing })
            })
            .catch(err => {
                setUpdatingTemplateSharingStatus(false)
                console.log(err);
                toast.error(err.response.data.error)
            })
    }

    return (
        <>
            {!showAsLink
                ?
                <button className={`edit-btn position-relative ${className}`} id='share-button' title='share template' onClick={e => { handleShow() }}>
                    <PiShareFill className='edit-icon dark-icon w-s' />
                    {sharing
                        &&
                        <span class="position-absolute top-5 left-0 p-1 bg-danger border border-light rounded-circle">
                            <span class="visually-hidden">New alerts</span>
                        </span>
                    }
                </button>
                :
                <div className={`${className}`} onClick={handleShow}>
                    {title ? title : "Share Template"}
                </div>
            }



            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Share Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='py-3'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>
                                <h3 className='mb-1 font-s'>
                                    {sharing
                                        ? "This template is shared"
                                        : "This template is not shared"
                                    }
                                </h3>
                                <p className='text-secondary m-0 font-xxs'>
                                    {sharing
                                        ? "Toggle the switch to disable template sharing"
                                        : "Toggle the switch to enable template sharing"
                                    }
                                </p>
                            </div>
                            <SwitchButton
                                title={sharing ? "Disable template sharing" : "Share your template"}
                                loading={updatingTemplateSharingStatus}
                                active={sharing}
                                onClick={toggleTemplateSharing}
                            />
                        </div>

                        {sharing
                            &&
                            <div className='mt-4'>
                                <div className='mt-5 px-3'>
                                    <label htmlFor='chat-link-input' className='d-flex align-items-center gap-1 mb-1'><BiLink className='w-m h-m' /> Template link</label>
                                    <div className='input-with-icon' id="chat-link-input">
                                        <input value={templateLink} />
                                        <button type='button' className='button-icon edit-btn' title={copied ? 'copied' : 'copy'} value={templateLink} onClick={e => { navigator.clipboard.writeText(e.target.value).then(() => { setCopied(true) }) }}>
                                            {copied
                                                ?
                                                <IoCheckmark className='edit-icon copy-icon text-success' />
                                                :
                                                <IoCopy className='edit-icon copy-icon' />
                                            }
                                        </button>
                                    </div>

                                    <div className='mt-1'>
                                        <div className='d-flex gap-1 justify-content-end align-items-center mb-1'>
                                            <TbWorldShare className='w-m h-m text-secondary' />
                                            <p className='m-0 font-xxs text-secondary'>Anyone can use this link to use your template to start chat</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        }

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='button' onClick={() => handleClose()}>
                        Done
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ShareTemplateDialog