import React, { useContext, useEffect, useRef, useState } from 'react'
import ProtectedAxios from '../api/protectedAxios';
import { RiCheckLine, RiCloseLine, RiDeleteBin5Line, RiMailAddFill, RiMailFill } from 'react-icons/ri'
import { GiArmorUpgrade, GiTeamUpgrade } from 'react-icons/gi'
import { toast } from 'react-hot-toast';
import Modal from 'react-bootstrap/Modal';
import { MdGroupAdd } from 'react-icons/md'
import { UserContext } from '../context/UserProvider';
import { AiFillAlert, AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import DeleteUserButton from './DeleteUserButton';
import MyDropdown from './MyDropdown';
import ManageAccessModal from './ManageAccessModal';
import AddGeminiAPIkeyDialog from './dialogs/AddGeminiAPIkeyDialog'
import AddOpenaiAPIkeyDialog from './dialogs/AddOpenaiAPIkeyDialog';
import AddAzureOpenaiKeysDialog from './dialogs/AddAzureOpenaiKeysDialog';
import ManageEnterpriseChildAccounts from '../pages/ManageEnterpriseChildAccounts';
import MyTemplates from '../pages/MyTemplates';
import { FiAlertTriangle } from 'react-icons/fi';
import { Dialog } from './ui/Dialog';
import OpenaiCompatibleIcon from './custom-svgs/OpenaiCompatibleIcon';
import AddOpenaiCompatibleAPIkeyDialog from './dialogs/AddOpenaiCompatibleAPIkeyDialog';
import AddBedrockAPIkeyDialog from './dialogs/AddBedrockAPIkeyDialog';


const ManageUserTable_Actions = ({ loading = true, userDetails, fetchAllUsers, fetchUserSubscriptionDetails }) => {
    const [user] = useContext(UserContext)
    const [user_details, set_user_details] = useState(userDetails)

    const [selectedUser, setSelectedUser] = useState(null)
    const [deletingUser, setDeletingUser] = useState(false)
    const [addingFreeEnterpriseSubscription, setAddingFreeEnterpriseSubscription] = useState(false)

    const [showOpenaiApiKeyDialog, setShowOpenaiApiKeyDialog] = useState(false);
    const [showGeminiApiKeyDialog, setShowGeminiApiKeyDialog] = useState(false);
    const [showAzureKeysDialog, setShowAzureKeysDialog] = useState(false);
    const [showOpenaiCompatibleKeysDialog, setShowOpenaiCompatibleKeysDialog] = useState(false);
    const [showBedrockKeysDialog, setShowBedrockKeysDialog] = useState(false);

    const [upgradeEndDate, setUpgradeEndDate] = useState('')
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [upgradingUser, setUpgradingUser] = useState(false)

    const [confirmFreeEnterpriseSubscriptionDialog, setConfirmFreeEnterpriseSubscriptionDialog] = useState(false)
    const closeConfirmFreeEnterpriseSubscriptionDialog = () => { setConfirmFreeEnterpriseSubscriptionDialog(false) }
    const showConfirmFreeEnterpriseSubscriptionDialog = () => { setConfirmFreeEnterpriseSubscriptionDialog(true) }

    const [togglingUserStatus, setTogglingUserStatus] = useState(false)

    const [manageChildAccountsDialog, setManageChildAccountSDialog] = useState(false)
    const [manageTemplatesDialog, setManageTemplatesDialog] = useState(false)


    const disableUser = (_userId) => {
        setTogglingUserStatus(true)
        ProtectedAxios.post('/admin/disableUser', { selected_user_id: _userId })
            .then(res => {
                set_user_details({ ...user_details, is_active: 0 })
                setTogglingUserStatus(false)
            })
            .catch(err => {
                setTogglingUserStatus(false)
                toast.error(err.response.data.error)
            })
    }

    const activateUser = (_userId) => {
        setTogglingUserStatus(true)
        ProtectedAxios.post('/admin/activateUser', { selected_user_id: _userId })
            .then(res => {
                set_user_details({ ...user_details, is_active: 1 })
                setTogglingUserStatus(false)
            })
            .catch(err => {
                setTogglingUserStatus(false)
                toast.error(JSON.stringify(err.response.data.error))
            })
    }

    const upgradePlan = e => {
        e.preventDefault()
        setUpgradingUser(true)
        const dateObj = new Date(upgradeEndDate)
        const currentDate = new Date()
        dateObj.setHours(currentDate.getHours())
        dateObj.setMinutes(currentDate.getMinutes())
        dateObj.setSeconds(currentDate.getSeconds())
        // alert(dateObj)
        ProtectedAxios.post('/admin/upgradeUserToStandardPlan', { selected_user_id: selectedUser.user_id, email: selectedUser.email, name: selectedUser.name, selected_customer_id: selectedUser.stripe_customer_id, upgradeEndDate })
            .then(res => {
                if (res.data) {
                    setUpgradingUser(false)
                    handleClose()
                    fetchUserSubscriptionDetails()
                    toast.success(`${selectedUser.name} successfully upgraded to Standard plan`)
                }
            })
            .catch(err => {
                setUpgradingUser(false)
                console.log(err);
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                }
            })
    }

    const addFreeEnterpriseSubscription = async (_user_id, _username, _customer_id) => {
        setAddingFreeEnterpriseSubscription(true)
        toast.loading(`adding Enterprise subscription for user - ${selectedUser.username}`, { id: 'loading' })
        ProtectedAxios.post('/admin/addFreeEnterpriseSubscription', { user_id: user.user_id, selected_user_id: selectedUser.user_id, customer_id: selectedUser.stripe_customer_id })
            .then(res => {
                if (res.data) {
                    setAddingFreeEnterpriseSubscription(false)
                    toast.success(`Enterprise subscription added for user - '${selectedUser.username}'`, { id: 'loading' })
                    closeConfirmFreeEnterpriseSubscriptionDialog(``)
                    fetchAllUsers()
                }
            })
            .catch(err => {
                setAddingFreeEnterpriseSubscription(false)
                console.log(err);
                toast.error("could not add enterprise subscription at the moment, please try again later.", { id: 'loading' })
            })
    }



    return (
        <>
            {loading
                ?
                <td style={{ minWidth: '6rem', height: '3rem' }}>
                    <div style={{ width: '100%', height: '100%' }} className='d-flex justify-content-center align-items-center'>
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="sr-only"></span>
                        </div>
                    </div>
                </td>
                :
                <>
                    <td>{user_details.is_active ? 'Active' : 'Disabled'}</td>
                    <td>{user_details.is_official_template_creator ? 'Yes' : 'No'}</td>

                    <td className=''>
                        <div className='d-flex gap-2'>
                            <button className={`edit-btn position-relative`} onClick={() => setShowOpenaiApiKeyDialog(true)}>
                                {!user_details?.openai_api_key
                                    ?
                                    <RiCloseLine className='w-xxs h-xxs bg-danger text-white rounded-circle position-absolute top-2 right-2' style={{ right: "2px", top: "5px" }} />
                                    :
                                    <RiCheckLine className='w-xxs h-xxs bg-success text-white rounded-circle position-absolute top-2 right-2' style={{ right: "2px", top: "5px" }} />
                                }
                                <img className='w-s h-s' alt="" src="https://www.svgrepo.com/show/306500/openai.svg" />
                            </button>

                            <button className={`edit-btn position-relative`} onClick={() => setShowGeminiApiKeyDialog(true)}>
                                {!user_details?.gemini_api_key
                                    ?
                                    <RiCloseLine className='w-xxs h-xxs bg-danger text-white rounded-circle position-absolute top-2 right-2' style={{ right: "2px", top: "5px" }} />
                                    :
                                    <RiCheckLine className='w-xxs h-xxs bg-success text-white rounded-circle position-absolute top-2 right-2' style={{ right: "2px", top: "5px" }} />
                                }
                                <img className='w-s h-s' alt="" src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/google-gemini-icon.png" />
                            </button>

                            <button className={`edit-btn position-relative`} onClick={() => setShowAzureKeysDialog(true)}>
                                {(!user_details?.azure_openai_endpoint || !user_details?.azure_openai_api_key || !user_details?.azure_deployment_id)
                                    ?
                                    <RiCloseLine className='w-xxs h-xxs bg-danger text-white rounded-circle position-absolute top-2 right-2' style={{ right: "2px", top: "5px" }} />
                                    :
                                    <RiCheckLine className='w-xxs h-xxs bg-success text-white rounded-circle position-absolute top-2 right-2' style={{ right: "2px", top: "5px" }} />
                                }
                                <img className='w-s h-s' alt="" src="https://swimburger.net/media/eyzjad3q/azure.png?width=250&height=250&v=1d7697083360c50" />
                            </button>

                            <button className={`edit-btn position-relative`} onClick={() => setShowOpenaiCompatibleKeysDialog(true)}>
                                {(!user_details?.openai_compatible_endpoint || !user_details?.openai_compatible_api_key || !user_details?.openai_compatible_model)
                                    ?
                                    <RiCloseLine className='w-xxs h-xxs bg-danger text-white rounded-circle position-absolute top-2 right-2' style={{ right: "2px", top: "5px" }} />
                                    :
                                    <RiCheckLine className='w-xxs h-xxs bg-success text-white rounded-circle position-absolute top-2 right-2' style={{ right: "2px", top: "5px" }} />
                                }
                                <OpenaiCompatibleIcon className="w-m h-m" />
                            </button>

                            <button className={`edit-btn position-relative`} onClick={() => setShowBedrockKeysDialog(true)}>
                                {(!user_details?.bedrock_access_key || !user_details?.bedrock_secret_access_key || !user_details?.bedrock_model_region)
                                    ?
                                    <RiCloseLine className='w-xxs h-xxs bg-danger text-white rounded-circle position-absolute top-2 right-2' style={{ right: "2px", top: "5px" }} />
                                    :
                                    <RiCheckLine className='w-xxs h-xxs bg-success text-white rounded-circle position-absolute top-2 right-2' style={{ right: "2px", top: "5px" }} />
                                }
                                <img src="https://www.outsystems.com/Forge_CW/_image.aspx/Q8LvY--6WakOw9afDCuuGbQ9u-QKbiqiEaG1FDMiKVo=/aws-bedrock-runtime-2023-01-04%2000-00-00-2024-09-12%2014-12-44" className='rounded-circle w-m' alt="bedrock" />
                            </button>
                        </div>
                    </td>

                    <td>
                        <div className='d-flex px-2'>
                            <MyDropdown>
                                <div
                                    className='dropdown-item d-flex align-items-center gap-2'
                                    disabled={deletingUser || addingFreeEnterpriseSubscription}
                                    value={user_details.user_id}
                                    onClick={() => {
                                        if (user_details.is_active) {
                                            disableUser(user_details.user_id)
                                        }
                                        else {
                                            activateUser(user_details.user_id)
                                        }
                                    }}
                                >
                                    {
                                        user_details.is_active
                                            ?
                                            <div title='disable account'>Disable Account</div>

                                            :
                                            <div title='activate account'>Enable Account</div>
                                    }
                                    {togglingUserStatus
                                        &&
                                        <div>
                                            <div className="spinner-border spinner-border-sm" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className='dropdown-item'>
                                    <DeleteUserButton showAsIcon={false} selectedUser={user_details} fetchAllUsers={fetchAllUsers} />
                                </div>

                                {user_details.plan_id === process.env.REACT_APP_PRICE_A_ID
                                    &&
                                    <div
                                        className='dropdown-item'
                                        disabled={deletingUser || addingFreeEnterpriseSubscription}
                                        title='Upgrade to Standard plan'
                                        onClick={() => {
                                            setSelectedUser(user_details);
                                            handleShow()
                                        }}
                                    >
                                        Add Standard Plan
                                    </div>
                                }


                                {(user_details.plan_id === process.env.REACT_APP_PRICE_A_ID || user_details.plan_id === process.env.REACT_APP_PRICE_B_ID)
                                    &&
                                    <div
                                        className='dropdown-item'
                                        disabled={deletingUser || addingFreeEnterpriseSubscription}
                                        title='Add Enterprise Subscription'
                                        onClick={() => {
                                            setSelectedUser(user_details);
                                            showConfirmFreeEnterpriseSubscriptionDialog()
                                        }}
                                    >
                                        Add Enterprise Plan
                                    </div>
                                }


                                <div
                                    className='dropdown-item'
                                    title='Manage Access'
                                    onClick={() => {
                                        setSelectedUser(user_details)
                                    }}
                                >
                                    <ManageAccessModal selectedUser={selectedUser} setSelectedUser={setSelectedUser} setUserDetails={set_user_details} />
                                </div>


                                {user_details.plan_id === process.env.REACT_APP_PRICE_C_ID
                                    &&
                                    <div
                                        className='dropdown-item'
                                        title='Child Accounts'
                                        onClick={() => {
                                            setManageChildAccountSDialog(true)
                                        }}
                                    >
                                        Child Accounts
                                    </div>
                                }

                                <div
                                    className='dropdown-item'
                                    title='Templates'
                                    onClick={() => {
                                        setManageTemplatesDialog(true)
                                    }}
                                >
                                    Templates
                                </div>


                                <div
                                    className='dropdown-item'
                                    title='Refresh Details'
                                    onClick={() => {
                                        fetchUserSubscriptionDetails()
                                    }}
                                >
                                    Refresh Details
                                </div>

                            </MyDropdown>

                        </div>
                    </td>
                </>
            }


            {/* Add Standard Plan Dialog */}
            <Dialog
                open={show}
                onOpenChange={handleClose}
                title='Upgrade to standard plan'
                hideFooter
                body={
                    <form onSubmit={upgradePlan} className='pb-3'>
                        <div className='upgrade-confirmation-body px-3 py-3'>
                            <h5>{selectedUser?.name} - {selectedUser?.username}</h5>
                            <p>Current Plan - <b>{selectedUser?.plan}</b></p>
                            <br />
                            <div className='input-grp'>
                                <label>Upgrade End Date <span className='text-danger'>*</span></label>
                                <input required type="datetime-local"
                                    min={new Date(new Date().getTime() - (new Date().getTimezoneOffset() * 60000)).toISOString().slice(0, 16)}
                                    value={upgradeEndDate} onChange={e => setUpgradeEndDate(e.target.value)} />
                            </div>
                        </div>
                        <button className='button d-flex align-items-center ms-auto mt-2' disabled={upgradingUser}>
                            {upgradingUser
                                &&
                                <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            }
                            Upgrade to Standard
                        </button>
                    </form>
                }
            />


            {/* Add Enterprise Subscription Confirmation Dialog */}
            <Dialog
                open={confirmFreeEnterpriseSubscriptionDialog}
                onOpenChange={closeConfirmFreeEnterpriseSubscriptionDialog}
                title='Confirm'
                hideFooter
                body={
                    <div className='container mt-2 mb-3'>
                        <p>Are you sure you want to add Enterprise subscription for user - <b>{selectedUser?.username}</b></p>
                        <button type="submit" className='button d-flex align-items-center gap-2 ms-auto' onClick={() => addFreeEnterpriseSubscription()} disabled={addingFreeEnterpriseSubscription}>
                            {addingFreeEnterpriseSubscription
                                &&
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            }
                            Confirm
                        </button>
                    </div>
                }
            />

            <AddGeminiAPIkeyDialog
                open={showGeminiApiKeyDialog}
                onOpenChange={() => setShowGeminiApiKeyDialog(false)}
                title='Add/Edit API Key'
                selectedUser={user_details}
                setSelectedUser={set_user_details}
                message={<>Add/Edit Gemini API key for user: <br /><span className='fw-medium font-s'>{user_details.name} ({user_details?.username})</span></>}
            />

            <AddOpenaiAPIkeyDialog
                open={showOpenaiApiKeyDialog}
                onOpenChange={() => setShowOpenaiApiKeyDialog(false)}
                title='Add/Edit API Key'
                selectedUser={user_details}
                setSelectedUser={set_user_details}
                message={<>Add/Edit OpenAI API key for user: <br /><span className='fw-medium font-s'>{user_details.name} ({user_details?.username})</span></>}
            />

            <AddAzureOpenaiKeysDialog
                open={showAzureKeysDialog}
                onOpenChange={() => setShowAzureKeysDialog(false)}
                selectedUser={user_details}
                setSelectedUser={set_user_details}
                title="Manage Azure Keys"
                message={<>Add/Edit Azure OpenAI keys for user: <br /><span className='fw-medium font-s'>{user_details.name} ({user_details?.username})</span></>}
            />

            <AddOpenaiCompatibleAPIkeyDialog
                open={showOpenaiCompatibleKeysDialog}
                onOpenChange={() => setShowOpenaiCompatibleKeysDialog(false)}
                selectedUser={user_details}
                setSelectedUser={set_user_details}
                title="Manage OpenAI Compatible Keys"
                message={<>Add/Edit OpenAI compatible keys for user: <br /><span className='fw-medium font-s'>{user_details.name} ({user_details?.username})</span></>}
                />

            <AddBedrockAPIkeyDialog
                open={showBedrockKeysDialog}
                onOpenChange={() => setShowBedrockKeysDialog(false)}
                selectedUser={user_details}
                setSelectedUser={set_user_details}
                title="Manage Bedrock Keys"
                message={<>Add/Edit Bedrock keys for user: <br /><span className='fw-medium font-s'>{user_details.name} ({user_details?.username})</span></>}
            />

            {/* Manage Child Accounts Modal */}
            <Dialog
                open={manageChildAccountsDialog}
                onOpenChange={() => setManageChildAccountSDialog(false)}
                title='Manage child accounts'
                hideFooter
                body={
                    <div className='position-relative pb-3'>
                        <div className='shared-chat-band position-static m-0 font-xxs'>Managing for - {user_details.name} ({user_details.username})</div>
                        <ManageEnterpriseChildAccounts selectedUser={user_details} setSelectedUser={set_user_details} showTitle={false} />
                    </div>
                }
            />

            {/* Manage Templates Modal */}
            <Dialog
                open={manageTemplatesDialog}
                onOpenChange={() => setManageTemplatesDialog(false)}
                title='Manage templates'
                hideFooter
                body={
                    <div className='position-relative mb-3'>
                        <div className='shared-chat-band position-static m-0 font-xxs gap-2 flex-column'>
                            <p className='text-end color-dark'>Managing for - {user_details.name} ({user_details.username})</p>
                            <span className='font-us color-dark'>Any changes made will reflect in {user_details.username}'s account</span>
                        </div>
                        <MyTemplates selectedUser={user_details} setSelectedUser={set_user_details} />
                    </div>
                }
            />
        </>
    )
}

export default ManageUserTable_Actions