import React, { useContext, useEffect, useRef, useState } from 'react'
import { MdPersonAddAlt1 } from 'react-icons/md'
import Modal from 'react-bootstrap/Modal';
import ProtectedAxios from '../api/protectedAxios';
import { toast } from 'react-hot-toast';
import { RiCloseLine } from 'react-icons/ri'
import { GrFormAdd } from 'react-icons/gr'
import { FaPaperPlane } from 'react-icons/fa'
import { UserContext } from '../context/UserProvider';
import placeholderProfile from "../assets/placeholder-profile.jpg"

const RenameChat = ({ chat_id, prompt, filteredChatList, setFilteredChatList, viewingInChat, chatDetails, setChatDetails, className }) => {
    const [user] = useContext(UserContext)

    const [chatName, setChatName] = useState("")
    const [loadingChatName, setLoadingChatName] = useState(true)
    const [renamingChat, setRenamingChat] = useState(false)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const inputRef = useRef(null)

    useEffect(() => {
        loadChatName()
    }, [])

    const loadChatName = () => {
        setLoadingChatName(true)
        ProtectedAxios.post('/users/fetchChatName', { chat_id })
            .then(res => {
                if (res.data.chatName === null) {
                    setChatName(prompt)
                }
                else {
                    setChatName(res.data.chatName)
                }
                setLoadingChatName(false)
            })
            .catch(err => {
                setLoadingChatName(false)
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                } else {
                    console.log(err);
                }
            })
    }
    const renameChat = (e) => {
        e.preventDefault()
        setRenamingChat(true)
        ProtectedAxios.post('/users/renameChat', { chat_id, chatName })
            .then(res => {
                if (res.data) {
                    setRenamingChat(false)

                    if (viewingInChat) {
                        const updatedChatDetails = chatDetails
                        updatedChatDetails.chat_name = chatName
                        setChatDetails(updatedChatDetails)
                    }
                    else {
                        // Create a copy of the original array
                        const updatedChats = [...filteredChatList];

                        // Find the index of the chat object with the matching chat_id
                        const chatIndex = updatedChats.findIndex(chat => chat.chat_id === chat_id);
                        if (chatIndex !== -1) {
                            // Update the name of chat
                            updatedChats[chatIndex] = {
                                ...updatedChats[chatIndex],
                                chat_name: chatName,
                            };

                            // Set the updated chats
                            setFilteredChatList(updatedChats);
                        }
                    }

                    handleClose()
                }
            })
            .catch(err => {
                setRenamingChat(false)
                console.log(err);
                if (err.response.status === 500) {
                    toast.error(err.response.data.error, { id: 'rename-error' })
                }
                if (err.response.status === 422) {
                    toast(err.response.data.error, { icon: '⚠️', id: 'rename-error' })
                } else {
                    console.log(err);
                }
            })
    }

    const handleNameChange = e => {
        setChatName(e.target.value)
    }

    return (
        <>
            {true
                ?
                <div
                    onClick={handleShow}
                >
                    Rename
                </div>
                :
                <button className='edit-btn' id='share-button' title='share access' onClick={e => { handleShow() }}><MdPersonAddAlt1 className='edit-icon dark-icon' /></button>
            }



            <Modal show={show} onHide={handleClose} centered size='md'>
                <Modal.Header closeButton>
                    <Modal.Title>Rename Chat</Modal.Title>
                </Modal.Header>
                <form onSubmit={renameChat}>
                    <Modal.Body>
                        <div className='share-modal-body'>
                            <div className='input-grp'>
                                <label htmlFor="chat-name">Chat Name</label>
                                <input
                                    autoFocus={true}
                                    onFocus={e => e.target.select()}
                                    ref={inputRef}
                                    type="text"
                                    id="chat-name"
                                    value={chatName}
                                    onChange={e => handleNameChange(e)}
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='button' className='button button-danger' onClick={() => handleClose()}>
                            Close
                        </button>
                        <button type='submit' className='button' disabled={renamingChat}>
                            {renamingChat
                                ?
                                <>
                                    Rename
                                    <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </>

                                :
                                "Rename"
                            }
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    )
}

export default RenameChat