import { BsFillInfoCircleFill, BsTextareaT } from "react-icons/bs"
import { FaCrown, FaRegFilePdf } from "react-icons/fa"
import { GoMultiSelect } from "react-icons/go"
import { GrFormAdd, GrFormClose } from "react-icons/gr"
import { MdClose, MdDragIndicator, MdTextRotationNone } from "react-icons/md"
import { PiTextAa } from "react-icons/pi"
import { RiDeleteBin4Line, RiFileExcel2Line } from "react-icons/ri"
import { VscGistSecret } from "react-icons/vsc"
import { DragDropContainer, DropTarget } from 'react-drag-drop-container';
import { LiaHandRock } from "react-icons/lia"
import { TbDragDrop2 } from "react-icons/tb"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import toast from "react-hot-toast"
import ProtectedAxios from "../../api/protectedAxios"
import CompanySelector from "./CompanySelector"
import CategorySelector from "./CategorySelector"
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
import { useState } from "react"
import PasswordInput from "../ui/PasswordInput"

export const renderSelectedTemplatePreview = (templateStructure, templateStructureSetter, disabledFields = false) => {
    return <div className='form-container'>
        {
            templateStructure.map((element, i) => {
                return (
                    <div className={``} key={i} id={`element-item-${element.id}`}>

                        {element.type === "text"
                            &&
                            <>
                                <p>{templateStructure[i].content}</p>
                            </>
                        }

                        {element.type === "hidden-text"
                            &&
                            <div className='text-secondary'>
                                <span className='font-us'>This element will be hidden in template</span>
                                <p>{templateStructure[i].content}</p>
                            </div>
                        }

                        {element.type === "textarea"
                            &&
                            <>
                                <label for={`input-${element.id}`} className='label font-xxs'>{templateStructure[i].label}</label>
                                <input
                                    id={`input-${element.id}`}
                                    title={disabledFields ? "fields are disabled for preview" : ""}
                                    disabled={disabledFields}
                                    value={templateStructure[i]?.value}
                                    onChange={e => {
                                        templateStructureSetter(prev => {
                                            let updatedData = [...prev]
                                            updatedData[i].value = e.target.value
                                            return updatedData
                                        })
                                    }}
                                />
                            </>
                        }

                        {element.type === "select"
                            &&
                            <>
                                <label for={`input-${element.id}`} className='label font-xxs'>{element.label}</label>
                                <select
                                    className='form-select'
                                    title={disabledFields ? "fields are disabled for preview" : ""}
                                    disabled={disabledFields}
                                    id={`input-${element.id}`}
                                    value={templateStructure[i]?.value}
                                    onChange={e => {
                                        templateStructureSetter(prev => {
                                            let updatedData = [...prev]
                                            updatedData[i].value = e.target.value
                                            return updatedData
                                        })
                                    }}
                                >
                                    {element.options.map((option, option_key) => {
                                        return (
                                            <option
                                                key={option_key}
                                                value={option.value}
                                            >
                                                {option.key}
                                            </option>
                                        )
                                    })}
                                </select>
                            </>
                        }

                    </div>
                )
            })
        }
    </div>
}

const addElement = (dragData, structure, structureSetter, setTemplateBuilderError) => {
    setTemplateBuilderError("")

    const newId = `${dragData.type}-${new Date().getTime()}${structure.length}`
    const newElement = {
        id: newId,
        type: dragData.type,
        displayName: dragData.displayName,
        category: dragData.category,
        premium: dragData.premium,
        can_be_private: dragData.can_be_private,
        private: dragData.private,
        error: ""
    }

    if (dragData.type === "text") {
        newElement.content = ""
    }
    else if (dragData.type === "hidden-text") {
        newElement.content = ""
    }
    else if (dragData.type === "textarea") {
        newElement.label = ""
    }
    else if (dragData.type === "select") {
        newElement.label = ""
        newElement.options = []
    }
    else if (dragData.type === "plain-text-context") {
        newElement.content = ""
    }

    let insertedElementIndex = 0
    structureSetter(prev => {
        let updatedData = [...prev]

        updatedData.push(newElement)
        insertedElementIndex = updatedData.length - 1

        return updatedData
    })

    setTimeout(() => {
        document.getElementById(`element-item-${insertedElementIndex}`)?.scrollIntoView({ behavior: "smooth" })
    }, 100)
}

const deleteElement = (_element, structure, structureSetter) => {
    let index = structure.findIndex(element => element.id === _element.id)

    structureSetter(prev => {
        let updatedElement = prev.filter(element => element.id !== _element.id)
        return updatedElement
    })
}

export const renderTemplateElements = (selectedTemplateData, newTemplateStructure, setNewTemplateStructure, setTemplateBuilderError) => {
    const templateBuilderElements = [
        {
            category: "static",
            items: [
                {
                    type: "text",
                    displayName: "Text",
                    icon: <PiTextAa />,
                    premium: false,
                    can_be_private: false,
                    private: false,
                    supported_type: ["CHAT_STARTER_PRIVATE", "CHAT_STARTER_OPEN", "NORMAL"]
                },
                {
                    type: "hidden-text",
                    displayName: "Hidden Text",
                    icon: <VscGistSecret />,
                    premium: true,
                    can_be_private: true,
                    private: false,
                    supported_type: ["CHAT_STARTER_PRIVATE", "CHAT_STARTER_OPEN"]
                },
            ],
        },
        {
            category: "dynamic",
            items: [
                {
                    type: "textarea",
                    displayName: "Textarea",
                    icon: <BsTextareaT />,
                    premium: false,
                    can_be_private: false,
                    private: false,
                    supported_type: ["CHAT_STARTER_PRIVATE", "CHAT_STARTER_OPEN", "NORMAL"]
                },
                {
                    type: "select",
                    displayName: "Select",
                    icon: <GoMultiSelect />,
                    premium: false,
                    can_be_private: false,
                    private: false,
                    supported_type: ["CHAT_STARTER_PRIVATE", "CHAT_STARTER_OPEN", "NORMAL"]
                },
            ],
        },
        {
            category: "context",
            items: [
                {
                    type: "plain-text-context",
                    displayName: "Plain Text",
                    icon: <MdTextRotationNone />,
                    premium: true,
                    can_be_private: true,
                    private: false,
                    supported_type: ["CHAT_STARTER_PRIVATE", "CHAT_STARTER_OPEN"]
                },
                {
                    type: "pdf-context",
                    displayName: "PDF",
                    icon: <FaRegFilePdf />,
                    premium: true,
                    can_be_private: true,
                    private: false,
                    supported_type: ["CHAT_STARTER_PRIVATE", "CHAT_STARTER_OPEN"]
                },
                {
                    type: "csv-context",
                    displayName: "CSV",
                    icon: <RiFileExcel2Line />,
                    premium: true,
                    can_be_private: true,
                    private: false,
                    supported_type: ["CHAT_STARTER_PRIVATE", "CHAT_STARTER_OPEN"]
                },
            ],
        },
    ]

    return templateBuilderElements.map((templateBuilderElement, i) => {
        if (templateBuilderElement.items.filter(item => item.supported_type.includes(selectedTemplateData?.type)).length > 0) {
            return <div key={i} className='template-items-category'>
                <div className='template-item-header'>
                    <p className='text-capitalize'>{templateBuilderElement.category}</p>
                    <span />
                </div>

                <div className='template-items'>
                    {templateBuilderElement.items.map((item, j) => {
                        if (item.supported_type.includes(selectedTemplateData?.type)) {
                            return <div key={j} className='drag-item-container'>
                                <DragDropContainer
                                    dragData={{ type: item.type, displayName: item.displayName, category: templateBuilderElement.category, premium: item.premium, private: item.private, can_be_private: item.can_be_private }}
                                    targetKey="template-builder-area"
                                    customDragElement={
                                        <div className='template-item'>
                                            {item.icon}
                                            <p>{item.displayName}</p>
                                        </div>
                                    }
                                >
                                    {item.premium
                                        &&
                                        <FaCrown className='prem-icon' />
                                    }
                                    <div className='template-item'>
                                        {item.icon}
                                        <p>{item.displayName}</p>
                                    </div>
                                </DragDropContainer>
                                <button
                                    className='edit-btn'
                                    value={JSON.stringify({ type: item.type, displayName: item.displayName, category: templateBuilderElement.category, premium: item.premium, private: item.private, can_be_private: item.can_be_private })}
                                    onClick={e => addElement(JSON.parse(e.target.value), newTemplateStructure, setNewTemplateStructure, setTemplateBuilderError)}
                                >
                                    <GrFormAdd />
                                </button>
                            </div>
                        }
                    })}
                </div>

            </div>
        }
    })
}

export const renderSelectedTemplateElements = (templateStructure, templateStructureSetter, handleTemplateItemSort, templateBuilderError, setTemplateBuilderError) => {
    return <DropTarget
        targetKey="template-builder-area"
        onHit={(e) => {
            addElement(e.dragData, templateStructure, templateStructureSetter, setTemplateBuilderError)
        }}
    >
        <div className='template-elements-container'>
            {templateBuilderError.length > 0
                &&
                <div className='border rounded p-2 mb-3 d-flex justify-content-between align-items-center gap-2'>
                    <p className='text-danger m-0 font-xxs'>{templateBuilderError}</p>
                    <button
                        className='edit-btn'
                        style={{ flex: "none" }}
                        title='hide'
                        onClick={e => {
                            setTemplateBuilderError("")
                        }}>
                        <span className='edit-icon'>-</span>
                    </button>
                </div>
            }

            {templateStructure.length === 0
                ?
                <div className='w-100 d-flex flex-column justify-content-center align-items-center' style={{ height: "50dvh" }}>
                    <div className='d-flex flex-column'>
                        <p className='text-center no-elements-message' style={{ color: "var(--color-light)" }}>
                            Drag the elements here <LiaHandRock className='font-m' />
                            <br />
                            or click the + icon next to the element
                        </p>
                    </div>
                </div>

                :
                <div>
                    {templateStructure.map((element, i) => {
                        return (
                            <>
                                <DropTarget
                                    id={`element-drop-target-${i}`}
                                    targetKey="template-element"
                                    highlightClassName="element-dropping"
                                    onHit={e => {
                                        handleTemplateItemSort(e)
                                    }}
                                >
                                    <div className="item-drop-indicator"><TbDragDrop2 /><p>drop here</p></div>
                                    <div
                                        id={`element-item-${i}`}
                                        className={`template-element pt-4 ${element.error.length > 0 && 'border border-danger'}`}
                                        key={i}
                                    >
                                        {element?.premium
                                            &&
                                            <FaCrown className='prem-icon' style={{ top: "var(--size-us)", right: "var(--size-us)" }} />
                                        }
                                        {element.error.length > 0
                                            &&
                                            <div className='border rounded p-2 mb-3 d-flex justify-content-between align-items-center gap-2'>
                                                <p className='text-danger m-0 font-xxs'>{element.error}</p>
                                                <button
                                                    className='edit-btn'
                                                    style={{ flex: "none" }}
                                                    title='hide'
                                                    onClick={e => {
                                                        templateStructureSetter(prev => {
                                                            let updatedData = [...prev]
                                                            updatedData[i].error = ''
                                                            return updatedData
                                                        })
                                                    }}>
                                                    <span className='edit-icon'>-</span>
                                                </button>
                                            </div>
                                        }
                                        <div className='element-header'>

                                            <DragDropContainer
                                                dragData={{ index: i }}
                                                targetKey="template-element"
                                            >
                                                <div className='left'>
                                                    <MdDragIndicator className='edit-icon' />
                                                    <p>{element.displayName}</p>
                                                </div>
                                            </DragDropContainer>

                                            <button
                                                className='edit-btn'
                                                title='remove element'
                                                onClick={() => deleteElement(element, templateStructure, templateStructureSetter)}
                                            >
                                                <RiDeleteBin4Line className='edit-icon' />
                                            </button>
                                        </div>
                                        {element.type === "text"
                                            &&
                                            <>
                                                <label for={`textarea-${element.id}`} className='label font-xxs'>Content</label>
                                                <textarea
                                                    id={`textarea-${element.id}`}
                                                    value={templateStructure[i].content}
                                                    onChange={e => {
                                                        templateStructureSetter(prev => {
                                                            let updatedData = [...prev]
                                                            updatedData[i].content = e.target.value
                                                            updatedData[i].error = ""
                                                            return updatedData
                                                        })
                                                    }}
                                                />
                                            </>
                                        }

                                        {element.type === "hidden-text"
                                            &&
                                            <>
                                                <label for={`textarea-${element.id}`} className='label font-xxs'>Content</label>
                                                <textarea
                                                    id={`textarea-${element.id}`}
                                                    value={templateStructure[i].content}
                                                    onChange={e => {
                                                        templateStructureSetter(prev => {
                                                            let updatedData = [...prev]
                                                            updatedData[i].content = e.target.value
                                                            updatedData[i].error = ""
                                                            return updatedData
                                                        })
                                                    }}
                                                />
                                            </>
                                        }

                                        {element.type === "textarea"
                                            &&
                                            <>
                                                <label for={`input-${element.id}`} className='label font-xxs'>Label for textarea</label>
                                                <input
                                                    id={`input-${element.id}`}
                                                    value={templateStructure[i].label}
                                                    onChange={e => {
                                                        templateStructureSetter(prev => {
                                                            let updatedData = [...prev]
                                                            updatedData[i].label = e.target.value
                                                            updatedData[i].error = ""
                                                            return updatedData
                                                        })
                                                    }}
                                                />
                                            </>
                                        }

                                        {element.type === "select"
                                            &&
                                            <>
                                                <label for={`input-${element.id}`} className='label font-xxs'>Label for select</label>
                                                <input
                                                    id={`input-${element.id}`}
                                                    value={templateStructure[i].label}
                                                    onChange={e => {
                                                        templateStructureSetter(prev => {
                                                            let updatedData = [...prev]
                                                            updatedData[i].label = e.target.value
                                                            updatedData[i].error = ""
                                                            return updatedData
                                                        })
                                                    }}
                                                />
                                                <hr />
                                                <p className='font-xxs m-0'>Options: </p>
                                                {element.options.length === 0
                                                    ? <p className="font-xxs" style={{ color: "var(--color-light)" }}>No options added</p>
                                                    :
                                                    <div className='options'>
                                                        {element.options.map((option, option_key) => {
                                                            return (
                                                                <span
                                                                    key={option_key}
                                                                    title={`value: ${option.value}`}
                                                                    className={`tag tag-color-${option_key % 4 + 1} editing`}
                                                                >
                                                                    {option.key}
                                                                    <button
                                                                        type='button'
                                                                        title='remove option'
                                                                        id={`remove-option-button-${element.id}-${option_key}`}
                                                                        className='edit-btn remove-tag-button'
                                                                        onClick={e => {
                                                                            templateStructureSetter(prev => {
                                                                                let updatedData = [...prev]
                                                                                let currentOptions = updatedData[i].options
                                                                                let updatedOptions = currentOptions.filter(_option => _option !== option)

                                                                                updatedData[i].options = updatedOptions
                                                                                return updatedData
                                                                            })
                                                                        }}
                                                                    >
                                                                        <GrFormClose className={`edit-icon`} />
                                                                    </button>
                                                                </span>
                                                            )
                                                        })}
                                                    </div>
                                                }
                                                <form
                                                    onSubmit={e => {
                                                        e.preventDefault()
                                                        let key = e.target[0].value
                                                        let value = e.target[1].value

                                                        templateStructureSetter(prev => {
                                                            let updatedData = [...prev]
                                                            updatedData[i].options.push({ key, value })
                                                            updatedData[i].error = ""
                                                            return updatedData
                                                        })

                                                        //clear the values
                                                        e.target[0].value = ""
                                                        e.target[1].value = ""

                                                        e.target[1].blur()
                                                    }}
                                                >
                                                    <div className='d-flex gap-2'>
                                                        <div className='w-50'>
                                                            <label className='font-xxs' for={`key-input-${element.id}`}>Key</label>
                                                            <input required id={`key-input-${element.id}`} />
                                                        </div>
                                                        <div className='w-50'>
                                                            <label className='font-xxs' for={`value-input-${element.id}`}>Value</label>
                                                            <input required id={`value-input-${element.id}`} />
                                                        </div>
                                                    </div>
                                                    <div className='w-100 d-flex justify-content-end'>
                                                        <button type='submit' className='button-ghost font-us p-2 px-4 mt-2 rounded border-0'>Add Option</button>
                                                    </div>
                                                </form>
                                            </>
                                        }

                                        {element.type === "plain-text-context"
                                            &&
                                            <>
                                                <label for={`textarea-plain-text-context-${element.id}`} className='label font-xxs'>Content</label>
                                                <textarea
                                                    id={`textarea-plain-text-context-${element.id}`}
                                                    value={templateStructure[i].content}
                                                    onChange={e => {
                                                        templateStructureSetter(prev => {
                                                            let updatedData = [...prev]
                                                            updatedData[i].content = e.target.value
                                                            updatedData[i].error = ""
                                                            return updatedData
                                                        })
                                                    }}
                                                />
                                            </>
                                        }


                                        {element.type === "pdf-context" && (
                                            <>
                                                <div className="file-upload-container">
                                                    {
                                                        (templateStructure[i].content && templateStructure[i].filename)
                                                            ?
                                                            <div className='selected-file-details py-2'>
                                                                <span>{templateStructure[i].filename}</span>
                                                                <div className='d-flex align-items-center'>
                                                                    <button
                                                                        className='edit-btn'
                                                                        title="remove file"
                                                                        onClick={() => {
                                                                            templateStructureSetter((prev) => {
                                                                                let updatedData = [...prev];
                                                                                updatedData[i].files = null;
                                                                                delete updatedData[i].content
                                                                                delete updatedData[i].filename

                                                                                return updatedData;
                                                                            });
                                                                        }}
                                                                    >
                                                                        <MdClose className='w-xs h-xs' />
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            : templateStructure[i].files
                                                                ?
                                                                <div className='selected-file-details'>
                                                                    <span>{templateStructure[i].files.name}</span>
                                                                    <button
                                                                        className='edit-btn'
                                                                        onClick={() => {
                                                                            templateStructureSetter((prev) => {
                                                                                let updatedData = [...prev];
                                                                                updatedData[i].files = null;
                                                                                return updatedData;
                                                                            });
                                                                        }}
                                                                    >
                                                                        ×
                                                                    </button>
                                                                </div>

                                                                :
                                                                <>
                                                                    <p className='file-upload-label'>Drop file or click to upload</p>
                                                                    <input
                                                                        type="file"
                                                                        accept='.pdf'
                                                                        id={`input-pdf-context-${element.id}`}
                                                                        className='template-builder-file-selector'
                                                                        onChange={e => {
                                                                            // Ensure only one file is selected
                                                                            const file = e.target.files[0];
                                                                            if (!file || file.type !== "application/pdf") {
                                                                                // Handle invalid file types or no file selected
                                                                                templateStructureSetter((prev) => {
                                                                                    let updatedData = [...prev];
                                                                                    updatedData[i].files = null; // Clear the files
                                                                                    updatedData[i].error = "Please select a valid PDF file.";
                                                                                    return updatedData;
                                                                                });
                                                                                return; // Exit the function
                                                                            }

                                                                            templateStructureSetter((prev) => {
                                                                                let updatedData = [...prev];
                                                                                updatedData[i].files = file; // Store the single file
                                                                                updatedData[i].error = "";
                                                                                return updatedData;
                                                                            });
                                                                        }}
                                                                    />
                                                                </>
                                                    }
                                                </div>
                                            </>
                                        )}


                                        {element.type === "csv-context" && (
                                            <>
                                                <div className="file-upload-container">
                                                    {
                                                        (templateStructure[i].content && templateStructure[i].filename)
                                                            ?
                                                            <div className='selected-file-details py-2'>
                                                                <span>{templateStructure[i].filename}</span>
                                                                <div className='d-flex align-items-center'>
                                                                    <button
                                                                        className='edit-btn'
                                                                        title="remove file"
                                                                        onClick={() => {
                                                                            templateStructureSetter((prev) => {
                                                                                let updatedData = [...prev];
                                                                                updatedData[i].files = null;
                                                                                delete updatedData[i].content
                                                                                delete updatedData[i].filename

                                                                                return updatedData;
                                                                            });
                                                                        }}
                                                                    >
                                                                        <MdClose className='w-xs h-xs' />
                                                                    </button>
                                                                </div>
                                                            </div>

                                                            : templateStructure[i].files
                                                                ?
                                                                <div className='selected-file-details'>
                                                                    <span>{templateStructure[i].files.name}</span>
                                                                    <button
                                                                        className='edit-btn'
                                                                        onClick={() => {
                                                                            templateStructureSetter((prev) => {
                                                                                let updatedData = [...prev];
                                                                                updatedData[i].files = null;
                                                                                return updatedData;
                                                                            });
                                                                        }}
                                                                    >
                                                                        ×
                                                                    </button>
                                                                </div>

                                                                :
                                                                <>
                                                                    <p className='file-upload-label'>Drop file or click to upload</p>
                                                                    <input
                                                                        type="file"
                                                                        accept='.csv'
                                                                        id={`input-pdf-context-${element.id}`}
                                                                        className='template-builder-file-selector'
                                                                        onChange={e => {
                                                                            // Ensure only one file is selected
                                                                            const file = e.target.files[0];
                                                                            if (!file || file.type !== "text/csv") {
                                                                                // Handle invalid file types or no file selected
                                                                                templateStructureSetter((prev) => {
                                                                                    let updatedData = [...prev];
                                                                                    updatedData[i].files = null; // Clear the files
                                                                                    updatedData[i].error = "Please select a valid CSV file.";
                                                                                    return updatedData;
                                                                                });
                                                                                return; // Exit the function
                                                                            }

                                                                            templateStructureSetter((prev) => {
                                                                                let updatedData = [...prev];
                                                                                updatedData[i].files = file; // Store the single file
                                                                                updatedData[i].error = "";
                                                                                return updatedData;
                                                                            });
                                                                        }}
                                                                    />
                                                                </>
                                                    }
                                                </div>
                                            </>
                                        )}

                                        {element.can_be_private
                                            &&
                                            <div className='form-check mt-1'>
                                                <input
                                                    class="form-check-input"
                                                    id={`private-status-checkbox-${i}`}
                                                    type="checkbox"
                                                    checked={templateStructure[i].private}
                                                    onChange={() => {
                                                        templateStructureSetter(prev => {
                                                            let updatedData = [...prev]
                                                            updatedData[i].private = !updatedData[i].private
                                                            return updatedData
                                                        })
                                                    }}
                                                />
                                                <label class="mx-2 form-check-label text-secondary font-us cursor-pointer" htmlFor={`private-status-checkbox-${i}`}>Keep Private (content of this element won't be copied when template is duplicated or installed)</label>
                                            </div>
                                        }

                                    </div>
                                </DropTarget>
                            </>
                        )
                    })}
                </div>
            }
        </div>
    </DropTarget>
}

export const verifyTemplateBuilderStep = (_step, structure, structureSetter, stepSetter, setTemplateBuilderError, setVerifyingTemplateStep, selectedTemplate, setSelectedTemplate) => {
    // setVerifyingTemplateStep(true)
    if (_step === 0) {
        if (structure.length === 0) {
            setTemplateBuilderError("Add at least one or more element(s) to continue")
            setVerifyingTemplateStep(false)
            return
        }

        let errorAdded = false
        let errorRaisingElementIndex

        structure.forEach((element, i) => {
            if (!errorAdded) {
                if (element.type === "text") {
                    if (element.content.length === 0) {
                        structureSetter(prev => {
                            let updatedData = [...prev]
                            updatedData[i].error = "Please add content for the text"
                            return updatedData
                        })

                        document.getElementById(`element-item-${element.id}`).scrollIntoView({ behavior: "smooth" })
                        errorAdded = true
                        errorRaisingElementIndex = i
                    }
                }

                else if (element.type === "hidden-text") {
                    if (element.content.length === 0) {
                        structureSetter(prev => {
                            let updatedData = [...prev]
                            updatedData[i].error = "Please add content for the hidden text"
                            return updatedData
                        })

                        document.getElementById(`element-item-${element.id}`).scrollIntoView({ behavior: "smooth" })
                        errorAdded = true
                        errorRaisingElementIndex = i
                    }
                }

                else if (element.type === "textarea") {
                    if (element.label.length === 0) {
                        structureSetter(prev => {
                            let updatedData = [...prev]
                            updatedData[i].error = "Please add label for the textarea"
                            return updatedData
                        })

                        document.getElementById(`element-item-${element.id}`).scrollIntoView({ behavior: "smooth" })
                        errorAdded = true
                        errorRaisingElementIndex = i
                    }
                }

                else if (element.type === "select") {
                    if (element.label.length === 0) {
                        structureSetter(prev => {
                            let updatedData = [...prev]
                            updatedData[i].error = "Please add label for the select"
                            return updatedData
                        })

                        document.getElementById(`element-item-${element.id}`).scrollIntoView({ behavior: "smooth" })
                        errorAdded = true
                        errorRaisingElementIndex = i
                    }

                    else if (element.options.length === 0) {
                        structureSetter(prev => {
                            let updatedData = [...prev]
                            updatedData[i].error = "Please add atleast one option"
                            return updatedData
                        })

                        document.getElementById(`element-item-${element.id}`).scrollIntoView({ behavior: "smooth" })
                        errorAdded = true
                        errorRaisingElementIndex = i
                    }

                    else if (element.label.length === 0 && element.options.length === 0) {
                        structureSetter(prev => {
                            let updatedData = [...prev]
                            updatedData[i].error = "Please add a label and atleast one option"
                            return updatedData
                        })

                        document.getElementById(`element-item-${element.id}`).scrollIntoView({ behavior: "smooth" })
                        errorAdded = true
                        errorRaisingElementIndex = i
                    }

                    else {
                        structureSetter(prev => {
                            let updatedData = [...prev]
                            updatedData[i].value = updatedData[i].options[0].value
                            return updatedData
                        })
                    }

                }


                else if (element.type === "pdf-context") {
                    if (!element?.content && !element?.filename) {
                        if (!element?.files) {
                            structureSetter(prev => {
                                let updatedData = [...prev]
                                updatedData[i].error = "Please select a file"
                                return updatedData
                            })

                            document.getElementById(`element-item-${element.id}`).scrollIntoView({ behavior: "smooth" })
                            errorAdded = true
                            errorRaisingElementIndex = i
                        }
                    }
                }


                else if (element.type === "csv-context") {
                    if (!element?.content && !element?.filename) {
                        if (!element?.files) {
                            structureSetter(prev => {
                                let updatedData = [...prev]
                                updatedData[i].error = "Please select a file"
                                return updatedData
                            })

                            document.getElementById(`element-item-${element.id}`).scrollIntoView({ behavior: "smooth" })
                            errorAdded = true
                            errorRaisingElementIndex = i
                        }
                    }
                }


            }
        })


        if (!errorAdded) {
            if (selectedTemplate?.selectedCompany) {
                setSelectedTemplate({ ...selectedTemplate, isOfficial: true })
            } else {
                setSelectedTemplate({ ...selectedTemplate, isOfficial: false })
            }

            stepSetter(1)
        }
        else {
            if (errorRaisingElementIndex) {
                document.getElementById(`element-item-${errorRaisingElementIndex}`).scrollIntoView()
            }
        }
    }

    setVerifyingTemplateStep(false)
}

export const RenderSelectedTemplateDetailsForm = (selectedTemplateData, selectedTemplateDataSetter, user, mode, subscriptionDetail) => {
    if (!selectedTemplateData) {
        return;
    }
    return <>
        {(user.role_id !== 1)
            &&
            <div className='form-check d-sm-block'>
                <input className='form-check-input' id='publicAccessCheckbox' type='checkbox' checked={selectedTemplateData.is_public} onClick={e => selectedTemplateDataSetter({ ...selectedTemplateData, is_public: !selectedTemplateData.is_public, notify_creator_on_use: !selectedTemplateData.is_public === false ? false : selectedTemplateData.notify_creator_on_use, is_password_protected: !selectedTemplateData.is_public === true ? false : selectedTemplateData.is_password_protected })} />
                <label className="form-check-labe" htmlFor="publicAccessCheckbox" style={{ marginLeft: "5px" }}>
                    Public
                    <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        overlay={(props) => (
                            <Tooltip {...props}>
                                Upon activating this option, your template will be accessible to all users. Users will have the ability to try and install it directly from the Template Marketplace.<br />(You can always set it to private again)
                            </Tooltip>
                        )}
                        placement="right"
                    >
                        <button type='button' className='tooltip-button'><BsFillInfoCircleFill /></button>
                    </OverlayTrigger>
                </label>
            </div>
        }

        {(!selectedTemplateData.is_public)
            &&
            <div className='form-check d-sm-block'>
                <input className='form-check-input' id='passwordProtectedCheckbox' type='checkbox' checked={selectedTemplateData.is_password_protected} onClick={e => selectedTemplateDataSetter({ ...selectedTemplateData, is_password_protected: !selectedTemplateData.is_password_protected })} />
                <label className="form-check-labe" htmlFor="passwordProtectedCheckbox" style={{ marginLeft: "5px" }}>
                    Protected
                    <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        overlay={(props) => (
                            <Tooltip {...props}>
                                This allows other users to access your private templates with the help of password you set on this template.
                            </Tooltip>
                        )}
                        placement="right"
                    >
                        <button type='button' className='tooltip-button'><BsFillInfoCircleFill /></button>
                    </OverlayTrigger>
                </label>
            </div>
        }

        {(user?.is_official_template_creator && selectedTemplateData.is_public)
            &&
            <div className='form-check d-sm-block'>
                <input
                    className='form-check-input'
                    id='notifyCreatorCheckbox'
                    type='checkbox'
                    checked={selectedTemplateData.notify_creator_on_use}
                    onClick={e => selectedTemplateDataSetter({ ...selectedTemplateData, notify_creator_on_use: !selectedTemplateData.notify_creator_on_use })}
                />
                <label className="form-check-labe" htmlFor="notifyCreatorCheckbox" style={{ marginLeft: "5px" }}>
                    Notify me on use
                    <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        overlay={(props) => (
                            <Tooltip {...props}>
                                You will receive an email whenever a user uses this public template. Users will see a message informing them that their details will be shared with the template creator.
                            </Tooltip>
                        )}
                        placement="right"
                    >
                        <button type='button' className='tooltip-button'><BsFillInfoCircleFill /></button>
                    </OverlayTrigger>
                </label>
            </div>
        }

        {(user.role_id !== 1 && user?.is_official_template_creator && selectedTemplateData.is_public)
            &&
            <div className='form-check d-sm-block'>
                <input
                    className='form-check-input'
                    id='officialCheckbox'
                    type='checkbox'
                    checked={selectedTemplateData.isOfficial}
                    onClick={e => {
                        if (!selectedTemplateData.isOfficial === false) {
                            selectedTemplateDataSetter({ ...selectedTemplateData, isOfficial: !selectedTemplateData.isOfficial, selectedCompany: null })
                        } else {
                            selectedTemplateDataSetter({ ...selectedTemplateData, isOfficial: !selectedTemplateData.isOfficial })
                        }
                    }}
                />
                <label className="form-check-labe" htmlFor="officialCheckbox" style={{ marginLeft: "5px" }}>
                    Official
                    <OverlayTrigger
                        delay={{ hide: 450, show: 300 }}
                        overlay={(props) => (
                            <Tooltip {...props}>
                                Official templates will be seen under the offical section of the template marketplace. You will have an additional option to add company details in official template.
                            </Tooltip>
                        )}
                        placement="right"
                    >
                        <button type='button' className='tooltip-button'><BsFillInfoCircleFill /></button>
                    </OverlayTrigger>
                </label>
            </div>
        }

        <div className='input-grp'>
            <label htmlFor="name">Name <span className='text-danger'>*</span></label>
            <input required autoFocus type="text" id="name" value={selectedTemplateData.name} onChange={e => selectedTemplateDataSetter({ ...selectedTemplateData, name: e.target.value })} />
        </div>

        <div className='input-grp'>
            <label htmlFor="description">Description <span className='text-danger'>*</span></label>
            <textarea required type="text" id="description" value={selectedTemplateData.description} onChange={e => selectedTemplateDataSetter({ ...selectedTemplateData, description: e.target.value })} />
        </div>

        {(user.role_id === 1 || (user.is_official_template_creator && selectedTemplateData.isOfficial))
            &&
            <CompanySelector
                selectedTemplateData={selectedTemplateData}
                setSelectedTemplate={selectedTemplateDataSetter}

            />
        }

        <CategorySelector
            user={user}
            selectedTemplate={selectedTemplateData}
            setSelectedTemplate={selectedTemplateDataSetter}
        />

        {selectedTemplateData.is_password_protected
            &&
            <div className='input-grp'>
                <label htmlFor="template-password">Password <span className='text-danger'>*</span></label>
                <PasswordInput
                    id="template-password"
                    value={selectedTemplateData.password}
                    onValueChange={e => selectedTemplateDataSetter({ ...selectedTemplateData, password: e.target.value })}
                />
            </div >
        }

        <div className='form-check d-sm-block'>
            <input className='form-check-input' id='additionalOptionsCheckbox' type='checkbox' checked={selectedTemplateData.showing_additional_options} onClick={e => selectedTemplateDataSetter({ ...selectedTemplateData, showing_additional_options: !selectedTemplateData.showing_additional_options })} />
            <label className="form-check-labe" style={{ marginLeft: "5px" }} htmlFor="additionalOptionsCheckbox">
                Enable Style and Tone selection
                <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => (
                        <Tooltip {...props}>
                            By enabling this option, you will be able to choose from a selection of predefined styles and tones for your prompt. This allows you to tailor the language and manner in which the AI responds to your input.
                        </Tooltip>
                    )}
                    placement="right"
                >
                    <button type='button' className='tooltip-button'><BsFillInfoCircleFill /></button>
                </OverlayTrigger>
            </label>
        </div>

        {
            ((mode === "ADMIN" && user?.plan_id) ? user.plan_id === process.env.REACT_APP_PRICE_C_ID : subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_C_ID)
            &&
            <div className='input-grp flex-row gap-2 align-items-start'>
                <input className='form-check-input' id='shareWithChildAccountsCheckbox' type='checkbox' checked={selectedTemplateData.isSharingWithChildAccounts} onClick={e => selectedTemplateDataSetter({ ...selectedTemplateData, isSharingWithChildAccounts: !selectedTemplateData.isSharingWithChildAccounts })} />
                <label className="form-check-label" htmlFor="shareWithChildAccountsCheckbox" style={{ marginLeft: "5px" }}>
                    Share with all my child accounts
                </label>
            </div>
        }
    </>
}

export const setTemplateStructure = async (template_id, setSelectedTemplateStructure, functionToExecute) => {
    toast.loading("Please wait", { id: "load-prompt", duration: Infinity })
    ProtectedAxios.get(`/users/getTemplatePrompt/${template_id}`)
        .then(res => {
            setSelectedTemplateStructure(res.data.prompt)
            toast.dismiss("load-prompt")
            functionToExecute()
        })
        .catch(err => {
            console.log(err);
            toast.dismiss("load-prompt")

            if (err.response.data.error) {
                return toast.error(err.response.data.error)
            }

            toast.error("Could not get your template prompt! Please try again later.")
        })
}