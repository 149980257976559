import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../context/UserProvider';
import ProtectedAxios from '../api/protectedAxios'
import { useLocation, useNavigate } from 'react-router-dom';
import AddOpenaiAPIkeyDialog from './dialogs/AddOpenaiAPIkeyDialog';
import { toast } from 'react-hot-toast';
import Template from './Template';
import { IoSend } from 'react-icons/io5';
import AdDialog from './dialogs/AdDialog';
import AddGeminiAPIkeyDialog from './dialogs/AddGeminiAPIkeyDialog';
import { GrClose } from 'react-icons/gr';
import logo from '../assets/logo.svg'
import MaxAiInteractionsReachedDialog from './dialogs/MaxAiInteractionsReachedDialog';
import AddBedrockAPIkeyDialog from './dialogs/AddBedrockAPIkeyDialog';
import { decryptAndGetLocalStorageItem } from '../utils/helper';
import useSubscriptionDetails from '../hooks/useSubscriptionDetails';
import OpenaiCompatibleIcon from './custom-svgs/OpenaiCompatibleIcon';
import AddOpenaiCompatibleAPIkeyDialog from './dialogs/AddOpenaiCompatibleAPIkeyDialog';


const NewChat = ({ prompt, setPrompt, templateId, setTemplateId, updateTokens }) => {
    const [user] = useContext(UserContext);
    const [subscriptionDetail] = useSubscriptionDetails();
    const navigate = useNavigate();
    const location = useLocation();

    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [isUsingSelfAPI, setIsUsingSelfAPI] = useState(true);
    const [selectedModelProvider, setSelectedModelProvider] = useState("");
    const [loading, setLoading] = useState(false);

    const [showOpenaiApiKeyModal, setShowOpenaiApiKeyModal] = useState(false);
    const [showGeminiApiKeyModal, setShowGeminiApiKeyModal] = useState(false);
    const [showBedrockApiKeyModal, setShowBedrockApiKeyModal] = useState(false);
    const [showOpenaiCompatibleApiKeyModal, setShowOpenaiCompatibleApiKeyModal] = useState(false);
    const [showMaxInteractionsReachedModal, setShowMaxInteractionsReachedModal] = useState(false);
    const [showAdModal, setShowAdModal] = useState(false);

    useEffect(() => {
        const previousPrompt = location.state?.prompt;
        const previousTemplateId = location.state?.template_id;
        if (previousTemplateId) {
            setTemplateId(previousTemplateId);
        } else {
            if (previousPrompt) {
                setPrompt(previousPrompt);
            }
        }
        // Reset location state
        navigate(`${location.pathname}${location.search}`, { state: {}, replace: true });
    }, []);

    useEffect(() => {
        const savedSelectedModelProvider = localStorage.getItem("selected-model-provider");
        if (savedSelectedModelProvider) {
            setSelectedModelProvider(savedSelectedModelProvider);
        } else {
            setSelectedModelProvider("DEFAULT");
        }
    }, [])

    const handleSubmit = async (e, _key, _prompt, _templateId, _addTemplateCreatorAsCollaborator) => {
        if (!user?.user_id) {
            const currentTemplateData = JSON.stringify(prompt);
            localStorage.setItem(`startChatTemplateData-${_templateId}`, currentTemplateData);

            const searchParams = new URLSearchParams(location.search);
            navigate(`/login?redirectTo=${location.pathname}?${searchParams.toString()}`);

            toast("Login/signup to continue");

            return;
        }

        let apiKey =
            _key
                ? _key
                : selectedModelProvider === "OPENAI" ? user.openai_api_key
                    : selectedModelProvider === "GEMINI" ? user.gemini_api_key
                        : selectedModelProvider === "BEDROCK" ? (user.bedrock_access_key && user.bedrock_secret_access_key && user.bedrock_model_id && user.bedrock_model_region) ? user.bedrock_access_key : false
                            : selectedModelProvider === "OPENAI_COMPATIBLE" && (user.openai_compatible_endpoint && user.openai_compatible_api_key && user.openai_compatible_model) ? user.openai_compatible_api_key : false

        if (e) {
            e.preventDefault()
        }

        if (apiKey || user.role_id === 5 || !isUsingSelfAPI || selectedModelProvider === "DEFAULT") {
            setLoading(true);

            let updatedPrompt = prompt;
            if (_prompt) {
                updatedPrompt = _prompt;
            }

            let updated_template_id = selectedTemplate?.template_id;
            if (_templateId) {
                updated_template_id = _templateId;
            }

            ProtectedAxios.post('/users/startChat', { user_id: user.user_id, customer_id: user.customer_id, is_using_self_api: isUsingSelfAPI, role_id: user.role_id, model_provider: selectedModelProvider, template_id: updated_template_id })
                .then(res => {
                    navigate(`/chat/${res.data.chat_id}`, {
                        state: {
                            chatDetails: res.data,
                            prompt: updatedPrompt,
                            addTemplateCreatorAsCollaborator: _addTemplateCreatorAsCollaborator,
                            subscriptionDetail: subscriptionDetail,
                        }
                    });
                })
                .catch(err => {
                    console.log(err);

                    if (err.response.status === 500) {
                        toast.error(err.response.data.error);
                    }
                    else if (err.response.status === 429) {     //max ai interactions reached
                        setShowMaxInteractionsReachedModal(true);
                    }
                    else if (err.response.status === 403) {     //plan limits reached
                        toast.error(err.response.data.error);
                        navigate('/upgrade');
                    }
                    else if (err.response.status === 401) {     //not enough tokens
                        toast.error(err.response.data.error);
                    }
                    setLoading(false);
                    setTemplateId(null);
                    setSelectedTemplate(null);
                    setPrompt("");
                })
        }
        else {
            if (selectedModelProvider === "OPENAI") {
                setShowOpenaiApiKeyModal(true);
            }
            else if (selectedModelProvider === "GEMINI") {
                setShowGeminiApiKeyModal(true);
            }
            else if (selectedModelProvider === "BEDROCK") {
                setShowBedrockApiKeyModal(true);
            }
            else if (selectedModelProvider === "OPENAI_COMPATIBLE") {
                setShowOpenaiCompatibleApiKeyModal(true);
            }
        }
    }
    const handleTextareaChange = (e, stateSetterFunction) => {
        const textarea = document.getElementById("prompt-input");
        let screenWidth = window.screen.width
        const lineCount = textarea.value.split('\n').length;

        let size = parseInt(getComputedStyle(document.body).getPropertyValue('--size-xs').slice(0, -2))

        // Calculate the number of rows needed based on line count and content height
        const rowsNeeded = Math.max(lineCount, textarea.scrollHeight / size / 2);

        if (textarea.value.length === 0) {
            if (screenWidth <= 786) {
                textarea.rows = 2;
            }
            else {
                textarea.rows = 1;
            }
        } else {
            textarea.rows = rowsNeeded;
        }

        if (stateSetterFunction) {
            stateSetterFunction(textarea.value)
        }
    }


    useEffect(() => {
        // For mobile devices make the input box height larger
        const textarea = document.getElementById("prompt-input");
        let screenWidth = window.screen.width
        if (screenWidth <= 786) {
            textarea.rows = 2;
        }

        // Check and use saved prompt if available
        const savedPrompt = decryptAndGetLocalStorageItem("start_chat_saved_prompt");
        if (savedPrompt) {
            setPrompt(savedPrompt);
            localStorage.removeItem("start_chat_saved_prompt");
        }
    }, []);
    useEffect(() => {
        if (selectedModelProvider === "GEMINI") {
            setIsUsingSelfAPI(true)
        }
    }, [selectedModelProvider]);

    const selectModelProvider = (_model) => {
        setSelectedModelProvider(_model);
        localStorage.setItem("selected-model-provider", _model);
    }

    return (
        <>
            <div className='w-100'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex chat-select'>
                        <div
                            onClick={() => selectModelProvider("DEFAULT")}
                            className={`chat-select-item ${selectedModelProvider === "DEFAULT" ? "selected-provider" : ""}`}
                        >
                            <div className='d-flex justify-content-center align-items-center gap-2'>
                                <img src={logo} alt="sagecollab" className="w-m" />
                                <p className='m-0 font-xs'>SageCollab</p>
                            </div>
                        </div>

                        <div
                            onClick={() => selectModelProvider("OPENAI")}
                            className={`chat-select-item ${selectedModelProvider === "OPENAI" ? "selected-provider" : ""}`}
                        >
                            <div className='d-flex justify-content-center align-items-center gap-2'>
                                <img src="https://www.svgrepo.com/show/306500/openai.svg" style={{ width: '20px' }} alt="openai" />
                                <p className='m-0 font-xs'>OpenAI</p>
                            </div>
                        </div>

                        <div
                            onClick={() => selectModelProvider("GEMINI")}
                            className={`chat-select-item ${selectedModelProvider === "GEMINI" ? "selected-provider" : ""}`}
                        >
                            <div className='d-flex justify-content-center align-items-center gap-2'>
                                <img src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/google-gemini-icon.png" style={{ width: '20px' }} alt="gemini" />
                                <p className='m-0 font-xs'>Gemini</p>
                            </div>
                        </div>

                        <div
                            onClick={() => selectModelProvider("BEDROCK")}
                            className={`chat-select-item ${selectedModelProvider === "BEDROCK" ? "selected-provider" : ""}`}
                        >
                            <div className='d-flex justify-content-center align-items-center gap-2'>
                                <img src="https://www.outsystems.com/Forge_CW/_image.aspx/Q8LvY--6WakOw9afDCuuGbQ9u-QKbiqiEaG1FDMiKVo=/aws-bedrock-runtime-2023-01-04%2000-00-00-2024-09-12%2014-12-44" className='rounded-circle' style={{ width: '25px' }} alt="bedrock" />
                                <p className='m-0 font-xs'>Bedrock</p>
                            </div>
                        </div>

                        <div
                            onClick={() => selectModelProvider("OPENAI_COMPATIBLE")}
                            className={`chat-select-item ${selectedModelProvider === "OPENAI_COMPATIBLE" ? "selected-provider" : ""}`}
                        >
                            <div className='d-flex justify-content-center align-items-center gap-2'>
                                <OpenaiCompatibleIcon className="w-m h-m" />
                                <p className='m-0 font-xs'>OpenAI Compatible</p>
                            </div>
                        </div>
                    </div>
                </div>

                <form id='add-message-form' className='add-message-container w-100' onSubmit={handleSubmit}>
                    <div className='textarea-container template-grp'>
                        <button type='submit' id='copy-promptReply-response-button' disabled={prompt.length === 0 || loading} className='send-message-button' title='start chat'>
                            {loading
                                ? <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>
                                : <IoSend className='edit-icon copy-icon' />
                            }
                        </button>
                        <textarea
                            placeholder="start typing to chat or type '/' to select a template"
                            rows={1}
                            required
                            id="prompt-input"
                            value={selectedTemplate?.type === "CHAT_STARTER_PRIVATE" || selectedTemplate?.type === "CHAT_STARTER_OPEN" ? '' : prompt}
                            disabled={loading}
                            onChange={e => handleTextareaChange(e, setPrompt)}
                        />
                        <Template placement='top' textInput={prompt} setPrompt={setPrompt} template_id={templateId} setTemplateId={setTemplateId} setTemplate={setSelectedTemplate} handleTextareaChange={handleTextareaChange} submit={handleSubmit} />
                    </div>
                </form>

                <div className='d-flex justify-content-between align-items-center w-100' style={{ minHeight: "3rem" }}>
                    <div />

                    {selectedTemplate
                        &&
                        <div className='selected-template' title={selectedTemplate?.description}>
                            <div className='d-flex align-items-center justify-content-between gap-3'>
                                <span className='font-xxs'>Selected Template</span>
                                <button onClick={() => { setSelectedTemplate(null); setPrompt("") }} className='bg-transparent border-0' disabled={loading}>
                                    <GrClose className='remove-icon' />
                                </button>
                            </div>
                            <div>
                                <p className='m-0 text-secondary font-xxs'>
                                    {selectedTemplate.name.substring(0, 20)}{selectedTemplate.name.length > 20 && "..."}
                                </p>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <AddOpenaiAPIkeyDialog
                open={showOpenaiApiKeyModal}
                onOpenChange={() => setShowOpenaiApiKeyModal(false)}
                message='Add your OpenAI API key to continue chat or use SageCollab tokens'
                handleSubmit={handleSubmit}
            />

            <AddGeminiAPIkeyDialog
                open={showGeminiApiKeyModal}
                onOpenChange={setShowGeminiApiKeyModal}
                message='Add your Gemini API key to continue chat'
                handleSubmit={handleSubmit}
            />

            <AddBedrockAPIkeyDialog
                open={showBedrockApiKeyModal}
                onOpenChange={() => setShowBedrockApiKeyModal(false)}
                message='Add your Bedrock API key to continue chat'
                handleSubmit={handleSubmit}
            />

            <AddOpenaiCompatibleAPIkeyDialog
                open={showOpenaiCompatibleApiKeyModal}
                onOpenChange={setShowOpenaiCompatibleApiKeyModal}
                handleSubmit={handleSubmit}
            />

            <MaxAiInteractionsReachedDialog
                show={showMaxInteractionsReachedModal}
                onHide={() => setShowMaxInteractionsReachedModal(false)}
            />

            <AdDialog
                show={showAdModal}
                onHide={() => setShowAdModal(false)}
            />
        </>
    )
}

export default NewChat