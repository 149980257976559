import { useState } from 'react';
import { Dialog } from '../../ui/Dialog';
import { useNavigate } from 'react-router-dom';
import { renderSelectedTemplatePreview, setTemplateStructure } from '../utils';

const ViewTemplateDialog = ({
    selectedTemplate,
    selectedTemplateStructure,
    setSelectedTemplateStructure
}) => {
    const [dialogState, setDialogState] = useState(false);
    const navigate = useNavigate();

    return (
        <>
            <Dialog
                open={dialogState}
                onOpenChange={setDialogState}
                hideTrigger={false}
                trigger={
                    <div
                        onClick={async () => {
                            await setTemplateStructure(selectedTemplate.template_id, setSelectedTemplateStructure, () => {
                                setDialogState(prev => !prev);
                            })
                        }}
                    >
                        Preview
                    </div>
                }
                title="Preview"
                body={
                    <div className='h-100 overflow-scroll'>
                        <h5>{selectedTemplate?.name}</h5>
                        <p>{selectedTemplate?.description}</p>
                        <div className=''>
                            {renderSelectedTemplatePreview(selectedTemplateStructure, setSelectedTemplateStructure, true)}
                        </div>
                    </div>
                }
                footer={
                    <div className='d-flex justify-content-end'>
                        <button className='button' onClick={() => navigate(`/start-chat?templateId=${selectedTemplate?.template_id}`)}>Use in chat</button>
                    </div>
                }
            />
        </>
    )
}

export default ViewTemplateDialog;