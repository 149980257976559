import logo from '../../../assets/logo.svg';

const AppToastLayout = ({ icon, hideIcon = false, title, message }) => {
    return (
        <span className='d-inline-flex gap-2 align-items-start overflow-hidden'>
            <div className='pt-1'>
                {!hideIcon
                    && icon
                    ? icon
                    : <img src={logo} alt="logo" className='w-m' />
                }
            </div>
            <div>
                <h4 className='font-s'>{title ? title : "Heading"}</h4>
                <span>{message ? message : "This is a placeholder message"}</span>
            </div>
        </span>
    )
}

export default AppToastLayout;
