
import React, { useContext, useEffect, useState } from 'react'
import { MdSettings } from 'react-icons/md'
import ProtectedAxios from '../../../api/protectedAxios';
import { toast } from 'react-hot-toast';
import { UserContext } from '../../../context/UserProvider';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { FiExternalLink } from 'react-icons/fi';
import axios from 'axios';
import { RiCheckLine, RiCloseLine } from 'react-icons/ri';
import { CiWarning } from 'react-icons/ci';
import LoadingSkeleton from "../../LoadingSkeleton"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Fade } from 'react-bootstrap'
import BedrockApiKeysSetting from './settingTabs/BedrockApiKeysSetting';
import OpenaiCompatibleApiKeysSetting from './settingTabs/OpenaiCompatibleApiKeysSetting';
import OpenaiCompatibleIcon from '../../custom-svgs/OpenaiCompatibleIcon';
import { Dialog } from "../../ui/Dialog"

const Settings = ({ viewAsLisk = false, viewModal = false, text, title, className }) => {
    const [user, setUser] = useContext(UserContext)

    const [activeTab, setActiveTab] = useState("openai")

    const [show, setShow] = useState(viewModal);
    const handleClose = () => {
        setShow(false)
        if (!isOpenaiModelSupported) {
            setDefaultOpenaiModel(openaiModels[0].model_name)
            setShowOpenaiModelMessage(false)
        }
    };
    const handleShow = () => setShow(true);


    // =========  OPENAI API KEY  =========
    const [openaiKey, setOpenaiKey] = useState(user?.openai_api_key)
    const [viewingOpenaiKey, setViewingOpenaiKey] = useState(false)
    const [addingOpenaiKey, setAddingOpenaiKey] = useState(false)
    const [openaiKeyAdded, setOpenaiKeyAdded] = useState(false)
    const [isOpenaiKeyValid, setIsOpenaiKeyValid] = useState(false)
    const [validatingOpenaiKey, setValidatingOpenaiKey] = useState(true)

    useEffect(() => {
        if (show && activeTab === "openai") {
            setOpenaiKey(user?.openai_api_key)
        }
    }, [user, show, activeTab])

    useEffect(() => {
        if (openaiKeyAdded) {
            setTimeout(() => {
                setOpenaiKeyAdded(false)
            }, 2000)
        }
    }, [openaiKeyAdded])

    const addOpenaiKey = e => {
        e?.preventDefault()
        setAddingOpenaiKey(true)
        ProtectedAxios.post('/users/updateProfile', { user_id: user.user_id, editingField: 'openai_api_key', newValue: openaiKey })
            .then(res => {
                setUser({ ...user, openai_api_key: res.data.newValue })
                setAddingOpenaiKey(false)
                setOpenaiKeyAdded(true)
            })
            .catch(err => {
                console.log(err);
                toast.error(err.response.data.error)
                setAddingOpenaiKey(false)
            })
    }

    useEffect(() => {
        if (show && activeTab === "openai") {
            setTimeout(() => {
                checkOpenaiApiKey()
            }, 500)
        }
    }, [openaiKey, show, activeTab])

    const checkOpenaiApiKey = async () => {
        const apiUrl = 'https://api.openai.com/v1/engines';
        if (openaiKey.length > 0) {
            try {
                setValidatingOpenaiKey(true)
                const response = await axios.get(apiUrl, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${openaiKey}`
                    }
                });
                if (response.status === 200) {
                    setIsOpenaiKeyValid(true)
                    setValidatingOpenaiKey(false)
                    if (openaiKey !== user?.openai_api_key) {
                        addOpenaiKey()
                    }
                }
            } catch (error) {
                setIsOpenaiKeyValid(false)
                setValidatingOpenaiKey(false)
            }
        }
    };



    // =========  DEFAULT OPENAI MODEL  =========
    const [openaiModels, setOpenaiModels] = useState()
    const [defaultOpenaiModel, setDefaultOpenaiModel] = useState(null)
    const [loadingOpenaiModels, setLoadingOpenaiModels] = useState(true)
    const [validatingOpenaiModel, setValidatingOpenaiModel] = useState(false)
    const [updatingDefaultOpenaiModel, setUpdatingDefaultOpenaiModel] = useState(false)
    const [defaultOpenaiModelUpdated, setDefaultOpenaiModelUpdated] = useState(false)
    const [isOpenaiModelSupported, setIsOpenaiModelSupported] = useState(false)
    const [showOpenaiModelMessage, setShowOpenaiModelMessage] = useState(false)

    //fetch user preferences and set default model
    useEffect(() => {
        if (show && activeTab === "openai") {
            fetchOpenaiModelPreferences()
        }
    }, [show, activeTab])
    const fetchOpenaiModelPreferences = () => {
        setLoadingOpenaiModels(true)
        ProtectedAxios.post('/users/getOpenaiModelPreferences', { user_id: user.user_id })
            .then(res => {
                if (res.data) {
                    if (res.data.key === "default-openai-model") {
                        setDefaultOpenaiModel(res.data.value)
                    }
                }
                fetchOpenaiModels(res.data.value)
            })
            .catch(err => {
                console.log(err);
                // toast.error(err.response.data.error)
                fetchOpenaiModels()
            })
    }

    //fetch all models
    const fetchOpenaiModels = (_defaultModel) => {
        setLoadingOpenaiModels(true)
        ProtectedAxios.post('/users/getOpenaiModels', { user_id: user.user_id })
            .then(res => {
                if (res.data) {
                    setOpenaiModels(res.data)
                    if (_defaultModel === undefined) {
                        setDefaultOpenaiModel(res.data[0].model_name)
                    }
                    setLoadingOpenaiModels(false)
                }

            })
            .catch(err => {
                console.log(err);
                toast.error(err.response.data.error)
                setAddingOpenaiKey(false)
            })
    }

    useEffect(() => {
        if (defaultOpenaiModelUpdated) {
            setTimeout(() => {
                setDefaultOpenaiModelUpdated(false)
            }, 3000)
        }
    }, [defaultOpenaiModelUpdated])

    //set deafult model
    const updateDefaultOpenaiModel = async (e) => {
        setShowOpenaiModelMessage(true)
        e.preventDefault()
        let selectedModel = e.target.value

        setDefaultOpenaiModel(e.target.value)

        setValidatingOpenaiModel(true)
        let modelValidWithApiKey = await validateSelectedOpenaiModelWithUsersApiKey(user.openai_api_key, selectedModel)
        setValidatingOpenaiModel(false)

        if (modelValidWithApiKey) {
            setIsOpenaiModelSupported(true)
            //update the user's default model in preferences
            setUpdatingDefaultOpenaiModel(true)
            ProtectedAxios.post('/users/setDefaultOpenaiModel', { user_id: user.user_id, selectedModel })
                .then(res => {
                    if (res.data) {
                        setUpdatingDefaultOpenaiModel(false)
                        setDefaultOpenaiModelUpdated(true)
                        setDefaultOpenaiModel(e.target.value)
                        setUser({ ...user, default_openai_model: e.target.value })
                        setTimeout(() => {
                            setShowOpenaiModelMessage(false)
                        }, 2000)
                    }
                })
                .catch(err => {
                    console.log(err);
                    setUpdatingDefaultOpenaiModel(false)
                    setDefaultOpenaiModelUpdated(false)
                })

        } else {
            setIsOpenaiModelSupported(false)
        }
    }

    //fucntion not confirmed yet, if the selected model is supported by user's api key then return true else false
    const validateSelectedOpenaiModelWithUsersApiKey = async (openai_api_key, selected_openai_model) => {
        return await new Promise((resolve, reject) => {
            ProtectedAxios.post('/users/validateOpenaiModelWithSelectedApiKey', { openai_api_key, selected_openai_model })
                .then(res => {
                    if (res.data) {
                        resolve(true)
                    }
                })
                .catch(err => {
                    resolve(false)
                })
        })
    }



    // =========  GEMINI API KEY  =========
    const [geminiKey, setGeminiKey] = useState(user?.gemini_api_key)
    const [viewingGeminiKey, setViewingGeminiKey] = useState(false)
    const [addingGeminiKey, setAddingGeminiKey] = useState(false)
    const [geminiKeyAdded, setGeminiKeyAdded] = useState(false)
    const [isGeminiKeyValid, setIsGeminiKeyValid] = useState(false)
    const [validatingGeminiKey, setValidatingGeminiKey] = useState(true)

    useEffect(() => {
        if (show && activeTab === "gemini") {
            setGeminiKey(user?.gemini_api_key)
        }
    }, [user, show, activeTab])

    useEffect(() => {
        if (geminiKeyAdded) {
            setTimeout(() => {
                setGeminiKeyAdded(false)
            }, 2000)
        }
    }, [geminiKeyAdded])

    const addGeminiKey = e => {
        e?.preventDefault()
        setAddingGeminiKey(true)
        ProtectedAxios.post('/users/updateProfile', { user_id: user.user_id, editingField: 'gemini_api_key', newValue: geminiKey })
            .then(res => {
                setUser({ ...user, gemini_api_key: res.data.newValue })
                setAddingGeminiKey(false)
                setGeminiKeyAdded(true)
            })
            .catch(err => {
                console.log(err);
                toast.error(err.response.data.error)
                setAddingGeminiKey(false)
            })
    }

    useEffect(() => {
        if (show && activeTab === "gemini") {
            setTimeout(() => {
                checkGeminiApiKey()
            }, 500)
        }
    }, [geminiKey, show, activeTab])

    const checkGeminiApiKey = async () => {
        const API_VERSION = 'v1';
        const apiUrl = `https://generativelanguage.googleapis.com/${API_VERSION}/models?key=${geminiKey}`;
        if (geminiKey.length > 0) {
            try {
                setValidatingGeminiKey(true)
                const response = await axios.get(apiUrl, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (response.status === 200) {
                    setIsGeminiKeyValid(true)
                    setValidatingGeminiKey(false)
                    if (geminiKey !== user?.gemini_api_key) {
                        addGeminiKey()
                    }
                }
            } catch (error) {
                setIsGeminiKeyValid(false)
                setValidatingGeminiKey(false)
            }
        }
    };



    // =========  DEFAULT GEMINI MODEL  =========
    const [geminiModels, setGeminiModels] = useState()
    const [defaultGeminiModel, setDefaultGeminiModel] = useState(null)
    const [loadingGeminiModels, setLoadingGeminiModels] = useState(true)
    const [validatingGeminiModel, setValidatingGeminiModel] = useState(false)
    const [updatingDefaultGeminiModel, setUpdatingDefaultGeminiModel] = useState(false)
    const [defaultGeminiModelUpdated, setDefaultGeminiModelUpdated] = useState(false)
    const [isGeminiModelSupported, setIsGeminiModelSupported] = useState(false)
    const [showGeminiModelMessage, setShowGeminiModelMessage] = useState(false)

    //fetch user preferences and set default model
    useEffect(() => {
        if (show && activeTab === "gemini") {
            fetchGeminiModelPreferences()
        }
        setShowGeminiModelMessage(false)
    }, [show, activeTab])
    const fetchGeminiModelPreferences = () => {
        setLoadingGeminiModels(true)
        ProtectedAxios.post('/users/getGeminiModelPreferences', { user_id: user.user_id })
            .then(res => {
                if (res.data) {
                    if (res.data.key === "default-gemini-model") {
                        setDefaultGeminiModel(res.data.value)
                    }
                }
                fetchGeminiModels(res.data.value)
            })
            .catch(err => {
                console.log(err);
                fetchGeminiModels()
            })
    }

    //fetch all gemini models
    const fetchGeminiModels = (_defaultModel) => {
        setLoadingGeminiModels(true)
        ProtectedAxios.post('/users/getGeminiModels', { user_id: user.user_id })
            .then(res => {
                if (res.data) {
                    setGeminiModels(res.data)
                    if (_defaultModel === undefined) {
                        setDefaultGeminiModel(res.data[0].model_name)
                    }
                    setLoadingGeminiModels(false)
                }

            })
            .catch(err => {
                console.log(err);
                toast.error(err.response.data.error)
                setAddingGeminiKey(false)
            })
    }

    useEffect(() => {
        if (defaultGeminiModelUpdated) {
            setTimeout(() => {
                setDefaultGeminiModelUpdated(false)
            }, 3000)
        }
    }, [defaultGeminiModelUpdated])

    //set deafult model
    const updateDefaultGeminiModel = async (e) => {
        setShowGeminiModelMessage(true)
        e.preventDefault()
        let selectedModel = e.target.value

        setDefaultGeminiModel(e.target.value)

        setValidatingGeminiModel(true)
        let modelValidWithApiKey = await validateSelectedGeminiModelWithUsersApiKey(user.gemini_api_key, selectedModel)
        setValidatingGeminiModel(false)

        if (modelValidWithApiKey) {
            setIsGeminiModelSupported(true)
            //update the user's default model in preferences
            setUpdatingDefaultGeminiModel(true)
            ProtectedAxios.post('/users/setDefaultGeminiModel', { user_id: user.user_id, selectedModel })
                .then(res => {
                    if (res.data) {
                        setUpdatingDefaultGeminiModel(false)
                        setDefaultGeminiModelUpdated(true)
                        setDefaultGeminiModel(e.target.value)
                        setUser({ ...user, default_gemini_model: e.target.value })
                        setTimeout(() => {
                            setShowGeminiModelMessage(false)
                        }, 2000)
                    }
                })
                .catch(err => {
                    console.log(err);
                    setUpdatingDefaultGeminiModel(false)
                    setDefaultGeminiModelUpdated(false)
                })

        } else {
            setIsGeminiModelSupported(false)
        }
    }

    //fucntion not confirmed yet, if the selected model is supported by user's api key then return true else false
    const validateSelectedGeminiModelWithUsersApiKey = async (gemini_api_key, selected_gemini_model) => {
        return await new Promise((resolve, reject) => {
            ProtectedAxios.post('/users/validateGeminiModelWithSelectedApiKey', { gemini_api_key, selected_gemini_model })
                .then(res => {
                    if (res.data) {
                        resolve(true)
                    }
                })
                .catch(err => {
                    resolve(false)
                })
        })
    }

    const onTabSelect = (selectedTab) => {
        setActiveTab(selectedTab);

        setTimeout(() => {
            const tabEl = document.getElementById(`profile-tabs-tab-${selectedTab}`)?.parentElement;
            if (tabEl) {
                tabEl.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
            }
        }, 100);
    };

    return (
        <>
            {viewAsLisk
                ?
                <div
                    onClick={handleShow}
                    className={`${className}`}
                >
                    {text}
                </div>

                :
                <button className={`edit-btn hover-spin ${className}`} id='share-button' title={title ? title : "settings"} onClick={e => { handleShow() }}><MdSettings className='edit-icon dark-icon' /></button>
            }


            <Dialog
                open={show}
                onOpenChange={handleClose}
                title='Settings'
                hideFooter
                hideTrigger
                bodyClass='pb-5'
                body={
                    <Tabs
                        defaultActiveKey={activeTab}
                        onSelect={onTabSelect}
                        id="profile-tabs"
                        className="mb-3 profile-tabs"
                        transition={Fade}
                        justify
                    >


                        <Tab
                            eventKey="openai"
                            id="openai-tab"
                            title={
                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                    <img src="https://www.svgrepo.com/show/306500/openai.svg" style={{ width: '25px' }} />
                                    <p className='m-0'>OpenAI</p>
                                </div>
                            }
                        >
                            <div className='my-3 px-2 pt-4'>
                                <div className='input-grp'>
                                    {/* <label htmlFor='gpt-models'>Default GPT Model</label> */}
                                    <label className='flexed-between mb-1'>
                                        <span>
                                            Default OpenAI Model
                                        </span>
                                        <>
                                            {!user?.openai_api_key
                                                &&
                                                <div className='d-flex  justify-content-center align-items-center gap-1 font-xxs'>
                                                    <CiWarning className='edit-icon reject-icon ' />
                                                    Set API key to change model
                                                </div>
                                            }

                                            {showOpenaiModelMessage
                                                &&
                                                <>
                                                    {validatingOpenaiModel
                                                        ?
                                                        <div className='d-flex  justify-content-center align-items-center font-xxs'>
                                                            <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                                <span className="sr-only"></span>
                                                            </div>
                                                            Validating
                                                        </div>

                                                        :
                                                        <>
                                                            {isOpenaiModelSupported
                                                                ?
                                                                <>
                                                                    {updatingDefaultOpenaiModel
                                                                        ?
                                                                        <div className='d-flex  justify-content-center align-items-center font-xxs'>
                                                                            <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                                                <span className="sr-only"></span>
                                                                            </div>
                                                                            Updating
                                                                        </div>
                                                                        :
                                                                        <>
                                                                            {defaultOpenaiModelUpdated
                                                                                ?
                                                                                <div className='d-flex  justify-content-center align-items-center gap-1 font-xxs'>
                                                                                    <RiCheckLine className='edit-icon accept-icon ' />
                                                                                    Updated
                                                                                </div>
                                                                                :
                                                                                <div className='d-flex  justify-content-center align-items-center gap-1 font-xxs'>
                                                                                    <RiCloseLine className='edit-icon reject-icon ' />
                                                                                    Failed to update
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    }
                                                                </>

                                                                :
                                                                <div className='d-flex  justify-content-center align-items-center gap-1 font-xxs'>
                                                                    <RiCloseLine className='edit-icon reject-icon ' />
                                                                    Model not supported
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            }
                                        </>
                                    </label>
                                    {loadingOpenaiModels
                                        ?
                                        <LoadingSkeleton type='select' />
                                        :
                                        <>
                                            <select
                                                id='gpt-models'
                                                className="form-select"
                                                value={defaultOpenaiModel}
                                                onChange={e => updateDefaultOpenaiModel(e)}
                                                disabled={!user?.openai_api_key}
                                            >
                                                {openaiModels.map((model, i) => {
                                                    return (
                                                        <option key={i} value={model.model_name}>{model.model_name}</option>
                                                    )
                                                })}
                                            </select>
                                        </>
                                    }
                                    <p className='text-left my-1 mx-1 font-xxs'>If you're unfamiliar with GPT models and want to learn more, check <a target="_blank" href="https://sagecollab.com/understanding-openai-models/">this guide <FiExternalLink /></a></p>
                                </div>

                                <br /><br />

                                {user.role_id != 5
                                    &&
                                    <>
                                        <form onSubmit={addOpenaiKey}>
                                            <div className='input-grp'>
                                                <label className='flexed-between mb-1'>
                                                    <span>
                                                        OpenAI API Key
                                                    </span>
                                                    {openaiKey?.length
                                                        ?
                                                        <>
                                                            {validatingOpenaiKey
                                                                ?
                                                                <div className='d-flex  justify-content-center align-items-center font-xxs'>
                                                                    <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                                        <span className="sr-only"></span>
                                                                    </div>
                                                                    Validating
                                                                </div>

                                                                :
                                                                <>
                                                                    {openaiKey.length > 0
                                                                        &&
                                                                        <>
                                                                            {isOpenaiKeyValid
                                                                                ?
                                                                                <>
                                                                                    {addingOpenaiKey
                                                                                        ?
                                                                                        <div className='d-flex  justify-content-center align-items-center font-xxs'>
                                                                                            <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                                                                <span className="sr-only"></span>
                                                                                            </div>
                                                                                            Updating
                                                                                        </div>
                                                                                        :
                                                                                        <>
                                                                                            {openaiKeyAdded
                                                                                                ?
                                                                                                <div className='d-flex  justify-content-center align-items-center gap-1 font-xxs'>
                                                                                                    <RiCheckLine className='edit-icon accept-icon ' />
                                                                                                    Key Updated
                                                                                                </div>
                                                                                                :
                                                                                                <div className='d-flex  justify-content-center align-items-center gap-1 font-xxs'>
                                                                                                    <RiCheckLine className='edit-icon accept-icon ' />
                                                                                                    Valid Key
                                                                                                </div>
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                </>

                                                                                :
                                                                                <div className='d-flex  justify-content-center align-items-center gap-1 font-xxs'>
                                                                                    <RiCloseLine className='edit-icon reject-icon ' />
                                                                                    Invalid Key
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </>

                                                        : <div />
                                                    }
                                                </label>
                                                <div className='input-with-icon'>
                                                    <input required type={viewingOpenaiKey ? 'text' : 'password'} id='key' value={openaiKey} onChange={e => setOpenaiKey(e.target.value)} placeholder={openaiKey ? openaiKey : 'paste your OpenAI API key here'} className='' />
                                                    {openaiKey
                                                        &&
                                                        <>
                                                            {viewingOpenaiKey
                                                                ?
                                                                <button type="button" className='button-icon edit-btn' title='hide key' onClick={() => { setViewingOpenaiKey(false) }}><AiFillEyeInvisible className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>

                                                                :
                                                                <button type="button" className='button-icon edit-btn' title='view key' onClick={() => { setViewingOpenaiKey(true) }}><AiFillEye className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                                <p className='text-left my-1 mx-1 font-xxs'>Unsure where to find your OpenAI API key? Check <a target="_blank" href="https://sagecollab.com/how-to-find-your-openai-api-key">this guide <FiExternalLink /></a></p>
                                            </div>
                                        </form>
                                    </>
                                }


                            </div>
                        </Tab>


                        <Tab
                            eventKey="gemini"
                            id="gemini-tab"
                            title={
                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                    <img src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/google-gemini-icon.png" style={{ width: '25px' }} />
                                    <p className='m-0'>Gemini</p>
                                </div>
                            }
                        >
                            <div className='my-3 px-2 pt-4'>
                                <div className='input-grp'>
                                    {/* <label htmlFor='gpt-models'>Default GPT Model</label> */}
                                    <label className='flexed-between mb-1'>
                                        <span>
                                            Default Gemini Model
                                        </span>
                                        <>
                                            {!user?.gemini_api_key
                                                &&
                                                <div className='d-flex  justify-content-center align-items-center gap-1 font-xxs'>
                                                    <CiWarning className='edit-icon reject-icon ' />
                                                    Set API key to change model
                                                </div>
                                            }

                                            {showGeminiModelMessage
                                                &&
                                                <>
                                                    {validatingGeminiModel
                                                        ?
                                                        <div className='d-flex  justify-content-center align-items-center font-xxs'>
                                                            <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                                <span className="sr-only"></span>
                                                            </div>
                                                            Validating
                                                        </div>

                                                        :
                                                        <>
                                                            {isGeminiModelSupported
                                                                ?
                                                                <>
                                                                    {updatingDefaultGeminiModel
                                                                        ?
                                                                        <div className='d-flex  justify-content-center align-items-center font-xxs'>
                                                                            <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                                                <span className="sr-only"></span>
                                                                            </div>
                                                                            Updating
                                                                        </div>
                                                                        :
                                                                        <>
                                                                            {defaultGeminiModelUpdated
                                                                                ?
                                                                                <div className='d-flex  justify-content-center align-items-center gap-1 font-xxs'>
                                                                                    <RiCheckLine className='edit-icon accept-icon ' />
                                                                                    Updated
                                                                                </div>
                                                                                :
                                                                                <div className='d-flex  justify-content-center align-items-center gap-1 font-xxs'>
                                                                                    <RiCloseLine className='edit-icon reject-icon ' />
                                                                                    Failed to update
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    }
                                                                </>

                                                                :
                                                                <div className='d-flex  justify-content-center align-items-center gap-1 font-xxs'>
                                                                    <RiCloseLine className='edit-icon reject-icon ' />
                                                                    Model not supported
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </>
                                            }
                                        </>
                                    </label>
                                    {loadingGeminiModels
                                        ?
                                        <LoadingSkeleton type='select' />
                                        :
                                        <>
                                            <select
                                                id='gemini-models'
                                                className="form-select"
                                                value={defaultGeminiModel}
                                                onChange={e => updateDefaultGeminiModel(e)}
                                                disabled={!user?.gemini_api_key}
                                            >
                                                {geminiModels.map((model, i) => {
                                                    return (
                                                        <option key={i} value={model.model_name}>{model.model_name}</option>
                                                    )
                                                })}
                                            </select>
                                        </>
                                    }
                                    <p className='text-left my-1 mx-1 font-xxs'>If you're unfamiliar with Gemini models and want to learn more, check <a target="_blank" href="https://sagecollab.com/understanding-gemini-models/">this guide <FiExternalLink /></a></p>
                                </div>

                                <br /><br />

                                {user.role_id != 5
                                    &&
                                    <>
                                        <form onSubmit={addGeminiKey}>
                                            <div className='input-grp'>
                                                <label className='flexed-between mb-1'>
                                                    <span>
                                                        Gemini API Key
                                                    </span>
                                                    {geminiKey?.length
                                                        ?
                                                        <>
                                                            {validatingGeminiKey
                                                                ?
                                                                <div className='d-flex  justify-content-center align-items-center font-xxs'>
                                                                    <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                                        <span className="sr-only"></span>
                                                                    </div>
                                                                    Validating
                                                                </div>

                                                                :
                                                                <>
                                                                    {geminiKey.length > 0
                                                                        &&
                                                                        <>
                                                                            {isGeminiKeyValid
                                                                                ?
                                                                                <>
                                                                                    {addingGeminiKey
                                                                                        ?
                                                                                        <div className='d-flex  justify-content-center align-items-center font-xxs'>
                                                                                            <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                                                                <span className="sr-only"></span>
                                                                                            </div>
                                                                                            Updating
                                                                                        </div>
                                                                                        :
                                                                                        <>
                                                                                            {geminiKeyAdded
                                                                                                ?
                                                                                                <div className='d-flex  justify-content-center align-items-center gap-1 font-xxs'>
                                                                                                    <RiCheckLine className='edit-icon accept-icon ' />
                                                                                                    Key Updated
                                                                                                </div>
                                                                                                :
                                                                                                <div className='d-flex  justify-content-center align-items-center gap-1 font-xxs'>
                                                                                                    <RiCheckLine className='edit-icon accept-icon ' />
                                                                                                    Valid Key
                                                                                                </div>
                                                                                            }
                                                                                        </>
                                                                                    }
                                                                                </>

                                                                                :
                                                                                <div className='d-flex  justify-content-center align-items-center gap-1 font-xxs'>
                                                                                    <RiCloseLine className='edit-icon reject-icon ' />
                                                                                    Invalid Key
                                                                                </div>
                                                                            }
                                                                        </>
                                                                    }
                                                                </>
                                                            }
                                                        </>

                                                        : <div />
                                                    }
                                                </label>
                                                <div className='input-with-icon'>
                                                    <input required type={viewingGeminiKey ? 'text' : 'password'} id='key' value={geminiKey} onChange={e => setGeminiKey(e.target.value)} placeholder={geminiKey ? geminiKey : 'paste your Gemini API key here'} className='' />
                                                    {geminiKey
                                                        &&
                                                        <>
                                                            {viewingGeminiKey
                                                                ?
                                                                <button type="button" className='button-icon edit-btn' title='hide key' onClick={() => { setViewingGeminiKey(false) }}><AiFillEyeInvisible className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>

                                                                :
                                                                <button type="button" className='button-icon edit-btn' title='view key' onClick={() => { setViewingGeminiKey(true) }}><AiFillEye className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                                <p className='text-left my-1 mx-1 font-xxs'>Unsure where to find your Gemini API key? Check <a target="_blank" href="https://sagecollab.com/how-to-find-your-gemini-api-key" rel="noreferrer">this guide <FiExternalLink /></a></p>
                                            </div>
                                        </form>
                                    </>
                                }


                            </div>
                        </Tab>

                        <Tab
                            eventKey="bedrock"
                            id="bedrock-tab"
                            title={
                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                    <img src="https://www.outsystems.com/Forge_CW/_image.aspx/Q8LvY--6WakOw9afDCuuGbQ9u-QKbiqiEaG1FDMiKVo=/aws-bedrock-runtime-2023-01-04%2000-00-00-2024-09-12%2014-12-44" alt="" className='rounded-circle' style={{ width: '25px' }} />
                                    <p className='m-0'>AWS Bedrock</p>
                                </div>
                            }
                        >
                            <BedrockApiKeysSetting
                                open={show}
                                activeTab={activeTab}
                            />
                        </Tab>

                        <Tab
                            eventKey="openai-compatible"
                            id="openai-compatible-tab"
                            title={
                                <div className='d-flex justify-content-center align-items-center gap-2'>
                                    <OpenaiCompatibleIcon />
                                    <p className='m-0'>OpenAI Compatible</p>
                                </div>
                            }
                        >
                            <OpenaiCompatibleApiKeysSetting
                                show={show}
                                activeTab={activeTab}
                            />
                        </Tab>
                    </Tabs>
                }
            />
        </>
    )
}

export default Settings;