import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import ProtectedAxios from '../api/protectedAxios'
import LoadingSkeleton from './LoadingSkeleton'
import { TbNotes } from 'react-icons/tb'
import { UserContext } from '../context/UserProvider'
import { SocketContext } from '../context/SocketProvider'
import placeholderProfile from "../assets/placeholder-profile.jpg"
import { calculateDateDistance, formatNote } from '../utils/helper'
import NoteTextArea from './NoteTextArea'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { MdPostAdd } from 'react-icons/md'
import { EventContext } from '../context/EventProvider'

const ResponseNotes = ({ chat_id, response_id, isEnterpriseAdmin }) => {
    const [user] = useContext(UserContext)
    const [mySocket] = useContext(SocketContext)
    const { eventData, emitEvent } = useContext(EventContext);

    const [notes, setNotes] = useState([])
    const [loading, setLoading] = useState(true)
    const [accessList, setAccessList] = useState([])
    const [loadingAccessList, setLoadingAccessList] = useState(true)

    const [noteText, setNoteText] = useState("")
    const [noteMentions, setNoteMentions] = useState([])
    const [addNote, setAddNote] = useState(false)
    const [addingNotes, setAddingNotes] = useState(false)

    useEffect(() => {
        if (eventData?.eventName === "show_new_note_form" && eventData?.response_id === response_id) {
            setAddNote(true);
        }

        return () => emitEvent(null);
    }, [eventData]);

    useEffect(() => {
        if (mySocket) {
            mySocket.on('response_note_added', (data) => {
                if (data.response_id === response_id && data.created_by !== user.user_id) {
                    fetchNotes(false)
                }
            })
        }
    }, [])

    useEffect(() => {
        fetchNotes()
    }, [])
    const fetchNotes = (_load = true) => {
        if (_load) {
            setLoading(true)
        }
        ProtectedAxios.post("/users/notes/response", { response_id })
            .then(res => {
                setNotes(res.data)
                setLoading(false)
            })
            .catch(err => {
                console.log(err);
                setLoading(false)
            })
    }

    useEffect(() => {
        if (addNote) {
            fetchChatAccessList()
        }
    }, [addNote])
    const fetchChatAccessList = () => {
        setLoadingAccessList(true)
        ProtectedAxios.post("/users/fetchAccessList", { chat_id, include_creator: true })
            .then(res => {
                setAccessList(res.data.filter(_user => _user.user_id !== user.user_id))
                setLoadingAccessList(false)
            })
            .catch(err => {
                setLoadingAccessList(false)
                console.log(err);
            })
    }

    const addNoteForResponse = e => {
        if (noteText) {
            setAddingNotes(true)
            ProtectedAxios.post("/users/addNote/response", { chat_id, response_id, note_content: noteText, user_id: user.user_id })
                .then(res => {
                    let updatedNotes = notes
                    updatedNotes.push(res.data)
                    setNotes(updatedNotes)
                    setAddNote(false)
                    setNoteText("")
                    setNoteMentions([])
                    setAddingNotes(false)
                })
                .catch(err => {
                    console.log(err);
                    setAddingNotes(false)
                })
        }
    }

    const cancleNote = () => {
        setNoteText("");
        setNoteMentions([])
        setAddNote(false);

    }

    return (
        loading
            ?
            <>
                <LoadingSkeleton type="rectangle-half" />
                <LoadingSkeleton type="rectangle-half" />
            </>

            :
            <>
                <div className={`notes ${notes.length > 0 || addNote ? "have-notes" : "no-notes"} ${isEnterpriseAdmin ? 'border-0' : ""}`}>
                    {notes.map((note, i) => {
                        return (
                            <div className='note-container' key={i}>
                                <div className='note-header'>
                                    <h5>
                                        <img className='profile-image w-xxl h-xxl' src={`${note.profile_picture_src ? `${note.profile_picture_src}` : placeholderProfile}`} />
                                        {note.name}
                                    </h5>
                                    <p className='date'>{calculateDateDistance(new Date(note.created_at))}</p>
                                </div>
                                <p className='note-content'>{formatNote(note.note_content, note.mentioned_users)}</p>
                            </div>
                        )
                    })}
                </div>
                <div className='w-100 d-flex justify-content-end overflow-hidden'>
                    {!addNote
                        ?
                        <>
                            {/* {!isEnterpriseAdmin
                                &&
                                <OverlayTrigger
                                    overlay={
                                        <Tooltip>
                                            Add Note
                                        </Tooltip>
                                    }
                                    placement="left"
                                >

                                    <div id='add-note-button'>
                                        <button type='button' className='button-icon' onClick={() => { setAddNote(true) }}><MdPostAdd className='font-m' /></button>
                                    </div>
                                </OverlayTrigger>
                            } */}
                        </>

                        :
                        <div className='note-container note-form'>
                            <div className='note-header'>
                                <h5>Add Note</h5>
                            </div>
                            <NoteTextArea
                                text={noteText}
                                setText={setNoteText}
                                userList={accessList}
                                loadingUserList={loadingAccessList}
                                mentionedUsers={noteMentions}
                                setMentionedUsers={setNoteMentions}
                                disabled={addingNotes}
                            />

                            <div className='btn-row-container'>
                                <a className='text-dark cursor-pointer text-decoration-none' onClick={cancleNote}>cancel</a>
                                <button type='submit' className="button button-main" onClick={() => addNoteForResponse()} disabled={addingNotes || noteText.length === 0}>
                                    {addingNotes
                                        ?
                                        <>
                                            Add
                                            <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </>

                                        : "Add"
                                    }
                                </button>
                            </div>
                        </div>
                    }
                </div>
            </>
    )
}

export default ResponseNotes