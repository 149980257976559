import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../context/UserProvider';
import { RiCheckLine, RiCloseLine } from 'react-icons/ri'
import axios from 'axios';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
import { FiExternalLink } from 'react-icons/fi'
import ProtectedAxios from '../../api/protectedAxios';
import { toast } from 'react-hot-toast'
import { Dialog } from '../ui/Dialog';

const AddGeminiAPIkeyDialog = ({ open, onOpenChange, handleSubmit, title, selectedUser, setSelectedUser, message }) => {
    const [user, setUser] = useContext(UserContext)
    const [addingKey, setAddingKey] = useState(false)
    const [isKeyValid, setIsKeyValid] = useState(false)
    const [validatingKey, setValidatingKey] = useState(true)
    const [key, setKey] = useState('')
    const [viewingKey, setViewingKey] = useState(false)

    useEffect(() => {
        if (selectedUser) {
            setKey(selectedUser.gemini_api_key ? selectedUser.gemini_api_key : "");
        } else {
            setKey(user.gemini_api_key ? user.gemini_api_key : "");
        }
    }, [open, selectedUser, user]);

    const addKey = (e) => {
        e.preventDefault();
        setAddingKey(true);
        ProtectedAxios.post('/users/updateProfile', { user_id: user.user_id, selected_user_id: selectedUser?.user_id ? selectedUser.user_id : null, editingField: 'gemini_api_key', newValue: key })
            .then(res => {
                if (selectedUser && setSelectedUser) {
                    setSelectedUser({ ...selectedUser, gemini_api_key: res.data.newValue })
                } else {
                    setUser({ ...user, gemini_api_key: res.data.newValue })
                }
                setAddingKey(false)
                toast('Gemini API Key is saved', {
                    icon: "🔑"
                })
                onOpenChange();
                if (handleSubmit) {
                    handleSubmit(null, key);
                }
            })
            .catch(err => {
                console.log(err);
                toast.error(err.response.data.error);
                setAddingKey(false);
            })
    }

    useEffect(() => {
        setTimeout(() => {
            checkApiKey();
        }, 500)
    }, [key.length])

    const checkApiKey = async () => {
        const API_VERSION = 'v1';
        const apiUrl = `https://generativelanguage.googleapis.com/${API_VERSION}/models?key=${key}`;
        if (key.length > 0) {
            try {
                setValidatingKey(true)
                const response = await axios.get(apiUrl, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                if (response.status === 200) {
                    setIsKeyValid(true)
                    setValidatingKey(false)
                }
            } catch (error) {
                setIsKeyValid(false)
                setValidatingKey(false)
            }
        }
    };

    return (
        <Dialog
            open={open}
            onOpenChange={onOpenChange}
            title={title ? title : "API key not found"}
            hideFooter
            body={
                <form onSubmit={addKey} className="mb-3">
                    <img src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/google-gemini-icon.png" alt="" style={{ width: '25px', marginBottom: '1rem' }} />
                    <p className='font-s'>{message ? message : "Add your Gemini API key"}</p>
                    <div className={`mx-0 px-0 pt-3`}>
                        <div className='profile-item-header px-0 py-0 mb-2'>
                            <div className='input-grp'>
                                <label>Gemini API Key</label>
                                <div className='input-with-icon'>
                                    <input required type={viewingKey ? 'text' : 'password'} id='key' value={key} onChange={e => { setKey(e.target.value) }} className='' />
                                    {viewingKey
                                        ?
                                        <button type="button" className='button-icon edit-btn' title='hide key' onClick={() => { setViewingKey(false) }}><AiFillEyeInvisible className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>

                                        :
                                        <button type="button" className='button-icon edit-btn' title='view key' onClick={() => { setViewingKey(true) }}><AiFillEye className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>
                                    }
                                </div>
                            </div>
                        </div>
                        <p className='text-left px-3'>Unsure where to find your Gemini API key? Check <a target="_blank" href="https://sagecollab.com/how-to-find-your-gemini-api-key" rel="noreferrer">this guide <FiExternalLink /></a></p>
                    </div>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            {key.length
                                ?
                                <>
                                    {validatingKey
                                        ?
                                        <div className='d-flex font-xs justify-content-center align-items-center'>
                                            <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                            Validating
                                        </div>

                                        :
                                        <>
                                            {key.length > 0
                                                &&
                                                <>
                                                    {isKeyValid
                                                        ?
                                                        <div className='d-flex font-xs justify-content-center align-items-center gap-1'>
                                                            <RiCheckLine className='edit-icon accept-icon ' />
                                                            Valid Key
                                                        </div>

                                                        :
                                                        <div className='d-flex font-xs justify-content-center align-items-center gap-1'>
                                                            <RiCloseLine className='edit-icon reject-icon ' />
                                                            Invalid Key
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </>
                                    }
                                </>

                                : ''
                            }
                        </div>
                        <button type="submit" className="button button-main d-flex" disabled={addingKey || !isKeyValid}>
                            {addingKey
                                ?
                                <>
                                    {handleSubmit ? "Add & Continue" : "Add Key"}
                                    <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </>

                                : handleSubmit ? "Add & Continue" : "Add Key"
                            }
                        </button>
                    </div>
                </form>
            }
        />
    )
}

export default AddGeminiAPIkeyDialog