import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useNavigate, useParams } from 'react-router-dom'
import Axios from '../api/axios'
import toast from 'react-hot-toast'
import { Helmet } from 'react-helmet'
import { UserContext } from '../context/UserProvider'
import { BiUpArrowAlt } from 'react-icons/bi'
import { calculateDateDistance, formatDate } from '../utils/helper'
import ProtectedAxios from '../api/protectedAxios'
import { MdCheck } from 'react-icons/md'
import { AiFillStar, AiOutlineStar } from 'react-icons/ai'
import { IoIosShareAlt } from "react-icons/io";
import CustomModal from '../components/dialogs/CustomModal'
import { FacebookIcon, FacebookShareButton, LinkedinIcon, LinkedinShareButton, TwitterShareButton, XIcon } from "react-share";
import { IoChatboxOutline, IoCheckmark, IoCopy, IoEyeOutline } from 'react-icons/io5'
import logo from '../assets/logo.svg'
import TemplateViewManager from '../components/TemplateViewManager'
import { PiDotOutlineFill } from "react-icons/pi";
import { TfiWorld } from "react-icons/tfi";

const ViewTemplate = () => {
    const [user] = useContext(UserContext)
    const [loading, setLoading] = useState(true)
    const [template, setTemplate] = useState(null)
    const { template_id } = useParams()

    const navigate = useNavigate()
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [installingTemplate, setInstallingTemplate] = useState(false)

    const [shareDialogState, setShareDialogState] = useState(false)

    const [copied, setCopied] = useState(false)
    useEffect(() => {
        if (copied === true) {
            setTimeout(() => {
                setCopied(false)
            }, 1000)
        }
    }, [copied])

    useEffect(() => {
        getTemplateDetails()
    }, [template_id, user])

    const getTemplateDetails = () => {
        if (template_id) {
            setLoading(true)
            Axios.post(`/users/getTemplateDetails/${template_id}`, { user_id: user.user_id })
                .then(res => {
                    setTemplate(res.data)
                    setLoading(false)
                })
                .catch(err => {
                    console.log(err);
                    setLoading(false)
                    toast.error(err.response.data.error)
                })
        }
    }


    const [togglingFavourite, setTogglingFavourite] = useState(false)
    const toggleFavourite = (currentState, template_id) => {
        setTogglingFavourite(true)

        if (currentState === 0) {
            ProtectedAxios.post("/users/addMarketplaceTemplateAsFavourite", { user_id: user.user_id, template_id })
                .then(res => {
                    if (res.data) {
                        setTemplate(prev => { return { ...prev, is_favourite: 1 } })
                        setTogglingFavourite(false)
                    }
                })
                .catch(err => {
                    setTogglingFavourite(false)
                    console.log(err);
                    toast.error(err.response.data.error || err.response.statusText || "Could not add template as favourite at the moment, please try again later")
                })
        } else {
            ProtectedAxios.post("/users/removeMarketplaceTemplateAsFavourite", { user_id: user.user_id, template_id })
                .then(res => {
                    if (res.data) {
                        setTemplate(prev => { return { ...prev, is_favourite: 0 } })
                        setTogglingFavourite(false)
                    }
                })
                .catch(err => {
                    setTogglingFavourite(false)
                    console.log(err);
                    toast.error(err.response.data.error || err.response.statusText || "Could not remove template as favourite at the moment, please try again later")
                })
        }

    }


    const installTemplate = (_template) => {
        if (user?.user_id) {
            setInstallingTemplate(true)
            const startTime = performance.now()
            ProtectedAxios.post("/users/installTemplate", { user_id: user.user_id, selectedTemplate: _template })
                .then(res => {
                    if (res.data) {
                        const endTime = performance.now()
                        let timeTaken = endTime - startTime
                        let waitingTime = 3000 - timeTaken

                        setTimeout(() => {
                            setTemplate(prev => { return { ...prev, installed: 1, download_count: res.data.download_count } })
                            setInstallingTemplate(false)
                        }, waitingTime)
                    }
                })
                .catch(err => {
                    console.log(err);
                    setInstallingTemplate(false)
                    toast.error(err.response.data.error || err.response.statusText || "Could not install templates at the moment, please try again later")
                })

        } else {
            toast('Login/signup to continue')
            navigate(`/login?redirectTo=/marketplace/template/${template.template_id}`)
        }
    }
    return (
        <>
            <Helmet>
                <title>Template - SageCollab</title>
            </Helmet>
            {loading
                ?
                <div className='d-flex justify-content-center align-items-center' style={{ height: '70vh' }}>
                    <div className="spinner-border spinner-border" role="status">
                        <span className="sr-only"></span>
                    </div>
                </div>

                :
                !template || template.length === 0
                    ?
                    <div className='d-flex justify-content-center align-items-center' style={{ height: '70vh' }}>
                        <p className='text-danger'>Template not found</p>
                    </div>

                    :
                    <div className='d-flex flex-column align-items-center gap-3'>
                        <Helmet>
                            <title>{template?.name} - SageCollab</title>
                            <meta name="robots" content="index" />
                            <meta name="description" content={template.description} />
                            <meta name="keywords" content={[...template.selectedCategories, { category: "SageCollab Templates" }, { category: "Prompt Templates" }].map(category => category.category).join(", ")} />
                            <meta name="author" content={(template.created_user_role_id === 1 || template?.company_url) ? template.company_name : template.created_user_name} />
                            <meta property="og:title" content={template.name} />
                            <meta property="og:description" content={template.description} />
                            <meta property="og:image" content="https://sagecollab.com/wp-content/uploads/2023/04/h6xihpoksiy.jpg" />
                            <meta property="og:type" content="article" />
                            <meta property="og:site_name" content="SageCollab" />
                            <meta property="og:updated_time" content={template.updated_at} />
                            <script type="application/ld+json">
                                {JSON.stringify({
                                    "@context": "https://schema.org",
                                    "@type": "Article",
                                    "headline": template.name,
                                    "description": template.description,
                                    "image": "https://sagecollab.com/wp-content/uploads/2023/04/h6xihpoksiy.jpg",
                                    "author": {
                                        "@type": (template.created_user_role_id === 1 || template?.company_url) ? "Organization" : "Person",
                                        "name": (template.created_user_role_id === 1 || template?.company_url) ? template.company_name : template.created_user_name,
                                        "url": (template.created_user_role_id === 1 || template?.company_url) ? template.company_url : `https://app.sagecollab.com/user/${template.created_user_username}`
                                    },
                                    "publisher": {
                                        "@type": (template.created_user_role_id === 1 || template?.company_url) ? "Organization" : "Person",
                                        "name": (template.created_user_role_id === 1 || template?.company_url) ? template.company_name : template.created_user_name,
                                        "logo": {
                                            "@type": "ImageObject",
                                            "url": (template.created_user_role_id === 1 || template?.company_url) ? "https://sagecollab.com/wp-content/uploads/2023/04/logo.74f915af62316f34dbb2d2865eb2532b-40x40.png" : template.created_user_profile_picture_src
                                        }
                                    },
                                    "datePublished": template.created_at,
                                    "dateModified": template.updated_at,
                                    "mainEntityOfPage": {
                                        "@type": "WebPage",
                                        "@id": `https://app.sagecollab.com/marketplace/template/${template.template_id}`
                                    }
                                })}
                            </script>
                        </Helmet>

                        <TemplateViewManager template_id={template_id} setTemplateDetails={setTemplate} />

                        <div className='container py-5'>
                            <div className='d-flex justify-content-between gap-4'>
                                <div>
                                    <h1 className='font-xl fw-medium d-inline'>
                                        {template.name}
                                    </h1>

                                    <div className='d-flex gap-2 align-items-center mt-3'>
                                        {/* <p className='m-0 text-secondary font-us d-flex align-items-center gap-1'><img src={logo} className='w-m h-m' alt="SageCollab" />Prompt Template</p > */}
                                        {/* <TfiWorld /> */}
                                        {/* <PiDotOutlineFill /> */}
                                        {(template.created_user_role_id === 1 || template?.company_url)
                                            ?
                                            <a href={template.company_url} target="_blank">{template.company_name}</a>

                                            :
                                            <div className="d-flex align-items-center gap-1 cursor-pointer">
                                                <img src={template.created_user_profile_picture_src} alt="profile" className="profile-image w-m h-m" />
                                                <NavLink to={`/user/${template.created_user_username}`} className="m-0 text-black">{template.created_user_username}</NavLink>
                                            </div>
                                        }
                                        <PiDotOutlineFill />
                                        <p className='m-0'>{calculateDateDistance(new Date(template.created_at))}</p>
                                        {template.installed === 1
                                            &&
                                            <>
                                                <PiDotOutlineFill />
                                                <span style={{ fontSize: "var(--fs-5)" }} className="d-flex align-items-center gap-1 installed-tag text-secondary"><MdCheck fill="green" />Installed</span>
                                            </>
                                        }
                                    </div>
                                </div>

                                <div className='d-flex gap-1'>
                                    {user?.user_id
                                        &&
                                        <button
                                            disabled={togglingFavourite && selectedTemplate?.template_id === template.template_id}
                                            className={`button-favourite position-relative top-0 end-0 p-0 edit-btn ${togglingFavourite && selectedTemplate?.template_id === template.template_id ? 'toggling' : ''}`}
                                            onClick={() => { toggleFavourite(template.is_favourite, template.template_id); setSelectedTemplate(template) }}
                                        >
                                            {template.is_favourite
                                                ?
                                                <AiFillStar className="favourite-icon" />
                                                :
                                                <AiOutlineStar className="not-favourite-icon" />
                                            }
                                        </button>
                                    }
                                    <button
                                        disabled={togglingFavourite && selectedTemplate?.template_id === template.template_id}
                                        className={`button-favourite position-static p-0 edit-btn`}
                                        onClick={() => { setShareDialogState(true) }}
                                    >
                                        <IoIosShareAlt />
                                    </button>
                                </div>
                            </div>

                            <div className='' style={{ maxWidth: "100%", width: "60rem" }}>
                                <div className='mt-5 pt-4 container'>
                                    <p className='m-0 text-secondary'>Description</p>
                                    <p className='container pt-2'>{template.description}</p>
                                    <div className='d-flex align-items-center gap-5 container py-2'>
                                        <div className="d-inline-flex justify-content-center align-items-center gap-1">
                                            <BiUpArrowAlt />
                                            <span className='fw-medium'>{template?.download_count?.toLocaleString()}</span><span className='text-secondary'>Installs</span>
                                        </div>
                                        {/* <PiDotOutlineFill /> */}
                                        <div className="d-inline-flex justify-content-center align-items-center gap-1">
                                            <IoEyeOutline />
                                            <span className='fw-medium'>{template?.views?.toLocaleString()}</span><span className='text-secondary'>Views</span>
                                        </div>
                                        {/* <PiDotOutlineFill /> */}
                                        <div className="d-inline-flex justify-content-center align-items-center gap-1">
                                            <IoChatboxOutline />
                                            <span className='fw-medium'>{template?.used_count?.toLocaleString()}</span><span className='text-secondary'>Use</span>
                                        </div>
                                    </div>
                                    {template.selectedCategories.length > 0
                                        &&
                                        <div className="template-categories my-4 container">
                                            {template.selectedCategories.map((category, i) => {
                                                return (
                                                    <>
                                                        <div key={i} className={`category`}>
                                                            {category.category}
                                                        </div>
                                                        &nbsp;
                                                    </>
                                                )
                                            })
                                            }
                                        </div>
                                    }
                                </div>

                                <div className="d-flex gap-3 w-100 pt-5">
                                    <div />
                                    <div className="d-flex gap-2 justify-content-end mt-">
                                        <NavLink to={`/start-chat?templateId=${template.template_id}`} className="button button-border bg-transparent">{template.installed ? 'Use' : 'Try'}</NavLink>

                                        <button className={`button ${template.installed === 1 ? "button-borde" : ""}`} onClick={() => { setSelectedTemplate(template); installTemplate(template) }} disabled={selectedTemplate?.template_id === template.template_id && installingTemplate}>
                                            {selectedTemplate?.template_id === template.template_id && installingTemplate
                                                ?
                                                <>
                                                    <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                        <span className="sr-only"></span>
                                                    </div>
                                                </>

                                                :
                                                template.installed === 0
                                                    ? "Install"
                                                    : "Install Again"
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <CustomModal
                            show={shareDialogState}
                            onHide={() => setShareDialogState(false)}
                            title="Share"
                            content={
                                <div className='container py-5'>
                                    <div className='mb-5'>
                                        <p className='m-0 text-secondary'>Share this template with others:</p>
                                        <p className='fw-medium'>{template.name}</p>
                                    </div>
                                    <div className='d-flex gap-3'>
                                        <TwitterShareButton
                                            url={`${window.origin}/marketplace/template/${template.template_id}`}
                                        >
                                            <XIcon size={32} round />
                                        </TwitterShareButton>

                                        <LinkedinShareButton
                                            url={`${window.origin}/marketplace/template/${template.template_id}`}
                                        >
                                            <LinkedinIcon size={32} round />
                                        </LinkedinShareButton>

                                        <FacebookShareButton
                                            url={`${window.origin}/chat/shared/65`}
                                        >
                                            <FacebookIcon size={32} round />
                                        </FacebookShareButton>
                                    </div>

                                    <div className='input-with-icon mt-4' id="chat-link-input">
                                        <input value={`${window.origin}/marketplace/template/${template.template_id}`} />
                                        <button type='button' className='button-icon edit-btn' title={copied ? 'copied' : 'copy'} value={`${window.origin}/marketplace/template/${template.template_id}`} onClick={e => { navigator.clipboard.writeText(e.target.value).then(() => { setCopied(true) }) }}>
                                            {copied
                                                ?
                                                <IoCheckmark className='edit-icon copy-icon text-success' />
                                                :
                                                <IoCopy className='edit-icon copy-icon' />
                                            }
                                        </button>
                                    </div>
                                </div>
                            }
                        />
                    </div>
            }
        </>
    )
}

export default ViewTemplate