import { useState } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const PasswordInput = ({ id=Date.now(), value, onValueChange, className, inputClassName }) => {
    const [viewPassword, setViewPassowrd] = useState(false)
    return (
        <div className={`position-relative ${className}`}>
            <input required type={viewPassword ? "text" : "password"} id={id} className={`pe-5 ${inputClassName}`} value={value} onChange={onValueChange} />
            <button type="button" className="button-icon position-absolute top-50 end-0 translate-middle-y me-1" onClick={() => setViewPassowrd(prev => !prev)}>
                {viewPassword
                    ? <AiFillEyeInvisible />
                    : <AiFillEye />
                }
            </button>
        </div>
    )
};

export default PasswordInput;