import React, { useContext, useEffect, useRef, useState } from 'react'
import ProtectedAxios from '../api/protectedAxios'
import { UserContext } from '../context/UserProvider'
import { toast } from 'react-hot-toast'
import { NavLink, useLocation } from 'react-router-dom'
import { styleOptions, toneOptions } from '../utils/constants';
import { decryptAndGetLocalStorageItem, encryptAndSetLocalStorageItem } from '../utils/helper';
import { Dialog } from './ui/Dialog';
import { MdLock } from 'react-icons/md';
import PasswordInput from './ui/PasswordInput';

const Template = ({ textInput, setPrompt, placement, template_id, setTemplateId, setTemplate, handleTextareaChange, showNormalOnly = false, submit = null }) => {
    const [user] = useContext(UserContext)
    const [showingTemplateDropdown, setShowingTemplateDropdown] = useState(false)
    const [loadingTemplates, setLoadingTemplates] = useState(true)
    const [templates, setTemplates] = useState([])
    const [addCreatorAsCollaborator, setAddCreatorAsCollaborator] = useState(false);

    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [selectedTemplateStructure, setSelectedTemplateStructure] = useState([])

    const [selectedStyle, setSelectedStyle] = useState('0')
    const [selectedTone, setSelectedTone] = useState('0')

    const [password, setPassword] = useState("");
    const [isPasswordIncorrect, setIsPasswordIncorrect] = useState(false);

    const [show, setShow] = useState(false)
    const handleClose = async () => {
        setShow(false);
        if (setTemplateId) {
            setTemplateId(null)
        }
        if (setSelectedTemplate) {
            setSelectedTemplate(null)
        }
    }
    const handleShow = () => setShow(true)

    const [constructingPrompt, setConstructingPrompt] = useState(false)
    const location = useLocation()

    const templateDropdown = useRef(null)
    useClickOutside(templateDropdown)

    function useClickOutside(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowingTemplateDropdown(false)
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const urlTemplateId = template_id || searchParams.get('templateId');
        if (urlTemplateId) {
            toast.loading('Loading template details', { id: 'template-from-url', duration: Infinity })
            ProtectedAxios.post('/users/fetchUserTemplateDetailsWithTemplateId', { user_id: user.user_id, template_id: urlTemplateId })
                .then(res => {
                    if (res.data) {
                        let templateData = res.data
                        let data = { template_id: templateData.template_id, name: templateData.name, description: templateData.description, showing_additional_options: templateData.showing_additional_options, type: templateData.type, notify_creator_on_use: templateData.notify_creator_on_use, user_id: templateData.user_id, is_password_protected: templateData.is_password_protected }
                        setSelectedTemplate(data);

                        // check and use saved values for template structure if available
                        const savedTemplateStructure = JSON.parse(decryptAndGetLocalStorageItem(`savedTemplateStructure-${templateData.template_id}`) || "[]");
                        const templateStructure = JSON.parse(templateData.prompt);
                        templateStructure.forEach((element, i) => {
                            element.value = savedTemplateStructure[i]?.value || element.value;
                        });
                        setSelectedTemplateStructure(templateStructure)

                        setShow(true)
                        toast.dismiss('template-from-url')
                    }
                })
                .catch(err => {
                    console.log(err);
                    setTemplate(null)

                    if (err.response.status === 401) {
                        toast.error(err.response.data.error, { id: 'template-from-url', duration: 4000 })
                        return
                    }
                    toast.error("Can't use this template at the moment, please try again later.", { id: 'template-from-url', duration: 4000 })
                })
        }
    }, [location, template_id])


    useEffect(() => {
        if (textInput.split(" ", 2).length === 1 && textInput.split(" ", 2)[0].substring(0, 1) === "/") {
            setShowingTemplateDropdown(true)
        }
        else {
            setShowingTemplateDropdown(false)
        }
    }, [textInput])
    useEffect(() => {
        window.addEventListener("keydown", e => {
            if (e.key === "Escape") {
                setShowingTemplateDropdown(false)
            }
        })
    }, [])

    useEffect(() => {
        if (textInput.split(" ", 2).length === 1 && textInput.split(" ", 2)[0].substring(0, 1) === "/") {
            fetchTemplates()
        }
    }, [textInput])
    const fetchTemplates = async () => {
        setLoadingTemplates(true)
        ProtectedAxios.post('/users/fetchTemplates', { user_id: user.user_id, templateSearchText: textInput.substring(1, textInput.length), showSharedTemplates: true, showNormalOnly })
            .then(res => {
                if (res.data) {
                    setTemplates(res.data)
                    setLoadingTemplates(false)
                }
            })
            .catch(err => {
                console.log(err);
                toast.error("could not fetch your templates at the moment, please try again later.")
                setLoadingTemplates(false)
            })
    }


    const constructPrompt = async (e) => {
        e.preventDefault()
        setConstructingPrompt(true);

        if (selectedTemplate?.is_password_protected) {
            if (!password) {
                setIsPasswordIncorrect(true);
                setConstructingPrompt(false);
                return;
            }

            let verified = false;
            const response = await ProtectedAxios.post("/users/verifyProtectedTempletePassword", { template_id: selectedTemplate?.template_id, password: password })
            if (response.data.is_password_correct !== undefined) {
                verified = response.data.is_password_correct;
            } else {
                verified = false;
            }
            if (!verified) {
                setIsPasswordIncorrect(true);
                setConstructingPrompt(false);
                return;
            }
        }

        let constructedPrompt = selectedTemplateStructure.map(element => {
            if (element.type === "text") {
                return element.content;
            }
            else if (element.type === "hidden-text") {
                return element.content;
            }
            else if (element.type === "textarea") {
                return element.value;
            }
            else if (element.type === "select") {
                return element.value;
            }
        }).join(" ");

        //add style/tone if available
        if (selectedStyle !== '0' && selectedTone === '0') {
            constructedPrompt = (`Be ${selectedStyle}.\n\n`) + constructedPrompt;
        }
        else if (selectedStyle === '0' && selectedTone !== '0') {
            constructedPrompt = (`Your tone of response should be ${selectedTone}.\n\n`) + constructedPrompt;
        }
        else if (selectedStyle !== '0' && selectedTone !== '0') {
            constructedPrompt = (`Be ${selectedStyle}. Your tone of response should be ${selectedTone}.\n\n`) + constructedPrompt;
        }


        setSelectedStyle('0');
        setSelectedTone('0');

        setPrompt('');

        if (selectedTemplate.type !== "CHAT_STARTER_PRIVATE") {
            setPrompt(constructedPrompt);
        }

        setTimeout(() => {
            handleTextareaChange();
        }, 100)

        if (setTemplate) {
            setTemplate(selectedTemplate);
        }

        if (submit) {
            submit(null, null, constructedPrompt, selectedTemplate.template_id, addCreatorAsCollaborator);
        }

        handleClose();
        setConstructingPrompt(false);
    }

    const onTemplateElementChange = (e, i) => {
        setSelectedTemplateStructure(prev => {
            let updatedData = [...prev]
            updatedData[i].value = e.target.value

            // update saved structure in local storage
            encryptAndSetLocalStorageItem(`savedTemplateStructure-${selectedTemplate.template_id}`, JSON.stringify(updatedData));

            return updatedData
        })
    }

    return (
        <>
            {showingTemplateDropdown
                &&
                <div ref={templateDropdown} className={`list-dropdown ${placement === 'top' ? 'top-placed' : ''}`}>
                    {loadingTemplates
                        ?
                        <div className='py-3 opacity-50'>
                            <div className="d-flex m-auto spinner-border spinner-border" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>

                        :
                        <div className='list-container'>
                            {templates.length === 0
                                ?
                                <>
                                    <p className='pt-3 px-3'>
                                        No templates found. <NavLink to='/?activeTab=4&activeTemplateTab=1&createTemplate=true'>click here to add templates</NavLink>
                                    </p>
                                </>

                                :
                                <>
                                    {templates.map((template, key) => {
                                        return (
                                            <div className='list-item d-flex flex-wrap gap-3' key={key} onClick={() => { setSelectedTemplate(template); setSelectedTemplateStructure(JSON.parse(template.prompt)); handleShow() }}>
                                                {template.name}
                                                {template.is_shared
                                                    ?
                                                    <span className='shared-tag'>shared with you</span>

                                                    : ''
                                                }
                                            </div>
                                        )
                                    })}
                                </>
                            }
                        </div>
                    }
                </div>
            }

            <Dialog
                open={show}
                onOpenChange={handleClose}
                size="lg"
                title={selectedTemplate?.name}
                hideTrigger
                body={
                    <div className='container'>
                        <div className='mb-5'>
                            <div>
                                <span className='text-secondary mt-3'>Description</span>
                                <p className='pt-2 pb-4'>{selectedTemplate?.description}</p>
                                <div className='form-container gap-3'>
                                    {selectedTemplateStructure.map((element, i) => {
                                        return (
                                            <div className={``} key={i} id={`element-item-${element.id}`}>

                                                {element.type === "textarea"
                                                    &&
                                                    <>
                                                        <label for={`input-${element.id}`} className='label font-xxs'>{selectedTemplateStructure[i].label}</label>
                                                        <input
                                                            id={`input-${element.id}`}
                                                            value={selectedTemplateStructure[i]?.value}
                                                            onChange={e => onTemplateElementChange(e, i)}
                                                        />
                                                    </>
                                                }

                                                {element.type === "select"
                                                    &&
                                                    <>
                                                        <label for={`input-${element.id}`} className='label font-xxs'>{element.label}</label>
                                                        <select
                                                            className='form-select'
                                                            id={`input-${element.id}`}
                                                            value={selectedTemplateStructure[i]?.value}
                                                            onChange={e => onTemplateElementChange(e, i)}
                                                            defaultValue={1}
                                                        >
                                                            {element.options.map((option, option_key) => {
                                                                return (
                                                                    <option
                                                                        key={option_key}
                                                                        value={option.value}
                                                                    >
                                                                        {option.key}
                                                                    </option>
                                                                )
                                                            })}
                                                        </select>
                                                    </>
                                                }

                                            </div>
                                        )
                                    })}
                                    {selectedTemplate?.showing_additional_options === 1
                                        &&
                                        <>
                                            <div className='d-flex gap-4 flex-column flex-md-row'>
                                                <div className='input-grp'>
                                                    <label htmlFor='style-select'>Style</label>
                                                    <select id='style-select' className="form-select" value={selectedStyle} onChange={e => setSelectedStyle(e.target.value)}>
                                                        <option value={0}>Default</option>
                                                        {styleOptions.map((style, i) => {
                                                            return (
                                                                <option key={i} value={style}>{style}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                                <div className='input-grp'>
                                                    <label htmlFor='style-select'>Tone</label>
                                                    <select id='style-select' className="form-select" value={selectedTone} onChange={e => setSelectedTone(e.target.value)}>
                                                        <option value={0}>Default</option>
                                                        {toneOptions.map((tone, i) => {
                                                            return (
                                                                <option key={i} value={tone}>{tone}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>

                        {selectedTemplate?.is_password_protected
                            &&
                            <div className='mb-4'>
                                <div className='d-flex align-items-center justify-content-between'>
                                    <label id="template-password">Password</label>
                                    {isPasswordIncorrect
                                        &&
                                        <span className='font-us text-danger'>Incorrect password</span>
                                    }
                                </div>
                                <PasswordInput
                                    value={password}
                                    onValueChange={e => { setIsPasswordIncorrect(false); setPassword(e.target.value) }}
                                    id="template-password"
                                    inputClassName={`${isPasswordIncorrect ? "border-danger" : ""}`}
                                />
                            </div>
                        }

                        <div className='d-flex flex-column-reverse flex-md-row w-100 justify-content-md-between align-items-md-center gap-3'>
                            <div>
                                <div className='d-flex flex-row-reverse flex-md-row align-items-start align-items-md-center gap-2 me-1'>
                                    <input type="checkbox" id="add-creator-as-collaborator-checkbox" checked={addCreatorAsCollaborator} onChange={e => setAddCreatorAsCollaborator(e.target.checked)} />
                                    <label htmlFor='add-creator-as-collaborator-checkbox' className='text-end text-md-start font-xxs'>Add this template's creator as a collaborator in this chat</label>
                                </div>
                            </div>

                            <button type='submit' className="button d-flex align-items-center gap-2 ms-auto" disabled={constructingPrompt || isPasswordIncorrect} onClick={constructPrompt}>
                                {constructingPrompt
                                    ?
                                    <div className="spinner-border spinner-border-sm" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                    :
                                    selectedTemplate?.is_password_protected
                                    && <MdLock className='font-s' />
                                }
                                Generate Response
                            </button>
                        </div>
                    </div>
                }
                bodyClass='pb-4'
                footerClass='pt-2 pb-3'
                hideFooter={!selectedTemplate?.notify_creator_on_use}
                footer={
                    selectedTemplate?.notify_creator_on_use
                    &&
                    <p className='text-secondary font-us mb-0 text-end w-100'>
                        By using this template, you agree to share your username and email with the creator of this template.
                    </p>

                }
            />
        </>
    )
}

export default Template