import React, { useContext, useEffect, useState } from 'react'
import { MdPersonAddAlt1 } from 'react-icons/md'
import ProtectedAxios from '../api/protectedAxios';
import { toast } from 'react-hot-toast';
import { RiCloseLine } from 'react-icons/ri'
import { GrFormAdd } from 'react-icons/gr'
import { FaPaperPlane } from 'react-icons/fa'
import { UserContext } from '../context/UserProvider';
import placeholderProfile from "../assets/placeholder-profile.jpg"
import { Dialog } from './ui/Dialog';

const ManageChatAccess = ({ chat_id, prompt, showAsLink }) => {
    const [user] = useContext(UserContext)

    const [username, setUsername] = useState("")
    const [loadingUsers, setLoadingUsers] = useState(false)
    const [users, setUsers] = useState([])
    const [showingList, setShowingList] = useState(false)

    const [accessList, setAccessList] = useState([])
    const [loadingAccessList, setLoadingAccessList] = useState(true)

    const [selectedUser, setSelectedUser] = useState(null)
    const [addingInAccessList, setAddingInAccessList] = useState(false)
    const [removingFromAccessList, setRemovingFromAccessList] = useState(false)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const [invitingUser, setInvitingUser] = useState(false)

    useEffect(() => {
        loadUsers()
    }, [username])

    useEffect(() => {
        if (show) {
            loadAccessList()
        }
    }, [show])

    const loadUsers = () => {
        if (username.length > 0) {
            setShowingList(true)
            setLoadingUsers(true)
            ProtectedAxios.post('/users/fetchUsersByUsername', { username, user_id: user.user_id, chat_id })
                .then(res => {
                    setUsers(res.data)
                    setLoadingUsers(false)
                })
                .catch(err => {
                    setLoadingUsers(false)
                    if (err.response.status === 500) {
                        toast.error(err.response.data.error)
                    } else {
                        console.log(err);
                    }
                })
        } else {
            setShowingList(false)
            setUsers([])
        }
    }

    const loadAccessList = () => {
        setLoadingAccessList(true)
        ProtectedAxios.post('/users/fetchAccessList', { chat_id })
            .then(res => {
                setAccessList(res.data)
                setLoadingAccessList(false)
            })
            .catch(err => {
                setLoadingAccessList(false)
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                } else {
                    console.log(err);
                }
            })
    }

    const addInAccessList = (_user) => {
        setAddingInAccessList(true)
        ProtectedAxios.post('/users/addInAccessList', { chat_id, recipient_id: _user.user_id, name: user.name, prompt, profile_picture_src: user.profile_picture_src })
            .then(res => {
                if (res.data) {
                    setUsername("")
                    setShowingList(false)
                    toast.success(`${_user.username} added in acccess list`)
                    loadAccessList()
                    setSelectedUser(null)
                    setAddingInAccessList(false)
                }
            })
            .catch(err => {
                setAddingInAccessList(false)
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                } else {
                    console.log(err);
                }
            })
    }

    const removeFromAccessList = (user) => {
        setRemovingFromAccessList(true)
        ProtectedAxios.post('/users/removeFomAccessList', { chat_access_id: user.chat_access_id })
            .then(res => {
                if (res.data) {
                    toast.success(`${user.username} removed from the access list`)
                    loadAccessList()
                    setSelectedUser(null)
                    setRemovingFromAccessList(false)
                }
            })
            .catch(err => {
                setRemovingFromAccessList(false)
                if (err.response.status === 500) {
                    toast.error(err.response.data.error)
                } else {
                    console.log(err);
                }
            })
    }
    const inviteUserByEmail = (user) => {
        if (emailRegex.test(username)) {
            setInvitingUser(true)
            ProtectedAxios.post('/users/inviteUserByEmail', { email: username, chat_id })
                .then(res => {
                    if (res.data) {
                        setInvitingUser(false)
                        toast.success(res.data)
                        setUsername('')
                    }
                })
                .catch(err => {
                    setInvitingUser(false)
                    if (err.response.status === 500) {
                        toast.error(err.response.data.error)
                    } else {
                        console.log(err);
                    }
                })
        }
        else {
            toast.error(`${username} is not a valid email`)
        }
    }
    return (
        <Dialog
            open={show}
            onOpenChange={handleClose}
            title='Manage chat access'
            hideFooter
            hideTrigger={false}
            trigger={
                !showAsLink
                    ?
                    <button className='edit-btn' id='share-button' title='share access' onClick={e => { handleShow() }}><MdPersonAddAlt1 className='edit-icon dark-icon' /></button>
                    :
                    <div onClick={handleShow}>
                        Chat Access
                    </div>
            }
            body={
                <div className='share-modal-body pb-3'>
                    <div className='input-with-icon position-relative'>
                        <input autoFocus={true} className='form-input' type="text" placeholder="search by username, name or email" value={username} onChange={e => setUsername(e.target.value)} />
                        {username.length
                            ?
                            <button type="button" className='button-icon edit-btn' title='clear' onClick={() => setUsername("")}><RiCloseLine className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>
                            :
                            <></>
                        }
                        {showingList
                            &&
                            <div className='list-dropdown'>
                                {loadingUsers
                                    ?
                                    <div>
                                        Loading
                                        <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </div>
                                    :
                                    <>
                                        {users.length > 0
                                            ?
                                            <>
                                                {users.map((user, i) => {
                                                    return (
                                                        <div key={i} className="list-item">
                                                            <div className='profile-pic' style={{ backgroundImage: `url(${user.profile_picture_src ? user.profile_picture_src : placeholderProfile})` }} />
                                                            <div className='list-item-body'>
                                                                <p className='name'>{user.name}</p>
                                                                <p className='username'>{user.username}</p>
                                                            </div>
                                                            <button disabled={addingInAccessList} type="button" className='edit-btn' title='add access' value={JSON.stringify(user)} onClick={e => { setSelectedUser(JSON.parse(e.target.value)); addInAccessList(JSON.parse(e.target.value)) }}>
                                                                {(selectedUser?.user_id === user.user_id && addingInAccessList)
                                                                    ? <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                                        <span className="sr-only"></span>
                                                                    </div>

                                                                    :
                                                                    <GrFormAdd className='edit-icon reject-icon' />
                                                                }
                                                            </button>
                                                        </div>
                                                    )
                                                })}
                                            </>

                                            :
                                            <>
                                                {emailRegex.test(username)
                                                    ?
                                                    <button title={`${username} - send invite`} disabled={invitingUser} onClick={() => inviteUserByEmail()} className='invite-button'>
                                                        Invite - {username.substring(0, 18)}{username.length > 18 && "..."}
                                                        {invitingUser
                                                            ?
                                                            <div style={{ width: "1.1rem", height: "1.1rem" }} className="d-block spinner-border spinner-border-sm" role="status">
                                                                <span className="sr-only"></span>
                                                            </div>
                                                            : <div><FaPaperPlane /></div>
                                                        }
                                                    </button>

                                                    :
                                                    <p className='d-flex m-0 mx-2'>No users found, type complete email to invite users and share access</p>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </div>
                        }
                    </div>
                    <div className='access-list-container'>
                        <h3>People with access</h3>
                        <div className='access-list'>
                            {loadingAccessList
                                ?
                                <>
                                    Loading
                                    <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </>

                                :
                                <>
                                    {accessList.length === 0
                                        ? <p className='px-1 '>No access given</p>
                                        :
                                        <>
                                            {accessList.map((item, i) => {
                                                return (
                                                    <div key={i} className="list-item">
                                                        <div className='profile-pic' style={{ backgroundImage: `url(${item.profile_picture_src ? item.profile_picture_src : placeholderProfile})` }} />
                                                        <div className='list-item-body'>
                                                            <p className='name'>{item.name}</p>
                                                            <p className='username'>{item.email}</p>
                                                        </div>
                                                        <button disabled={removingFromAccessList} type="button" className='edit-btn' title='remove access' value={JSON.stringify(item)} onClick={e => { setSelectedUser(JSON.parse(e.target.value)); removeFromAccessList(JSON.parse(e.target.value)) }}>
                                                            {(removingFromAccessList && selectedUser.username === item.username)
                                                                ?
                                                                <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                                    <span className="sr-only"></span>
                                                                </div>

                                                                :
                                                                <RiCloseLine className='edit-icon reject-icon' />
                                                            }
                                                        </button>
                                                    </div>
                                                )
                                            })}
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            }
        />
    )
}

export default ManageChatAccess