import React from 'react'
import { Dialog } from '../ui/Dialog';
import OpenaiCompatibleIcon from '../custom-svgs/OpenaiCompatibleIcon';
import OpenaiCompatibleApiKeysSetting from '../chat/settings/settingTabs/OpenaiCompatibleApiKeysSetting';


const AddOpenaiCompatibleAPIkeyDialog = ({ open, onOpenChange, handleSubmit, selectedUser, setSelectedUser, title, message }) => {

    return (
        <Dialog
            open={open}
            onOpenChange={onOpenChange}
            hideTrigger
            hideFooter
            title={title ? title : "API key not found"}
            body={
                <div className='px-1 pb-5'>
                    <OpenaiCompatibleIcon />
                    <p className='mt-2'>{message}</p>
                    <OpenaiCompatibleApiKeysSetting handleSubmit={handleSubmit} selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
                </div>
            }
        />
    )
}

export default AddOpenaiCompatibleAPIkeyDialog