import React, { useContext, useEffect, useState } from 'react'
import { MdOutlineDone } from 'react-icons/md'
import { toast } from 'react-hot-toast';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { FiExternalLink } from 'react-icons/fi';
import { BiCloudUpload } from 'react-icons/bi';
import { UserContext } from '../../../../context/UserProvider';
import ProtectedAxios from '../../../../api/protectedAxios';
import LoadingSkeleton from '../../../LoadingSkeleton';

const BedrockApiKeysSetting = ({ open, activeTab, handleSubmit, onHide, selectedUser, setSelectedUser }) => {
    const [user, setUser] = useContext(UserContext)

    const [bedrockAccessKeyOld, setBedrockAccessKeyOld] = useState("");
    const [bedrockAccessKey, setBedrockAccessKey] = useState("");
    const [bedrockSecretAccessKeyOld, setBedrockSecretAccessKeyOld] = useState("");
    const [bedrockSecretAccessKey, setBedrockSecretAccessKey] = useState("");
    const [viewingBedrockSecretAccessKey, setViewingBedrockSecretAccessKey] = useState(false);
    const [updatingBedrockKeys, setUpdatingBedrockKeys] = useState(false);
    const [bedrockKeysAdded, setBedrockKeysAdded] = useState(false);
    const [bedrockModelId, setBedrockModelId] = useState("");
    const [bedrockModelRegionOld, setBedrockModelRegionOld] = useState("");
    const [bedrockModelRegion, setBedrockModelRegion] = useState("");

    const [bedrockModelIds, setBedrockModelIds] = useState([]);
    const [loadingDefaultBedrockModelId, setLoadingDefaultBedrockModelId] = useState(true);
    const [selectedBedrockModelIdOld, setSelectedBedrockModelIdOld] = useState("");
    const [selectedBedrockModelId, setSelectedBedrockModelId] = useState("");

    useEffect(() => {
        if (open && activeTab === "bedrock") {
            loadBedrockModels()
            if (selectedUser) {
                setBedrockAccessKeyOld(selectedUser?.bedrock_access_key ? selectedUser.bedrock_access_key : "");
                setBedrockAccessKey(selectedUser?.bedrock_access_key ? selectedUser.bedrock_access_key : "");
                setBedrockSecretAccessKeyOld(selectedUser?.bedrock_secret_access_key ? selectedUser.bedrock_secret_access_key : "");
                setBedrockSecretAccessKey(selectedUser?.bedrock_secret_access_key ? selectedUser.bedrock_secret_access_key : "");
                setBedrockModelRegionOld(selectedUser?.bedrock_model_region ? selectedUser.bedrock_model_region : "");
                setBedrockModelRegion(selectedUser?.bedrock_model_region ? selectedUser.bedrock_model_region : "");
            } else {
                setBedrockAccessKeyOld(user?.bedrock_access_key ? user.bedrock_access_key : "");
                setBedrockAccessKey(user?.bedrock_access_key ? user.bedrock_access_key : "");
                setBedrockSecretAccessKeyOld(user?.bedrock_secret_access_key ? user.bedrock_secret_access_key : "");
                setBedrockSecretAccessKey(user?.bedrock_secret_access_key ? user.bedrock_secret_access_key : "");
                setBedrockModelRegionOld(user?.bedrock_model_region ? user.bedrock_model_region : "");
                setBedrockModelRegion(user?.bedrock_model_region ? user.bedrock_model_region : "");
            }
        }
    }, [user, selectedUser, open])

    useEffect(() => {
        if (bedrockKeysAdded) {
            setTimeout(() => {
                setBedrockKeysAdded(false)
            }, 2000)
        }
    }, [bedrockKeysAdded]);

    const loadBedrockModels = () => {
        setLoadingDefaultBedrockModelId(true)
        ProtectedAxios.get('/users/keys/bedrock/models')
            .then(res => {
                setBedrockModelIds(res.data.models)

                ProtectedAxios.post('/users/keys/bedrock/model/default', { selected_user_id: selectedUser ? selectedUser.user_id : user.user_id })
                    .then(res => {
                        setSelectedBedrockModelIdOld(res.data.bedrock_model_id)
                        setSelectedBedrockModelId(res.data.bedrock_model_id)
                        setLoadingDefaultBedrockModelId(false)
                    })
                    .catch(err => {
                        throw err
                    })
            })
            .catch(err => {
                console.log(err);
                toast.error("Could not load your default bedrock model")
                setLoadingDefaultBedrockModelId(false)
            })

    };

    const updateBedrockKeys = e => {
        e?.preventDefault()
        setUpdatingBedrockKeys(true)
        ProtectedAxios.post('/users/keys/bedrock/update', { user_id: user.user_id, bedrockAccessKey, bedrockSecretAccessKey, bedrockModelId: selectedBedrockModelId, bedrockModelRegion })
            .then(() => {
                if (selectedUser) {
                    setSelectedUser({ ...selectedUser, bedrock_access_key: bedrockAccessKey, bedrock_secret_access_key: bedrockSecretAccessKey, bedrock_model_id: bedrockModelId, bedrock_model_region: bedrockModelRegion })
                } else {
                    setUser({ ...user, bedrock_access_key: bedrockAccessKey, bedrock_secret_access_key: bedrockSecretAccessKey, bedrock_model_id: bedrockModelId, bedrock_model_region: bedrockModelRegion })
                }
                setUpdatingBedrockKeys(false)
                setBedrockKeysAdded(true)
                if (onHide) {
                    onHide()
                }
                if (handleSubmit) {
                    handleSubmit(null, bedrockAccessKey)
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.data.error) {
                    toast.error(err.response.data.error)
                } else {
                    toast.error("Could not update bedrock keys at the moment")
                }
                setUpdatingBedrockKeys(false)
            })
    }

    return (
        <div className='mb-3 mt-4 px-2'>
            <form onSubmit={updateBedrockKeys}>
                <div className='d-flex justify-content-end gap-2' style={{ marginBottom: "-1.4rem", minHeight: "2.6rem" }}>
                    {
                        bedrockKeysAdded
                            ?
                            <div>
                                <button type="submit" className='button-link'>
                                    <MdOutlineDone className='font-m' style={{ fill: "green" }} />
                                    <span className='font-us'>Saved</span>
                                </button>
                            </div>

                            :
                            (bedrockAccessKey && bedrockSecretAccessKey && selectedBedrockModelId && bedrockModelRegion &&
                                (
                                    bedrockAccessKey !== bedrockAccessKeyOld
                                    || bedrockSecretAccessKey !== bedrockSecretAccessKeyOld
                                    || selectedBedrockModelId !== selectedBedrockModelIdOld
                                    || bedrockModelRegion !== bedrockModelRegionOld
                                ))
                            &&
                            <div>
                                <button type="submit" className='button-link'>
                                    {updatingBedrockKeys
                                        ?
                                        <div className="spinner-border spinner-border-sm" role="status">
                                            <span className="sr-only"></span>
                                        </div>

                                        :
                                        <BiCloudUpload className='font-m' />
                                    }
                                    <span className='font-us'>Save {handleSubmit && "& continue"}</span>
                                </button>
                            </div>
                    }
                </div>
                <div className='input-grp'>
                    <label className='flexed-between mb-1'>
                        <span>
                            Access Key
                        </span>
                    </label>
                    <div className='input-with-icon'>
                        <input required type='text' id='key' value={bedrockAccessKey} onChange={e => setBedrockAccessKey(e.target.value)} placeholder={'paste your AWS Access Key here'} className='' />
                    </div>
                </div>

                <div className='input-grp mt-4'>
                    <label className='flexed-between mb-1'>
                        <span>
                            Secret Access Key
                        </span>
                    </label>
                    <div className='input-with-icon'>
                        <input required type={viewingBedrockSecretAccessKey ? 'text' : 'password'} id='key' value={bedrockSecretAccessKey} onChange={e => setBedrockSecretAccessKey(e.target.value)} placeholder={'paste your AWS Secret Access Key here'} className='' />
                        {bedrockSecretAccessKey
                            &&
                            <>
                                {viewingBedrockSecretAccessKey
                                    ?
                                    <button type="button" className='button-icon edit-btn' title='hide key' onClick={() => { setViewingBedrockSecretAccessKey(false) }}><AiFillEyeInvisible className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>

                                    :
                                    <button type="button" className='button-icon edit-btn' title='view key' onClick={() => { setViewingBedrockSecretAccessKey(true) }}><AiFillEye className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>
                                }
                            </>
                        }
                    </div>
                </div>

                <div className='input-grp mt-4'>
                    <label className='flexed-between mb-1'>
                        <span>
                            Bedrock Model
                        </span>
                    </label>
                    {loadingDefaultBedrockModelId
                        ?
                        <LoadingSkeleton type='select' />
                        :
                        <select
                            id='bedrock-models'
                            className="form-select"
                            value={selectedBedrockModelId}
                            onChange={e => setSelectedBedrockModelId(e.target.value)}
                        >
                            {bedrockModelIds.map((id, i) => {
                                return (
                                    <option key={i} value={id}>{id}</option>
                                )
                            })}
                        </select>
                    }
                </div>

                <div className='input-grp mt-4'>
                    <label className='flexed-between mb-1'>
                        <span>
                            Region
                        </span>
                    </label>
                    <div className='input-with-icon'>
                        <input required type='text' id='model_region' value={bedrockModelRegion} onChange={e => setBedrockModelRegion(e.target.value)} placeholder={'paste your aws region here'} />
                    </div>
                </div>


                <p className='text-left mt-4 mx-1 font-xxs'>Unsure where to find these keys? Check <a target="_blank" href="https://sagecollab.com/#" rel="noreferrer">this guide <FiExternalLink /></a></p>
            </form>
        </div>
    )
}

export default BedrockApiKeysSetting