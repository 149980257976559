import React, { useContext, useEffect, useState } from 'react'
import { MdOutlineDone } from 'react-icons/md'
import { toast } from 'react-hot-toast';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { BiCloudUpload } from 'react-icons/bi';
import { UserContext } from '../../../../context/UserProvider';
import ProtectedAxios from '../../../../api/protectedAxios';

const OpenaiCompatibleApiKeysSetting = ({ show, activeTab, handleSubmit, onHide, selectedUser, setSelectedUser }) => {
    const [user, setUser] = useContext(UserContext)

    const [openaiCompatibleEndpointOld, setOpenaiCompatibleEndpointOld] = useState("");
    const [openaiCompatibleEndpoint, setOpenaiCompatibleEndpoint] = useState("");
    const [openaiCompatibleApiKeyOld, setOpenaiCompatibleApiKeyOld] = useState("");
    const [openaiCompatibleApiKey, setOpenaiCompatibleApiKey] = useState("");
    const [openaiCompatibleModelOld, setOpenaiCompatibleModelOld] = useState("");
    const [openaiCompatibleModel, setOpenaiCompatibleModel] = useState("");
    const [viewingApiKey, setViewingApiKey] = useState(false);
    const [updatingKeys, setUpdatingKeys] = useState(false);
    const [keysAdded, setKeysAdded] = useState(false);

    useEffect(() => {
        if (show) {
            if (selectedUser) {
                setOpenaiCompatibleEndpointOld(selectedUser?.openai_compatible_endpoint ? selectedUser.openai_compatible_endpoint : "");
                setOpenaiCompatibleEndpoint(selectedUser?.openai_compatible_endpoint ? selectedUser.openai_compatible_endpoint : "");
                setOpenaiCompatibleApiKeyOld(selectedUser?.openai_compatible_api_key ? selectedUser.openai_compatible_api_key : "");
                setOpenaiCompatibleApiKey(selectedUser?.openai_compatible_api_key ? selectedUser.openai_compatible_api_key : "");
                setOpenaiCompatibleModelOld(selectedUser?.openai_compatible_model ? selectedUser.openai_compatible_model : "");
                setOpenaiCompatibleModel(selectedUser?.openai_compatible_model ? selectedUser.openai_compatible_model : "");
            } else {
                setOpenaiCompatibleEndpointOld(user?.openai_compatible_endpoint ? user.openai_compatible_endpoint : "");
                setOpenaiCompatibleEndpoint(user?.openai_compatible_endpoint ? user.openai_compatible_endpoint : "");
                setOpenaiCompatibleApiKeyOld(user?.openai_compatible_api_key ? user.openai_compatible_api_key : "");
                setOpenaiCompatibleApiKey(user?.openai_compatible_api_key ? user.openai_compatible_api_key : "");
                setOpenaiCompatibleModelOld(user?.openai_compatible_model ? user.openai_compatible_model : "");
                setOpenaiCompatibleModel(user?.openai_compatible_model ? user.openai_compatible_model : "");
            }
        }
    }, [user, show, selectedUser])

    useEffect(() => {
        if (keysAdded) {
            setTimeout(() => {
                setKeysAdded(false)
            }, 2000)
        }
    }, [keysAdded])

    const updateKeys = e => {
        e?.preventDefault()
        setUpdatingKeys(true)
        ProtectedAxios.post('/users/keys/openai-compatible/update', { user_id: user.user_id, openaiCompatibleEndpoint, openaiCompatibleApiKey, openaiCompatibleModel })
            .then(() => {
                if (selectedUser) {
                    setSelectedUser({ ...selectedUser, openai_compatible_endpoint: openaiCompatibleEndpoint, openai_compatible_api_key: openaiCompatibleApiKey, openai_compatible_model: openaiCompatibleModel })
                } else {
                    setUser({ ...user, openai_compatible_endpoint: openaiCompatibleEndpoint, openai_compatible_api_key: openaiCompatibleApiKey, openai_compatible_model: openaiCompatibleModel })
                }
                setUpdatingKeys(false);
                setKeysAdded(true);
                if (onHide) {
                    onHide();
                }
                if (handleSubmit) {
                    handleSubmit(null, openaiCompatibleApiKey);
                }
            })
            .catch(err => {
                console.log(err);
                if (err.response.data.error) {
                    toast.error(err.response.data.error);
                } else {
                    toast.error("Could not update keys for openai compatible model at the moment");
                }
                setUpdatingKeys(false);
            })
    }

    return (
        <div className='mb-3 mt-4 px-2'>
            <form onSubmit={updateKeys}>
                <div className='d-flex justify-content-end gap-2' style={{ marginBottom: "-1.4rem", minHeight: "2.6rem" }}>
                    {
                        keysAdded
                            ?
                            <div>
                                <button type="submit" className='button-link'>
                                    <MdOutlineDone className='font-m' style={{ fill: "green" }} />
                                    <span className='font-us'>Saved</span>
                                </button>
                            </div>

                            :
                            (openaiCompatibleEndpoint && openaiCompatibleApiKey && openaiCompatibleModel &&
                                (
                                    openaiCompatibleEndpoint !== openaiCompatibleEndpointOld
                                    || openaiCompatibleApiKey !== openaiCompatibleApiKeyOld
                                    || openaiCompatibleModel !== openaiCompatibleModelOld
                                ))
                            &&
                            <div>
                                <button type="submit" className='button-link'>
                                    {updatingKeys
                                        ?
                                        <div className="spinner-border spinner-border-sm" role="status">
                                            <span className="sr-only"></span>
                                        </div>

                                        : <BiCloudUpload className='font-m' />
                                    }
                                    <span className='font-us'>Save {handleSubmit && "& continue"}</span>
                                </button>
                            </div>
                    }
                </div>
                <div className='input-grp'>
                    <label className='flexed-between mb-1'>
                        <span>
                            Endpoint
                        </span>
                    </label>
                    <div className='input-with-icon'>
                        <input required type='text' id='key' value={openaiCompatibleEndpoint} onChange={e => setOpenaiCompatibleEndpoint(e.target.value)} placeholder={'paste your model endpoint here'} />
                    </div>
                </div>

                <div className='input-grp mt-4'>
                    <label className='flexed-between mb-1'>
                        <span>
                            API Key
                        </span>
                    </label>
                    <div className='input-with-icon'>
                        <input required type={viewingApiKey ? 'text' : 'password'} id='key' value={openaiCompatibleApiKey} onChange={e => setOpenaiCompatibleApiKey(e.target.value)} placeholder={'paste your API Key here'} />
                        {openaiCompatibleApiKey
                            &&
                            <>
                                {viewingApiKey
                                    ?
                                    <button type="button" className='button-icon edit-btn' title='hide key' onClick={() => { setViewingApiKey(false) }}><AiFillEyeInvisible className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>

                                    :
                                    <button type="button" className='button-icon edit-btn' title='view key' onClick={() => { setViewingApiKey(true) }}><AiFillEye className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>
                                }
                            </>
                        }
                    </div>
                </div>

                <div className='input-grp mt-4'>
                    <label className='flexed-between mb-1'>
                        <span>
                            Model
                        </span>
                    </label>
                    <div className='input-with-icon'>
                        <input required type='text' id='model_id' value={openaiCompatibleModel} onChange={e => setOpenaiCompatibleModel(e.target.value)} placeholder={'paste your Model ID here'} />
                    </div>
                </div>
            </form>
        </div>
    )
}

export default OpenaiCompatibleApiKeysSetting