import React, { useContext } from 'react'
import CustomModal from './CustomModal'
import { NavLink } from 'react-router-dom'
import { UserContext } from '../../context/UserProvider'
import useSubscriptionDetails from '../../hooks/useSubscriptionDetails'

const MaxAiInteractionsReachedDialog = ({ show, onHide }) => {
    const [user] = useContext(UserContext)
    const [subscriptionDetail] = useSubscriptionDetails(user.stripe_customer_id)

    return (
        <CustomModal
            show={show}
            onHide={onHide}
            title={<h3 className='font-m m-0'>Max Limit Reached</h3>}
            content={
                <div className='my-4'>
                    <h5>Upgrade Your Experience</h5>
                    <p>
                        You have reached maximum number of AI interactions in 24 hours. Please try again later.
                        <br />

                        {subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_A_ID
                            ? "Upgrade your account to access more AI interactions and premium features."
                            : "Please contact us for increasing your quota."
                        }
                    </p>
                </div>
            }
            buttons={<>
                {subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_A_ID
                    &&
                    <NavLink to="/upgrade" className='button px-4'>Check upgrade options</NavLink>
                }
            </>}
        />
    )
}

export default MaxAiInteractionsReachedDialog