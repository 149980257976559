import React, { useContext, useState } from 'react'
import { MdPersonAddAlt1 } from 'react-icons/md'
import ProtectedAxios from '../api/protectedAxios';
import { toast } from 'react-hot-toast';
import { BiErrorCircle } from 'react-icons/bi'
import { EventContext } from '../context/EventProvider';
import { useNavigate } from 'react-router-dom';
import { Dialog } from './ui/Dialog';

const DeleteChat = ({ chat_id, prompt, chat_name, setFilteredChatList, viewingInChat = false }) => {
    const { emitEvent } = useContext(EventContext);

    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); emitEvent({ eventName: "closeChatDropdown", timestamp: new Date() }); }
    const handleShow = () => setShow(true);

    const [deletingChat, setDeletingChat] = useState(false)

    const navigate = useNavigate()

    const deleteChat = (e) => {
        e.preventDefault()
        setDeletingChat(true)
        ProtectedAxios.post('/users/deleteChat', { chat_id })
            .then(res => {
                if (res.data) {
                    setDeletingChat(false)
                    toast.success("Chat deleted")

                    if (viewingInChat) {
                        navigate(-1)
                    }
                    else {
                        emitEvent({ eventName: "closeChatDropdown", timestamp: new Date() });
                        setFilteredChatList(prev => {
                            let updatedList = prev.filter(chat => chat.chat_id !== chat_id)
                            return updatedList
                        })
                    }

                    handleClose()
                }
            })
            .catch(err => {
                setDeletingChat(false)
                console.log(err);
                if (err.response.status === 500) {
                    toast.error(err.response.data.error, { id: 'delete-error' })
                }
                if (err.response.status === 403) {
                    toast(err.response.data.error, { icon: <BiErrorCircle style={{ color: "red", fontSize: "calc(var(--size-xxl) * 1.3)" }} />, id: 'delete-error' })
                } else {
                    console.log(err);
                }
            })
    }


    return (
        <>
            {true
                ?
                <div
                    onClick={handleShow}
                >
                    Delete
                </div>
                :
                <button className='edit-btn' id='share-button' title='share access' onClick={e => { handleShow() }}><MdPersonAddAlt1 className='edit-icon dark-icon' /></button>
            }


            <Dialog
                open={show}
                onOpenChange={handleClose}
                title='Delete chat'
                hideTrigger
                hideFooter
                body={
                    <form onSubmit={deleteChat}>
                        <div className='pt-3 pb-2 px-2'>
                            <p>
                                <b>
                                    Are you sure you want to delete this chat?
                                </b>
                                <br />
                                {chat_name
                                    ? chat_name
                                    : <>{prompt.substring(0, 60)}{prompt.length > 60 && '...'}</>
                                }
                            </p>
                        </div>
                        <button type='submit' className='button button-danger mb-3 d-flex ms-auto align-items-center gap-2' disabled={deletingChat}>
                            {deletingChat
                                &&
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            }
                            Yes, Delete
                        </button>
                    </form>
                }
            />
        </>
    )
}

export default DeleteChat