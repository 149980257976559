import React, { memo, useContext, useEffect, useRef, useState } from 'react'
import ProtectedAxios from '../api/protectedAxios'
import LoadingSkeleton from './LoadingSkeleton'
import { IoCheckmark, IoCopy } from "react-icons/io5"
import ResponseNotes from './ResponseNotes'
import { SocketContext } from '../context/SocketProvider'
import logo from '../assets/logo.svg'
import { UserContext } from '../context/UserProvider'
import useSubscriptionDetails from '../hooks/useSubscriptionDetails'
import toast from 'react-hot-toast'
import { MdPostAdd, MdRefresh } from 'react-icons/md'
import ReactMarkdown from 'react-markdown';
import CodeBlock from './ui/CodeBlock'
import rehypeHighlight from 'rehype-highlight';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { EventContext } from '../context/EventProvider'
import OpenaiCompatibleIcon from './custom-svgs/OpenaiCompatibleIcon'

const codeLanguageSubset = [
    'python',
    'javascript',
    'java',
    'go',
    'bash',
    'c',
    'cpp',
    'csharp',
    'css',
    'diff',
    'graphql',
    'json',
    'kotlin',
    'less',
    'lua',
    'makefile',
    'markdown',
    'objectivec',
    'perl',
    'php',
    'php-template',
    'plaintext',
    'python-repl',
    'r',
    'ruby',
    'rust',
    'scss',
    'shell',
    'sql',
    'swift',
    'typescript',
    'vbnet',
    'wasm',
    'xml',
    'yaml',
];

const Responses = ({ chat_id, chatDetails, chatDetailsSetter, message_id, isEnterpriseAdmin, tokens, updateTokens, setShowAdModal, setShowMaxInteractionsReachedModal, setMessages, setAddingMessage, lastResponse, viewingSharedChat }) => {
    const [mySocket] = useContext(SocketContext);
    const { eventData, emitEvent } = useContext(EventContext);
    const [subscriptionDetail] = useSubscriptionDetails()
    const [user] = useContext(UserContext)

    const [responses, setResponses] = useState([])
    const [loading, setLoading] = useState(true)

    const [showingRegenerateOptions, setShowingRegenerateOptions] = useState(false)
    const [regeneratingResponse, setRegeneratingResponse] = useState(false)
    const regenerateOptionsRef = useRef(null)
    useClickOutside(regenerateOptionsRef)

    const [selectedResponse, setSelectedResponse] = useState(null)
    const [copied, setCopied] = useState(false)
    useEffect(() => {
        if (copied === true) {
            setTimeout(() => {
                setCopied(false)
            }, 1000)
        }
    }, [copied])


    useEffect(() => {
        if (mySocket) {
            mySocket.on('response_added', (data) => {
                fetchResponses(false)
            })
        }
    }, [])


    useEffect(() => {
        fetchResponses()
    }, [message_id])

    const fetchResponses = (_load = true) => {
        if (_load) {
            setLoading(true)
        }
        ProtectedAxios.post("/users/responses", { chat_id, message_id })
            .then(res => {
                setLoading(false)
                setResponses(res.data)
            })
            .catch(err => {
                console.log(err);
                setLoading(false)
            })
    }

    function useClickOutside(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowingRegenerateOptions(false)
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const regenerateLastResponse = (_isUsingSelfAPI) => {
        if (process.env.REACT_APP_SHOW_AD === "true" && subscriptionDetail.price_id === process.env.REACT_APP_PRICE_A_ID) {
            setShowAdModal(true)
        }

        setRegeneratingResponse(true)
        setAddingMessage(true)
        setShowingRegenerateOptions(false)

        // toast.loading("Regenerating response...", { id: 'regenerate-response', duration: Infinity })

        const startTime = performance.now()

        ProtectedAxios.post("/users/regenerateResponse", { user_id: user.user_id, role_id: user.role_id, chat_id, customer_id: user.stripe_customer_id, is_using_self_api: _isUsingSelfAPI, model_provider: chatDetails.model_provider, template_id: chatDetails?.template_ref?.template_id })
            .then(res => {
                const endTime = performance.now()
                let timeTaken = endTime - startTime
                let waitingTime = 15000 - timeTaken

                if (subscriptionDetail.price_id !== process.env.REACT_APP_PRICE_A_ID || process.env.REACT_APP_SHOW_AD === "false") {
                    waitingTime = 0
                }

                setTimeout(() => {
                    setShowAdModal(false)

                    //removing and adding the last message in the messages after some time gap to make sure that the last message component re-renders so that new response can be shown to the user
                    setMessages(prev => {
                        let updatedMessages = [...prev]
                        updatedMessages = updatedMessages.slice(0, updatedMessages.length - 1)
                        return updatedMessages
                    })
                    setTimeout(() => {
                        setMessages(prev => {
                            let updatedMessages = [...prev]
                            updatedMessages.push(res.data)
                            return updatedMessages
                        })
                        chatDetailsSetter(prev => { return { ...prev, total_tokens: res.data.total_tokens } })
                    }, 200)

                    //update the tokens
                    try {
                        updateTokens()
                    }
                    catch (err) {
                        console.log(err);
                    }

                    //show success messages
                    toast.success("Response regenerated", { id: 'regenerate-response', duration: 2000 })
                    setRegeneratingResponse(false)
                    setAddingMessage(false);

                    //scroll to the new message
                    setTimeout(() => {
                        var element = document.getElementById(`response-${res.data.response_id}`);
                        var headerOffset = 100;
                        var elementPosition = element.getBoundingClientRect().top;
                        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

                        window.scrollTo({
                            top: offsetPosition,
                            behavior: "smooth"
                        });
                    }, 1000)
                }, waitingTime)
            })
            .catch(err => {
                console.log(err);

                const endTime = performance.now()
                let timeTaken = endTime - startTime
                let waitingTime = process.env.REACT_APP_AD_DURATION - timeTaken

                if (subscriptionDetail.price_id !== process.env.REACT_APP_PRICE_A_ID || process.env.REACT_APP_SHOW_AD === "false") {
                    waitingTime = 0
                }

                setTimeout(() => {
                    setShowAdModal(false);
                    if (err.response.status === 500) {
                        toast.error(err.response.data.error, { id: 'regenerate-response', duration: 2000 });
                    }
                    else if (err.response.status === 401) {
                        toast.error("You dont have enough tokens to add this message, please topup to continue", { id: 'regenerate-response', duration: 2000 });
                    }
                    else if (err.response.status === 429) {     //max ai interactions reached
                        setShowMaxInteractionsReachedModal(true);
                        toast.dismiss("regenerate-response");
                    }
                    setRegeneratingResponse(false);
                    setAddingMessage(false);
                }, waitingTime);
            });
    }

    return (
        loading
            ?
            <LoadingSkeleton type='rectangle' />

            :
            <div className='responses'>
                {
                    responses.map((response, i) => {
                        return (
                            <>
                                <div className={`response ${i > 0 ? "mt-4" : ""}`}>
                                    {i === 0
                                        ?
                                        <div className='response-header'>
                                            <div className='position-relative'>
                                                {chatDetails.model_provider === "GEMINI"
                                                    ?
                                                    <img
                                                        className='absolute-provider-logo'
                                                        src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/google-gemini-icon.png"
                                                        alt=""
                                                    />

                                                    : chatDetails.model_provider === "OPENAI"
                                                        ?
                                                        <img
                                                            className='absolute-provider-logo'
                                                            src="https://www.svgrepo.com/show/306500/openai.svg"
                                                            alt=""
                                                        />

                                                        : chatDetails.model_provider === "BEDROCK"
                                                            ?
                                                            <img
                                                                src="https://www.outsystems.com/Forge_CW/_image.aspx/Q8LvY--6WakOw9afDCuuGbQ9u-QKbiqiEaG1FDMiKVo=/aws-bedrock-runtime-2023-01-04%2000-00-00-2024-09-12%2014-12-44"
                                                                className='rounded-circle absolute-provider-logo'
                                                                alt=""
                                                            />
                                                            : chatDetails.model_provider === "OPENAI_COMPATIBLE"
                                                            &&
                                                            <OpenaiCompatibleIcon
                                                                className='rounded-circle absolute-provider-logo'
                                                            />
                                                }
                                                <img className='profile-pic chat-profile-pic' src={logo} alt="" />
                                            </div>
                                        </div>

                                        :
                                        <div className='response-header opacity-0'>
                                            <div className='position-relative'>
                                                <img className='profile-pic chat-profile-pic' src={logo} alt="" />
                                            </div>
                                        </div>

                                    }
                                    <div className='response-body' key={i} id={`response-${response.response_id}`}>
                                        <div className='response-body-header'>
                                            <div>
                                                {i === 0
                                                    ? <div className='response-by'>
                                                        Sagecollab
                                                    </div>
                                                    :
                                                    <div className='response-by'>
                                                        option {i + 1}
                                                        <br />
                                                        {response.created_by !== user.user_id
                                                            &&
                                                            <div className='d-flex align-items-center gap-1 mt-1 mb-3'>
                                                                <img src={response.profile_picture_src} className='profile-pic w-m h-m' alt={response.username} />
                                                                <span className='font-us color-response-highlight-lightened'>Regenerated by {response.name}</span>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            {/* <button type='button' id='copy-response-content-button' className='button-icon align-self-start' title={copied ? 'copied' : 'copy'} value={response.response_content} onClick={e => { setSelectedResponse(response); navigator.clipboard.writeText(e.target.value).then(() => { setCopied(true) }) }}>
                                                {copied && selectedResponse?.response_id === response.response_id
                                                    ?
                                                    <IoCheckmark className='font-xs text-success' />
                                                    :
                                                    <IoCopy className='font-xs' />
                                                }
                                            </button> */}
                                        </div>
                                        <ReactMarkdown
                                            remarkPlugins={[
                                                remarkGfm,
                                                [remarkMath, { singleDollarTextMath: true }]
                                            ]}
                                            rehypePlugins={[
                                                rehypeKatex,
                                                [
                                                    rehypeHighlight,
                                                    {
                                                        detect: true,
                                                        ignoreMissing: true,
                                                        subset: codeLanguageSubset,
                                                    },
                                                ],
                                            ]}
                                            components={{
                                                code,
                                                p,
                                                table,
                                                a
                                            }}
                                        >
                                            {response.response_content}
                                        </ReactMarkdown>

                                        <ResponseNotes chat_id={chat_id} response_id={response.response_id} isEnterpriseAdmin={isEnterpriseAdmin} />

                                        <div className="chat-actions response-actions">
                                            <button type='button' className='button-link color-dark' title="Add note" onClick={e => { emitEvent({ eventName: "show_new_note_form", response_id: response.response_id }) }}>
                                                {true
                                                    &&
                                                    <div className='d-flex align-items-center gap-1'>
                                                        <MdPostAdd className='font-s' style={{ marginBottom: "2px" }} />
                                                        <span className='font-uus d-none d-md-block'>
                                                            Add note
                                                        </span>
                                                    </div>
                                                }
                                            </button>

                                            {i + 1 === responses.length && lastResponse && !viewingSharedChat
                                                &&
                                                <div className='regenerate-container'>
                                                    <button type='button' id='regenerate-response-button' className='button-link color-dark' title='regenerate response' disabled={regeneratingResponse}
                                                        onClick={() => {
                                                            if (chatDetails.model_provider === "OPENAI") {
                                                                if (subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_A_ID || subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_D_ID || tokens === 0) {
                                                                    regenerateLastResponse(true)
                                                                }
                                                                else {
                                                                    if (showingRegenerateOptions) {
                                                                        setShowingRegenerateOptions(false)
                                                                        setTimeout(() => {
                                                                            setShowingRegenerateOptions(true)
                                                                        }, 200)
                                                                    }
                                                                    else {
                                                                        setShowingRegenerateOptions(true)
                                                                    }
                                                                }
                                                            }
                                                            else {
                                                                regenerateLastResponse(true)
                                                            }
                                                        }}
                                                    >
                                                        {regeneratingResponse
                                                            ?
                                                            <>
                                                                <div className="spinner-border spinner-border-sm" role="status">
                                                                    <span className="sr-only"></span>
                                                                </div>
                                                                <span className='font-uus'>
                                                                    Regenerate
                                                                </span>
                                                            </>
                                                            :
                                                            <div className='d-flex align-items-center gap-1'>
                                                                <MdRefresh className='font-s' />
                                                                <span className='font-uus d-none d-md-block'>
                                                                    Regenerate
                                                                </span>
                                                            </div>
                                                        }
                                                    </button>

                                                    {showingRegenerateOptions
                                                        &&
                                                        <div ref={regenerateOptionsRef}>
                                                            <div className='regenerate-options'>
                                                                <span className='link cursor-pointer' onClick={() => regenerateLastResponse(false)}>Use SageCollab Tokens</span>

                                                                <span className='link cursor-pointer' onClick={() => regenerateLastResponse(true)}>Use My API Key</span>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            }

                                            <button type='button' className='button-link color-dark' title={copied ? 'copied' : 'copy'} onClick={e => { setSelectedResponse(response); navigator.clipboard.writeText(response.response_content).then(() => { setCopied(true) }) }}>
                                                {copied && selectedResponse?.response_id === response.response_id
                                                    ?
                                                    <div className='d-flex align-items-center gap-1'>
                                                        <IoCheckmark className='font-xs' />
                                                        <span className='font-uus d-none d-md-block'>
                                                            Copied
                                                        </span>
                                                    </div>
                                                    :
                                                    <div className='d-flex align-items-center gap-1'>
                                                        <IoCopy className='font-xs' />
                                                        <span className='font-uus d-none d-md-block'>
                                                            Copy
                                                        </span>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    })
                }
            </div>
    )
}

const code = memo((props) => {
    const { inline, className, children } = props;
    const match = /language-(\w+)/.exec(className || '');
    const lang = match && match[1];

    if (inline || !lang) {
        return <code className="inline-code">{children}</code>;
    } else {
        return <CodeBlock lang={lang || 'text'} codeChildren={children} />;
    }
});

const p = memo(
    (props) => {
        return <p className=''>{props?.children}</p>;
    }
);

const table = memo(
    (props) => {
        return <div className='markdown-table-container'>{props?.children}</div>;
    }
);

const a = memo(
    (props) => {
        return <a href={props?.href} target="_blank" rel="noreferrer" className='text-decoration-none'>{props?.children}</a>;
    }
);

export default Responses