import React, { useContext, useEffect, useRef, useState } from 'react'
import ProtectedAxios from '../api/protectedAxios'
import { UserContext } from '../context/UserProvider'
import { toast } from 'react-hot-toast'
import { formatDate } from '../utils/helper';
import { RiChatUploadLine } from 'react-icons/ri';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { MdCheck, MdLock, MdNotificationsActive, MdPublic, MdSearch } from 'react-icons/md';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useSubscriptionDetails from '../hooks/useSubscriptionDetails';
import LoadingSkeleton from '../components/LoadingSkeleton';
import { GrFormClose } from 'react-icons/gr';
import { BiFilter, BiCategoryAlt } from 'react-icons/bi';
import { TbWorldShare } from 'react-icons/tb'
import { RiGitRepositoryPrivateLine } from "react-icons/ri";
import logo from '../assets/logo.svg'
import { Helmet } from 'react-helmet';
import TemplateRowAction from '../components/template/TemplateRowAction';
import TemplateBuilderDialog from '../components/template/dialogs/TemplateBuilderDialog';
import { BsLink45Deg } from 'react-icons/bs';
import ConfirmTemplateBuilderClose from '../components/template/dialogs/ConfirmTemplateBuilderClose';

const MyTemplates = ({ selectedUser, setSelectedUser }) => {
  const [user, setUser] = useContext(UserContext)
  const [subscriptionDetail] = useSubscriptionDetails()
  const location = useLocation()
  const navigate = useNavigate()

  //----------------------------------------------------------------------------
  // Templates
  //----------------------------------------------------------------------------
  const [loadingTemplates, setLoadingTemplates] = useState(true)
  const [templates, setTemplates] = useState([])
  const [templateSearchText, setTemplateSearchText] = useState('')
  const [searchingWithText, setSearchingWithText] = useState(false)
  const [searchText, setSearchText] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [marketplaceLinkCopied, setMarketplaceLinkCopied] = useState(false);

  useEffect(() => {
    fetchTemplates()
  }, [])
  useEffect(() => {
    if (templateSearchText.length === 0) {
      fetchTemplates()
    }
  }, [templateSearchText])

  const fetchTemplates = async (e, _selectedCategories, _filterType) => {
    if (e) {
      e?.preventDefault()
    }

    setCategoryDropdownVisible(false)
    setLoadingTemplates(true)

    let selected_categories = _selectedCategories ? _selectedCategories : selectedFilterCategories
    if (templateSearchText.length > 0) {
      setSearchingWithText(true)
      setSearchText(templateSearchText)
    } else {
      setSearchingWithText(false)
    }

    ProtectedAxios.post('/users/fetchMyTemplates', { user_id: user.user_id, selected_user_id: selectedUser?.user_id, templateSearchText: templateSearchText, selectedCategories: selected_categories })
      .then(res => {
        if (res.data) {
          setTemplates(res.data)

          ProtectedAxios.post('/users/getUserTemplateCreatorStatus', { user_id: user.user_id, selected_user_id: selectedUser?.user_id })
            .then(res => {
              if (selectedUser && setSelectedUser) {
                setSelectedUser({ ...selectedUser, is_official_template_creator: res.data.is_official_template_creator })
              } else {
                setUser({ ...user, is_official_template_creator: res.data.is_official_template_creator })
              }

              setLoadingTemplates(false)
            })
        }
      })
      .catch(err => {
        console.log(err);
        toast.error("could not fetch your templates at the moment, please try again later.")
        setLoadingTemplates(false)
      })
  }


  //----------------------------------------------------------------------------
  // New Template
  //----------------------------------------------------------------------------
  const [templateBuilderDialog, setTemplateBuilderDialog] = useState(false);
  const [templateBuilderCloseConfirmationDialog, setTemplateBuilderCloseConfirmationDialog] = useState(false);

  const onBeforeUnload = (ev) => {
    ev.returnValue = "You have unsaved changes. Are you sure you want to leave?";
    return "You have unsaved changes. Are you sure you want to leave?";
  };

  // Update `show` based on the `createTemplate=true` URL param whenever the URL changes
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const createTemplateUrlParam = searchParams.get('createTemplate') === 'true';
    setTemplateBuilderDialog(createTemplateUrlParam);

    if (createTemplateUrlParam) {
      window.addEventListener("beforeunload", onBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [location.search]);

  // Closes the template builder and shows confirm-close dialog
  const showCloseConfirmationDialog = async ({ showCloseConfirmationDialog = true }) => {
    setTemplateBuilderDialog(false);
    setTemplateBuilderCloseConfirmationDialog(true);
  };
  // Closes the confirm-close dialog and shows the template bulder again
  const hideTemplateBuilderCloseConfirmationDialog = () => {
    setTemplateBuilderCloseConfirmationDialog(false);
    setTemplateBuilderDialog(true);
  }
  // Closes the confirm-close dialog and also the template builder, also removes
  // related URL params
  const handleConfirmCloseTemplateBuilder = () => {
    setTemplateBuilderCloseConfirmationDialog(false);
    setTemplateBuilderDialog(false);

    const searchParams = new URLSearchParams(location.search);
    // Remove the 'createTemplate' param
    searchParams.delete('createTemplate');

    // Build the new URL path with the updated search params
    const newUrl = `${location.pathname}?${searchParams.toString()}`;

    // Update the URL without reloading the page
    navigate(newUrl, { replace: true });
  }


  //---------------------------------------------------------------------------- 
  // Category Filters
  //---------------------------------------------------------------------------- 
  const [selectedFilterCategories, setSelectedFilterCategories] = useState([]);
  const [fetchingTemplateCategories, setFetchingTemplateCategories] = useState(false);
  const [templateCategories, setTemplateCategories] = useState([]);
  const [backupTemplateCategories, setBackupTemplateCategories] = useState([]);
  const [searchTemplateCategoryText, setSearchTemplateCategoryText] = useState("");
  const [categoryDropdownVisible, setCategoryDropdownVisible] = useState(false)
  const categoryFilterDropdownRef = useRef(null);

  useClickOutside(categoryFilterDropdownRef);

  useEffect(() => {
    if (categoryDropdownVisible) {
      fetchTemplateCategories();
    }
  }, [categoryDropdownVisible])
  useEffect(() => {
    filterTemplateCategoryList();
  }, [searchTemplateCategoryText])

  const fetchTemplateCategories = () => {
    setFetchingTemplateCategories(true)
    ProtectedAxios.post("/users/fetchUsersTemplateCategories", { user_id: user.user_id, selected_user_id: selectedUser?.user_id, selectedCategories: selectedFilterCategories })
      .then(res => {
        setTemplateCategories(res.data)
        setBackupTemplateCategories(res.data)
        setFetchingTemplateCategories(false)
      })
      .catch(err => {
        console.log(err)
        toast.error("Could not fetch categories at the moment please try again later.")
        setFetchingTemplateCategories(false)
      })
  }
  const selectTemplateCategory = (_category) => {
    setSearchTemplateCategoryText("")
    setSelectedFilterCategories(prev => {
      let updatedCategories = [...prev]
      updatedCategories.push(_category)
      fetchTemplates(undefined, updatedCategories)
      return updatedCategories
    })
  }
  const removeTemplateCategory = (_category) => {
    const filteredCategories = selectedFilterCategories.filter(category => category.category_id !== _category.category_id)
    setSelectedFilterCategories(filteredCategories)
    fetchTemplates(undefined, filteredCategories)
  }
  const filterTemplateCategoryList = () => {
    if (searchTemplateCategoryText.length > 0) {
      const updatedList = backupTemplateCategories.filter(category => {
        return category.category.toLowerCase().includes(searchTemplateCategoryText.toLowerCase())
      })
      setTemplateCategories(updatedList)
    } else {
      setTemplateCategories(backupTemplateCategories)
    }
  }
  function useClickOutside(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setCategoryDropdownVisible(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  return (
    <div className='container py-5'>
      <Helmet>
        <title>Templates - SageCollab</title>
      </Helmet>

      {(selectedUser ? selectedUser?.role_id === 1 : user.role_id === 1)
        &&
        <h2>Create Templates for Marketplace</h2>
      }
      {(selectedUser ? selectedUser?.role_id === 1 : user.role_id === 1)
        ?
        <p>Create prompt templates that users can install from template marketplace and use/edit them as their own.</p>

        :
        <>
        </>
      }

      <div className='template-header'>
        <div className='w-100 d-flex justify-content-end align-items-center my-4 gap-3'>
          <form className='input-with-icon w-100' onSubmit={fetchTemplates}>
            <input
              className='form-input'
              type="text"
              placeholder="search template"
              value={templateSearchText}
              onChange={e => setTemplateSearchText(e.target.value)}
            />
            {templateSearchText.length
              ?
              <button type="submit" className='button-icon edit-btn' title='search'><MdSearch className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>
              :
              <></>
            }
          </form>

          <div className={`category-select-dropdown ${categoryDropdownVisible ? 'dropdown-active' : ''} position-relative`}>
            <button className='edit-btn' onClick={() => setCategoryDropdownVisible(prev => !prev)}>
              <BiFilter className='edit-icon' style={{ fontSize: 'var(--fs-2)' }} />
            </button>
            <div ref={categoryFilterDropdownRef} className={`searchable-dropdown ${categoryDropdownVisible ? 'dropdown-active' : ''}`}>
              <div className="dropdown-content">
                <div className="dropdown-header">
                  <div className='dropdown-title'>
                    <p>Filter by cateogry</p>
                  </div>
                  <input type='text' placeholder='search category' value={searchTemplateCategoryText} onChange={e => setSearchTemplateCategoryText(e.target.value)} />
                </div>

                <div className="category-list">
                  {
                    fetchingTemplateCategories
                      ?
                      <div className="d-flex mt-4 w-100 h-100 gap-2 justify-content-center align-items-center flex-column">
                        <div className="mx-2 spinner-border spinner-border-sm" role="status">
                          <span className="sr-only"></span>
                        </div>
                        <p className="font-xxs">
                          Loading categories
                        </p>
                      </div>
                      :
                      templateCategories.length === 0
                        ?
                        <div className="d-flex mt-4 w-100 h-100 justify-content-center align-items-center flex-column">
                          <BiCategoryAlt className="fs-3" />
                          <p className="font-xxs">
                            Nothing found
                          </p>
                        </div>
                        :
                        <div className="dropdown-categories">
                          {
                            templateCategories.map((category, i) => {
                              return (
                                <div key={i} onClick={() => { selectTemplateCategory(category); setCategoryDropdownVisible(false) }} className={`filter-categories-category tag tag-color-${i % 4 + 1}`}>
                                  {category.category}
                                </div>
                              )
                            })
                          }
                        </div>
                  }
                </div>
              </div>
            </div>
          </div>

        </div>
        {user.role_id === 1
          &&
          <div className='btn-container d-flex flex-row justify-content-end align-items-center gap-2'>
            <button onClick={() => setTemplateBuilderDialog(true)} className='button'>New&nbsp;Template</button>
          </div>
        }
      </div>

      <div className="applied-filters">
        {searchingWithText && templateSearchText.length > 0
          &&
          <span className={`tag tag-search editing`}>search: {searchText} <button type='button' title='remove filter' id='remove-tag-button' className='edit-btn remove-tag-button' onClick={e => { setTemplateSearchText(""); setSearchText(""); fetchTemplates() }}><GrFormClose className={`edit-icon`} /></button></span>
        }
        {selectedFilterCategories.length > 0
          &&
          selectedFilterCategories.map((category, i) => {
            return (
              <span className={`tag tag-color-${i % 4 + 1} editing`}>{category.category} <button type='button' title='remove filter' id='remove-tag-button' className='edit-btn remove-tag-button' onClick={e => { removeTemplateCategory(category) }}><GrFormClose className={`edit-icon`} /></button></span>
            )
          })
        }
      </div>

      {loadingTemplates
        ?
        <div>
          <div className='pt-4'>
            <LoadingSkeleton type='template' />
            <LoadingSkeleton type='template' />
            <LoadingSkeleton type='template' />
            <LoadingSkeleton type='template' />
          </div>
        </div>

        :
        <div className='table-container mb-5 pb-5'>
          <table className='templates-table w-100' >
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Type</th>
                {(selectedUser?.role_id ? selectedUser.role_id === 1 : user.role_id === 1)
                  &&
                  <th>Company</th>
                }
                <th>Categories</th>
                {(selectedUser?.role_id ? selectedUser.role_id !== 1 : user.role_id !== 1)
                  &&
                  <th>Privacy</th>
                }
                <th>Modified</th>
                <th>Action</th>
              </tr>
            </thead>
            {templates.length === 0
              ?
              <tbody>
                <tr>
                  <td colSpan={7}>No templates found</td>
                </tr>
              </tbody>

              :
              <tbody>
                {templates.map((template, i) => {
                  return (
                    <tr key={i}>
                      <td style={{ width: "3rem" }}>{i + 1}</td>
                      <td className='d-flex flex-column gap-2 justify-content-start align-items-start'>
                        {template.name}
                        {template.was_installed_from_marketplace
                          ?
                          <span className='shared-tag' title="This tempate was installed from marketplace"><img src={logo} alt="logo" className='w-xxs h-xxs' /> Marketplace</span>
                          : <></>
                        }
                        {template.is_shared
                          ?
                          <span className='shared-tag'>shared with you</span>
                          : <></>
                        }
                        <div className='d-flex align-items-center gap-1'>
                          {template.is_sharing
                            &&
                            <OverlayTrigger
                              delay={{ hide: 450, show: 300 }}
                              overlay={(props) => (
                                <Tooltip {...props}>
                                  This template is shared
                                </Tooltip>
                              )}
                              placement="right"
                            >
                              <button className='button-icon'>
                                <TbWorldShare className='font-s text-secondary' />
                              </button>
                            </OverlayTrigger>
                          }
                          {template.notify_creator_on_use
                            &&
                            <OverlayTrigger
                              delay={{ hide: 450, show: 300 }}
                              overlay={(props) => (
                                <Tooltip {...props}>
                                  When some use this template you will be notified by email
                                </Tooltip>
                              )}
                              placement="right"
                            >
                              <button className='button-icon'>
                                <MdNotificationsActive className='font-s text-secondary' />
                              </button>
                            </OverlayTrigger>
                          }
                          {template.is_password_protected
                            &&
                            <OverlayTrigger
                              delay={{ hide: 450, show: 300 }}
                              overlay={(props) => (
                                <Tooltip {...props}>
                                  This template is password protected
                                </Tooltip>
                              )}
                              placement="right"
                            >
                              <button className='button-icon'>
                                <MdLock className='font-s text-secondary' />
                              </button>
                            </OverlayTrigger>
                          }
                        </div>
                      </td>
                      {selectedUser?.role_id ? selectedUser.role_id === 1 : user.role_id === 1
                        &&
                        <td>
                          {template.selectedCompany !== null
                            ? template.selectedCompany.company_name
                            :
                            <span className='d-flex justify-content-center'>-</span>
                          }
                        </td>
                      }
                      <td>{template.type === "CHAT_STARTER_PRIVATE" ? "Private Chat Starter" : template.type === "CHAT_STARTER_OPEN" ? "Open Chat Starter" : "Normal"}</td>
                      <td>
                        {template.selectedCategories.length === 0
                          ? ' - '
                          :
                          template.selectedCategories.map(category => category.category.toLowerCase()).join(", ")
                        }
                      </td>
                      {(selectedUser?.role_id ? selectedUser.role_id !== 1 : user.role_id !== 1)
                        &&
                        <td className='pe-3'>
                          {
                            template.is_public
                              ?
                              <div className='d-flex gap-1 align-items-center'>
                                <MdPublic /><span>Public</span>
                                <OverlayTrigger
                                  placement="right"
                                  overlay={(props) => (
                                    <Tooltip {...props}>
                                      {(marketplaceLinkCopied && selectedTemplate?.template_id === template.template_id)
                                        ? "Copied"
                                        : "Copy marketplace link"
                                      }
                                    </Tooltip>
                                  )}
                                >
                                  <button
                                    className="button-icon"
                                    onClick={() => navigator.clipboard.writeText(`${window.location.origin}/marketplace/template/${template.template_id}`)
                                      .then(() => {
                                        setSelectedTemplate(template);
                                        setMarketplaceLinkCopied(true);

                                        setTimeout(() => {
                                          setSelectedTemplate(null);
                                          setMarketplaceLinkCopied(false);
                                        }, 3000)
                                      })
                                    }
                                  >
                                    {(marketplaceLinkCopied && selectedTemplate?.template_id === template.template_id)
                                      ? <MdCheck className="font-xs text-success" />
                                      : <BsLink45Deg className="font-s color-dark" />
                                    }
                                  </button>
                                </OverlayTrigger>
                              </div>

                              :
                              <div className='d-flex gap-1 align-items-center'>
                                <RiGitRepositoryPrivateLine /><span>Private</span>
                              </div>
                          }
                          {
                            template?.selectedCompany?.company_id
                            &&
                            <span className='shared-tag mt-2' title={`${template?.selectedCompany?.company_name}`}>Official</span>
                          }
                        </td>
                      }
                      <td>{formatDate(new Date(template.updated_at))}</td>

                      <td className='py-md-1 d-flex align-items-center'>
                        <OverlayTrigger
                          delay={{ hide: 450, show: 300 }}
                          overlay={(props) => (
                            <Tooltip {...props}>
                              Use template in chat
                            </Tooltip>
                          )}
                          placement="left"
                        >
                          <Link to={`/start-chat?templateId=${template.template_id}`} className='button-icon'><RiChatUploadLine className='' /></Link>
                        </OverlayTrigger>

                        <TemplateRowAction
                          templates={templates}
                          setTemplates={setTemplates}
                          iteratingTemplate={template}
                          user={selectedUser ? selectedUser : user}
                        />
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            }
          </table>
        </div>
      }

      <TemplateBuilderDialog
        dialogState={templateBuilderDialog}
        showCloseConfirmationDialog={showCloseConfirmationDialog}
        closeTemplateBuilder={handleConfirmCloseTemplateBuilder}
        setTemplates={setTemplates}
        user={selectedUser ? selectedUser : user}
        mode={selectedUser ? "ADMIN" : "USER"}
        subscriptionDetail={subscriptionDetail}
      />
      <ConfirmTemplateBuilderClose
        open={templateBuilderCloseConfirmationDialog}
        onOpenChange={setTemplateBuilderCloseConfirmationDialog}
        hideTemplateBuilderCloseConfirmationDialog={hideTemplateBuilderCloseConfirmationDialog}
        handleConfirmCloseTemplateBuilder={handleConfirmCloseTemplateBuilder}
      />
    </div >
  )
}

export default MyTemplates