import { useEffect, useState } from "react";
import { BsArrowDownShort } from "react-icons/bs";

const ScrollToBottomButton = ({ offset = 500 }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const isAtBottom =
                window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - offset;
            setIsVisible(!isAtBottom);
        };

        window.addEventListener("scroll", handleScroll);
        handleScroll(); // Initial check

        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        isVisible && (
            <button className="button-icon scroll-down-button" onClick={() => window.scrollTo({ top: document.documentElement.scrollHeight, behavior: "smooth" })}>
                <BsArrowDownShort />
            </button>
        )
    );
};

export default ScrollToBottomButton;
