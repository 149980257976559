import React from 'react'
import BedrockApiKeysSetting from '../chat/settings/settingTabs/BedrockApiKeysSetting';
import { Dialog } from '../ui/Dialog';


const AddBedrockAPIkeyDialog = ({ open, onOpenChange, title, message, selectedUser, setSelectedUser, handleSubmit }) => {

    return (
        <Dialog
            open={open}
            onOpenChange={onOpenChange}
            title={title ? title : "API keys not found"}
            hideFooter
            body={
                <div className='mb-4'>
                    <img src="https://www.outsystems.com/Forge_CW/_image.aspx/Q8LvY--6WakOw9afDCuuGbQ9u-QKbiqiEaG1FDMiKVo=/aws-bedrock-runtime-2023-01-04%2000-00-00-2024-09-12%2014-12-44" alt="" className='rounded-circle' style={{ width: '25px' }} />
                    <p className='mt-2'>{message}</p>
                    <BedrockApiKeysSetting
                        open={open}
                        onHide={onOpenChange}
                        handleSubmit={handleSubmit}
                        selectedUser={selectedUser}
                        setSelectedUser={setSelectedUser}
                        activeTab="bedrock"
                    />
                </div>
            }
        />
    )
}

export default AddBedrockAPIkeyDialog