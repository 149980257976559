import { useContext, useEffect, useRef, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { UserContext } from '../context/UserProvider';
import ProtectedAxios from '../api/protectedAxios';
import useSubscriptionDetails from '../hooks/useSubscriptionDetails';
import Tokens from './Tokens'
import { toast } from 'react-hot-toast';
import logo from '../assets/logo.svg'
import Axios from '../api/axios';
import TemplateMarketplaceIcon from './custom-svgs/templateMarketplaceIcon';
import Tabs from './Tabs';
import OpenaiWithSelfApiKeyDownNoticeDialog from './dialogs/OpenaiWithSelfApiKeyDownNoticeDialog';
import Settings from './chat/settings/Settings';
import TemplateMarketplaceIconAlt from './custom-svgs/templateMarketplaceIconAlt';
import TemplateMarketplaceIconPlusAlt from './custom-svgs/templateMarketplaceIconPlusAlt';

function NavbarHome({ tokens }) {
  const [user, setUser] = useContext(UserContext)
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [processing, setProcessing] = useState(false)
  const [loggingOut, setLoggingOut] = useState(false)
  const urlParams = new URLSearchParams(window.location.search);
  const redirectTo = urlParams.get('redirectTo');
  const navRef = useRef(null)

  const [subscriptionDetail] = useSubscriptionDetails()

  let { pathname } = useLocation()
  pathname = pathname.slice(1, pathname.length)

  const navigate = useNavigate()

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.height = "100dvh"
      document.body.style.overflowY = "hidden"
    } else {
      document.body.style.height = "auto"
      document.body.style.overflowY = "scroll"
    }
  }, [isMenuOpen])

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        navRef.current.classList.add('add-shadow')
      }
      else {
        navRef.current.classList.remove('add-shadow')

      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const logout = () => {
    setLoggingOut(true)
    toast.dismiss()
    navigate("/")
    setUser({
      accessToken: '',
      refreshToken: '',
      name: '',
      email: '',
      role_id: ''
    })
    Axios.post('/auth/logout', { refresh_token: user.refreshToken })
      .then(res => {
        setLoggingOut(false)
      })
      .catch(err => {
        setLoggingOut(false)
        if (err.response.status === 500) {
          toast.error(err.response.data.error)
        }
      })
    window.location.href = "/"
  }

  const createPortalSession = () => {
    setProcessing(true)
    ProtectedAxios.post('/subscription/create-portal-session', { customer_id: user.stripe_customer_id, current_path: pathname })
      .then(res => {
        window.location.href = res.data
        setProcessing(false)
      })
      .catch(err => {
        setProcessing(false)
        if (err.response.status === 500) {
          // toast.error(err.response.data.error)
          toast.error("Can't create subscription management session at the moment, please try again later")
        }
      })
  }


  return (
    <nav className="main-nav" ref={navRef}>
      {user.role_id !== 1 && user.username
        &&
        <OpenaiWithSelfApiKeyDownNoticeDialog />
      }

      {/* <div className='header'>
        <div className='container'>
          <div id="google_translate_element"></div>
        </div>
      </div> */}
      <div className='main container'>
        <div className="nav-left">
          <NavLink to="/" className="logo notranslate">
            <div className="home-branding">
              <img
                alt="logo"
                src={logo}
                className="home-logo"
              />
              <span className="home-company">SageCollab</span>
            </div>
          </NavLink>
        </div>

        <div className='nav-right'>

          {user?.accessToken === ""
            ?
            <>
              <NavLink to={`/login${redirectTo ? `?redirectTo=${redirectTo}` : ''}`} className={`nav-btn`}>Login</NavLink>
              <NavLink to={`/register${redirectTo ? `?redirectTo=${redirectTo}` : ''}`}>Register</NavLink>
            </>

            :
            <>
              {user?.role_id === 1
                ?   //ADMIN LINKS
                <>
                  <NavLink to="/">Home</NavLink>
                  <NavLink to="/user-stats">User Stats</NavLink>
                  <NavLink to="/app-stats">App Stats</NavLink>
                  <NavLink to="/manage-users">Manage Users</NavLink>
                  <NavLink to="/add-enterprise-user">Enterprise User</NavLink>

                  <Dropdown className='dropdown-link'>
                    <Dropdown.Toggle>
                      Manage App
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <NavLink className='dropdown-item' to="/add-user">Add User</NavLink>
                      <NavLink className='dropdown-item' to="/manage-models">Manage Models</NavLink>
                      <NavLink className='dropdown-item' to="/prompt-templates">Marketplace Templates</NavLink>
                      <NavLink className='dropdown-item' to="/highlighted-template">Highlighted Template</NavLink>
                      <NavLink className='dropdown-item' to="/highlighted-template-categories">Highlighted Template Categories</NavLink>
                      <NavLink className='dropdown-item' to="/public-templates">Public Templates</NavLink>
                      <NavLink className='dropdown-item' to="/manage-explorer-prompts">Explorer Prompts</NavLink>
                      <NavLink className='dropdown-item' to="/email-templates">Email Templates</NavLink>
                      <NavLink className='dropdown-item' to="/tools">Tools</NavLink>
                    </Dropdown.Menu>
                  </Dropdown>

                  {/* <NavLink to="/manage-enterprise-accounts">Manage Enterprise</NavLink> */}
                </>

                :
                //USERS LINKS
                <>
                  {user.stripe_subscription_id
                    &&
                    <>
                      {subscriptionDetail?.price_id && user.role_id !== 1
                        &&
                        <>
                          {subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_B_ID
                            &&
                            <Tokens tokens={tokens} />
                          }
                          {subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_C_ID
                            &&
                            <>
                              <Tokens tokens={tokens} />
                            </>
                          }
                        </>
                      }
                    </>
                  }
                  {user.role_id !== 1 && user.username
                    &&
                    <button title="find new templates on template marketplace" className="bg-transparent border-0 shine-hover" onClick={(e) => { navigate(`/?activeTab=4&activeTemplateTab=0`) }}><TemplateMarketplaceIconPlusAlt className='mb-2' fill="#222" style={{width:"var(--size-xl)", height:"var(--size-xl)"}} /></button>
                  }
                </>
              }

              <Dropdown className='dropdown-link'>
                <Dropdown.Toggle>
                  <div className='profile-pic' style={{ backgroundImage: `url(${user.profile_picture_src})` }} />
                </Dropdown.Toggle>
                <Dropdown.Menu style={{ minWidth: "13rem" }}>
                  <div className='px-3 py-1'>
                    <p className='m-0 fw-medium' title={user.name}>{user.name.substring(0, 16)}{user.name.length > 16 && "..."}</p>
                    {user?.username
                      ? <p className='m-0 text-secondary' title={user.username}>{user.username.substring(0, 20)}{user.username.length > 20 && "..."}</p>
                      : <p className='m-0 text-secondary' title={user.email}>{user.email.substring(0, 20)}{user.email.length > 20 && "..."}</p>
                    }
                  </div>

                  <hr className='my-2' />

                  {user.username
                    &&
                    <>
                      {subscriptionDetail?.price_id && user?.stripe_subscription_id
                        &&
                        <>
                          {subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_C_ID
                            &&
                            <NavLink className='dropdown-item' to="/manage-accounts">Manage Accounts</NavLink>
                          }
                        </>
                      }

                      {user.role_id != 1
                        &&
                        <NavLink className='dropdown-item' to="/access-keys">Access Keys</NavLink>
                      }

                      {user.role_id != 1
                        &&
                      <Settings viewAsLisk={true} text="Chat Settings" className="dropdown-item" />
                      }

                      <NavLink className='dropdown-item' to="/profile">My Profile</NavLink>

                      {subscriptionDetail?.price_id && user?.stripe_subscription_id
                        &&
                        <>
                          {subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_A_ID
                            &&
                            <NavLink className='dropdown-item' to="/upgrade">Upgrade Subscription</NavLink>
                          }
                        </>
                      }

                    </>
                  }

                  <a onClick={logout} className='dropdown-item cursor-pointer' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '1rem', color: `${loggingOut ? 'gray' : '#222'}` }}>
                    Logout
                    {loggingOut
                      &&
                      <div class="spinner-border spinner-border-sm" role="status">
                        <span class="sr-only"></span>
                      </div>
                    }
                  </a>
                </Dropdown.Menu>
              </Dropdown>
            </>
          }
        </div>


        <div className='nav-right-mobile'>
          <div className='nav-token-container'>
            {subscriptionDetail?.price_id
              &&
              <>
                {subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_B_ID
                  &&
                  <Tokens tokens={tokens} />
                }
                {subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_C_ID
                  &&
                  <Tokens tokens={tokens} />
                }
              </>
            }
          </div>

          <div className='toggle-btn-container'>
            <input type="checkbox" checked={isMenuOpen} onChange={() => setIsMenuOpen(!isMenuOpen)} />
            <div className='toggle-btn' style={{ justifyContent: `${isMenuOpen ? 'center' : 'space-evenly'}` }}>
              <span className='line1' style={{ transform: `rotate(${isMenuOpen ? '45deg' : '0deg'}) translateY(${isMenuOpen ? '2px' : '0'})` }} />
              <span className='line2' style={{ opacity: `${isMenuOpen ? 0 : 1}` }} />
              <span className='line3' style={{ transform: `rotate(${isMenuOpen ? '-45deg' : '0deg'}) translateY(${isMenuOpen ? '-1px' : '0'})` }} />
            </div>
          </div>
        </div>


      </div>

      {/* ========   MOBILE MENU  ========= */}
      <div className='nav-menu' style={{ right: `${isMenuOpen ? '0' : '-100%'}` }}>
        <div>
          <div className="nav-left container">
            <NavLink to="/" className="logo notranslate">
              <div className="home-branding">
                <img
                  alt="logo"
                  src={logo}
                  className="home-logo"
                />
                <span className="home-company">SageCollab</span>
              </div>
            </NavLink>
          </div>

          {user.accessToken
            &&
            <div className='container'>
              <div className='py-1 d-flex align-items-center gap-2'>
                <div className='profile-pic  ' style={{ backgroundImage: `url(${user.profile_picture_src})` }} />
                <div>
                  <p className='m-0 fw-medium' title={user.name}>{user.name.substring(0, 16)}{user.name.length > 16 && "..."}</p>
                  {user?.username
                    ?
                    <p className='m-0 text-secondary' title={user.username}>{user.username.substring(0, 20)}{user.username.length > 20 && "..."}</p>

                    :
                    <p className='m-0 text-secondary' title={user.email}>{user.email.substring(0, 20)}{user.email.length > 20 && "..."}</p>
                  }
                </div>
              </div>
              <hr />
            </div>
          }

          <div className='mobile-links-container'>
            {user.accessToken === ""
              ? <>
                <NavLink to="/login" className={``} onClick={() => setIsMenuOpen(!isMenuOpen)}>Login</NavLink>
                <NavLink to="/register" onClick={() => setIsMenuOpen(!isMenuOpen)}>Register</NavLink>
              </>

              :
              <>
                {user?.role_id === 1
                  ?   //ADMIN LINKS
                  <>
                    <NavLink to="/" onClick={() => setIsMenuOpen(!isMenuOpen)}>Home</NavLink>
                    <NavLink to="/user-stats" onClick={() => setIsMenuOpen(!isMenuOpen)}>User Stats</NavLink>
                    <NavLink to="/app-stats" onClick={() => setIsMenuOpen(!isMenuOpen)}>App Stats</NavLink>
                    <NavLink to="/manage-users" onClick={() => setIsMenuOpen(!isMenuOpen)}>Manage Users</NavLink>
                    <NavLink to="/add-user" onClick={() => setIsMenuOpen(!isMenuOpen)}>Add User</NavLink>
                    <NavLink to="/add-enterprise-user" onClick={() => setIsMenuOpen(!isMenuOpen)}>Enterprise User</NavLink>
                    <NavLink to="/manage-models" onClick={() => setIsMenuOpen(!isMenuOpen)}>Manage Models</NavLink>
                    <NavLink to="/prompt-templates" onClick={() => setIsMenuOpen(!isMenuOpen)}>Marketplace Templates</NavLink>
                    <NavLink to="/highlighted-template" onClick={() => setIsMenuOpen(!isMenuOpen)}>Highlighted Template</NavLink>
                    <NavLink to="/highlighted-template-categories" onClick={() => setIsMenuOpen(!isMenuOpen)}>Highlighted Template Categories</NavLink>
                    <NavLink to="/public-templates" onClick={() => setIsMenuOpen(!isMenuOpen)}>Public Templates</NavLink>
                    <NavLink to="/manage-explorer-prompts" onClick={() => setIsMenuOpen(!isMenuOpen)}>Explorer Prompts</NavLink>
                    <NavLink to="/email-templates" onClick={() => setIsMenuOpen(!isMenuOpen)}>Email Templates</NavLink>
                    <NavLink to="/tools" onClick={() => setIsMenuOpen(!isMenuOpen)}>Tools</NavLink>
                    {/* <NavLink to="/manage-enterprise-accounts" onClick={() => setIsMenuOpen(!isMenuOpen)}>Manage Enterprise</NavLink> */}
                    <NavLink to="/profile" onClick={() => setIsMenuOpen(!isMenuOpen)}>My Profile</NavLink>
                  </>

                  :
                  //USER LINKS
                  user.username
                  &&
                  <>
                    <NavLink to="/" onClick={() => setIsMenuOpen(!isMenuOpen)}>Home</NavLink>
                    <NavLink to="/access-keys" onClick={() => setIsMenuOpen(!isMenuOpen)}>Access Keys</NavLink>
                    <Settings onClick={() => setIsMenuOpen(!isMenuOpen)} viewAsLisk={true} text="Chat Settings" className="cursor-pointer"/>
                    <NavLink to="/profile" onClick={() => setIsMenuOpen(!isMenuOpen)}>My Profile</NavLink>
                    {user.stripe_subscription_id
                      &&
                      <>
                        {subscriptionDetail?.price_id
                          &&
                          <>
                            {subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_A_ID
                              ?
                              <NavLink to="/upgrade" onClick={() => setIsMenuOpen(!isMenuOpen)}>Upgrade Subscription</NavLink>
                              :
                              <>
                                {subscriptionDetail?.price_id === process.env.REACT_APP_PRICE_C_ID
                                  &&
                                  <>
                                    <NavLink to="/manage-accounts" onClick={() => setIsMenuOpen(!isMenuOpen)}>Manage Accounts</NavLink>
                                  </>
                                }
                              </>
                            }
                          </>
                        }
                      </>
                    }
                  </>
                }
                <a className='cursor-pointer' onClick={() => { logout(); setIsMenuOpen(false) }}>Logout</a>
              </>
            }
          </div>
        </div>

        {user.accessToken && user.role_id !== 1 && user.username
          &&
          <Tabs showingInMobileNav={true} setIsMenuOpen={setIsMenuOpen} className="" style={{ bottom: "-1px" }} />
        }

      </div>
    </nav>

  );
}

export default NavbarHome;