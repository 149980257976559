import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../context/UserProvider';
import { RiCheckLine, RiCloseLine } from 'react-icons/ri'
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
import { FiExternalLink } from 'react-icons/fi'
import ProtectedAxios from '../../api/protectedAxios';
import { toast } from 'react-hot-toast'
import { Dialog } from "../ui/Dialog"

const AddAzureOpenaiKeysDialog = ({ open, onOpenChange, title, message, selectedUser, setSelectedUser, handleSubmit }) => {
    const [user, setUser] = useContext(UserContext)
    const [addingKeys, setAddingKeys] = useState(false)
    const [isKeyValid, setIsKeyValid] = useState(false)
    const [validatingKeys, setValidatingKeys] = useState(false)

    const [key, setKey] = useState('');
    const [endpoint, setEndpoint] = useState('');
    const [deploymentId, setDeploymentId] = useState('');

    const models = [
        { title: "gpt-3.5-turbo", model_name: "gpt-35-turbo" },
        { title: "gpt-4", model_name: "gpt-4" },
        { title: "gpt-4o", model_name: "gpt-4o" },
    ];
    const [selectedModel, setSelectedModel] = useState(null);

    const [error, setError] = useState("");
    const [viewingKey, setViewingKey] = useState(false);

    useEffect(() => {
        if (selectedUser && setSelectedUser) {
            setKey(selectedUser?.azure_openai_api_key ? selectedUser.azure_openai_api_key : '');
            setEndpoint(selectedUser?.azure_openai_endpoint ? selectedUser.azure_openai_endpoint : '');
            setDeploymentId(selectedUser?.azure_deployment_id ? selectedUser.azure_deployment_id : '');
            setSelectedModel(selectedUser?.azure_openai_model ? selectedUser.azure_openai_model : null);
        } else {
            setKey(user?.azure_openai_api_key ? user.azure_openai_api_key : '');
            setEndpoint(user?.azure_openai_endpoint ? user.azure_openai_endpoint : '');
            setDeploymentId(user?.azure_deployment_id ? user.azure_deployment_id : '');
            setSelectedModel(user?.azure_openai_model ? user.azure_openai_model : null);
        }
    }, [selectedUser, user]);

    const addAzureOpenaiKeys = e => {
        e.preventDefault();
        setAddingKeys(true);
        ProtectedAxios.post('/users/addAzureOpenaiKeys', { user_id: user.user_id, selected_user_id: selectedUser?.user_id ? selectedUser.user_id : user.user_id, key, endpoint, deploymentId, selectedModel })
            .then(res => {
                if (selectedUser && setSelectedUser) {
                    setSelectedUser({ ...selectedUser, azure_openai_api_key: res.data.azure_openai_api_key, azure_openai_endpoint: res.data.azure_openai_endpoint, azure_deployment_id: res.data.azure_deployment_id, azure_openai_model: res.data.azure_openai_model });
                } else {
                    setUser({ ...user, azure_openai_api_key: res.data.azure_openai_api_key, azure_openai_endpoint: res.data.azure_openai_endpoint, azure_deployment_id: res.data.azure_deployment_id, azure_openai_model: res.data.azure_openai_model });
                }
                setAddingKeys(false);
                toast('Azure OpenAI Keys are saved', {
                    icon: "🔑"
                });
                onOpenChange();
                if (handleSubmit) {
                    handleSubmit(null, key);
                }
            })
            .catch(err => {
                console.log(err);
                toast.error(err.response.data.error)
                setAddingKeys(false)
            })
    }

    useEffect(() => {
        if (open) {
            if (key.length > 0 && endpoint.length > 0 && deploymentId.length > 0) {
                setTimeout(() => {
                    checkAzureOpenaiKeys();
                }, 1000);
            }
        }
    }, [open, key, endpoint, deploymentId]);

    const checkAzureOpenaiKeys = async () => {
        if (key.length > 0 && endpoint.length > 0 && deploymentId.length > 0) {
            setValidatingKeys(true);
            setError("");
            ProtectedAxios.post('/users/verifyAzureOpenaiKeys', { user_id: user.user_id, key, endpoint, deploymentId })
                .then(res => {
                    if (res.data.isValid) {
                        setIsKeyValid(true);
                        setValidatingKeys(false);
                        setError("");
                    }
                    else {
                        setIsKeyValid(false);
                        setValidatingKeys(false);

                        if (res.data?.message) {
                            setError(res.data.message);
                        }
                    }
                })
                .catch(err => {
                    setValidatingKeys(false);
                    console.log(err);
                    toast.error(err.response.data.error);
                })
        }
    };

    useEffect(() => {
        if (!open) {
            setError("");
        }
    }, [open]);

    return (
        <Dialog
            open={open}
            onOpenChange={onOpenChange}
            title={title ? title : "API key not found"}
            hideFooter
            body={
                <form onSubmit={addAzureOpenaiKeys} className="mb-3">
                    <div className='d-flex align-items-center gap-2 mb-3'>
                        <img src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/azure-icon.png" alt="" style={{ width: '25px' }} />
                        <span style={{ display: "flex", height: "1.3rem", width: "1px", backgroundColor: "gray" }} />
                        <img src="https://www.svgrepo.com/show/306500/openai.svg" alt="" style={{ width: '25px' }} />
                    </div>
                    <p className='font-s'>{message ? message : 'Add your OpenAI API key'}</p>
                    <div className={`mx-0 px-0 pt-3`}>
                        <div className='profile-item-header px-0 py-0 mb-4 flex-column'>
                            <div className='input-grp'>
                                <label>API Key</label>
                                <div className='input-with-icon'>
                                    <input required type={viewingKey ? 'text' : 'password'} id='key' value={key} onChange={e => { setKey(e.target.value) }} className='' disabled={validatingKeys || addingKeys} />
                                    {viewingKey
                                        ?
                                        <button type="button" className='button-icon edit-btn' title='hide key' onClick={() => { setViewingKey(false) }}><AiFillEyeInvisible className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>

                                        :
                                        <button type="button" className='button-icon edit-btn' title='view key' onClick={() => { setViewingKey(true) }}><AiFillEye className='edit-icon reject-icon ' style={{ fill: '#404040' }} /></button>
                                    }
                                </div>
                            </div>

                            <div className='input-grp'>
                                <label>Endpoint</label>
                                <div className='input-with-icon'>
                                    <input required type='text' id='endpoint' value={endpoint} onChange={e => { setEndpoint(e.target.value) }} className='' disabled={validatingKeys || addingKeys} />
                                </div>
                            </div>

                            <div className='input-grp'>
                                <label>Deployment ID</label>
                                <div className='input-with-icon'>
                                    <input required type='text' id='deployment-id' value={deploymentId} onChange={e => { setDeploymentId(e.target.value) }} className='' disabled={validatingKeys || addingKeys} />
                                </div>
                            </div>

                            <div className='input-grp'>
                                <label>Model name</label>
                                <select
                                    id='gpt-models'
                                    className="form-select"
                                    value={selectedModel}
                                    onChange={e => setSelectedModel(e.target.value)}
                                    disabled={validatingKeys || addingKeys}
                                >
                                    {models.map((model, i) => {
                                        return (
                                            <option key={i} value={model.model_name}>{model.title}</option>
                                        )
                                    })}
                                </select>
                                <span className="font-xxs text-secondary">Choose the same model as on your Azure OpenAI</span>
                            </div>

                        </div>

                        <div className='profile-item-footer'>
                            <p className='text-danger'>{error}</p>
                        </div>
                        <p className='text-left'>Unsure where to find your Azure OpenAI keys? Check <a target="_blank" href="https://sagecollab.com/how-to-find-your-azure-openai-keys" rel="noreferrer">this guide <FiExternalLink /></a></p>
                    </div>
                    <div className='d-flex align-items-center justify-content-between'>
                        <div className='btn-row-container left my-3'>
                            {(key.length > 0 && endpoint.length > 0 && deploymentId.length > 0)
                                ?
                                <>
                                    {validatingKeys
                                        ?
                                        <div className='d-flex font-xs justify-content-center align-items-center'>
                                            Validating
                                            <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div>

                                        :
                                        <>
                                            {key.length > 0
                                                &&
                                                <>
                                                    {isKeyValid
                                                        ?
                                                        <div className='d-flex font-xs justify-content-center align-items-center gap-1'>
                                                            <RiCheckLine className='edit-icon accept-icon ' />
                                                            Valid Keys
                                                        </div>

                                                        :
                                                        <div className='d-flex font-xs justify-content-center align-items-center gap-1'>
                                                            <RiCloseLine className='edit-icon reject-icon ' />
                                                            Invalid Keys
                                                        </div>
                                                    }
                                                </>
                                            }
                                        </>
                                    }
                                </>

                                : ''
                            }
                        </div>
                        <button type="submit" className="button button-main gap-2" disabled={addingKeys || !isKeyValid}>
                            {addingKeys
                                &&
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            }
                            {handleSubmit ? "Add & Continue" : "Add Key"}
                        </button>
                    </div>
                </form>
            }
        />
    )
}

export default AddAzureOpenaiKeysDialog