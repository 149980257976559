import React, { useContext, useEffect, useLayoutEffect, useState } from 'react'
import CustomModal from './CustomModal'
import { UserContext } from '../../context/UserProvider';
import { RiCloseLine } from 'react-icons/ri';
import { useLocation } from 'react-router-dom';

const OpenaiWithSelfApiKeyDownNoticeDialog = () => {
    const [user, setUser] = useContext(UserContext)
    const [open, setOpen] = useState(false)
    const [modalState, setModalState] = useState(false)

    let { pathname } = useLocation()
    pathname = pathname.slice(1, pathname.length)

    useEffect(() => {
        setTimeout(() => {
            if (user?.accessToken) {
                if (user?.openai_with_self_api_key_down_notice_popup_shown === undefined) {
                    // setOpen(true)
                    setUser(prev => { return { ...prev, openai_with_self_api_key_down_notice_popup_shown: true } })
                }
                else {
                    if (user?.openai_with_self_api_key_down_notice_popup_shown === false) {
                        setOpen(false)
                        setUser(prev => { return { ...prev, openai_with_self_api_key_down_notice_popup_shown: true } })
                    }
                }
            }
        }, 1000)
    }, [user, open])


    return (
        <>
            {pathname === ""
                &&
                open
                &&
                <div className='background-dark py-2' style={{ borderRadius: "0 0 4px 4px" }}>
                    <div className='d-flex align-items-center justify-content-between container gap-3'>
                        <p className='m-0 font-xxs'>
                            <button
                                className='button-plain-text w-auto h-auto text-start d-block font-us color-primary'
                                onClick={() => setModalState(true)}
                            >
                                Important Update for OpenAI API Key Users, <span className='text-primary d-inline font-us'>View Details</span>
                            </button>
                        </p>

                        <button className='edit-btn h-auto w-auto color-primary' onClick={() => setOpen(false)}><RiCloseLine className='w-s h-s' /></button>
                    </div>
                </div>
            }

            <CustomModal
                show={modalState}
                onHide={setModalState}
                title="OpenAI notice"
                content={
                    <div className='my-3 pb-2'>
                        <div className='d-flex align-items-center gap-2'>
                            <img src="https://www.svgrepo.com/show/306500/openai.svg" style={{ width: '25px' }} />
                            <h3 className='m-0 font-xs'>Update for OpenAI model!</h3>
                        </div>

                        <div className='font-xs'>
                            <p className='mt-3'>We're currently experiencing some issues with the OpenAI model when using personal API keys. This may affect even paid users with personal API keys.</p>

                            <h4 className='font-xs pt-2'>Here's how you can keep the conversation flowing!</h4>
                            <ol>
                                <li className='mt-3'><span className='fw-medium'>Gemini Model: </span>If you’re using the Gemini model, continue chatting as usual.</li>
                                <li className='mt-3'><span className='fw-medium'>Paid Subscribers: </span>If you're a paid subscribers, we encourage you to use SageCollab Tokens.</li>
                                <li className='mt-3'><span className='fw-medium'>OpenAI Model with Personal API Key: </span>For users using their own API keys, we advise switching to the Gemini model temporarily. This will help avoid any quota-related issues until this issue is resolved on OpenAI side.</li>
                            </ol>
                        </div>

                        <p className='pt-3'>We apologize for any inconvenience this may cause. In the meantime, choose the option that works best for you!</p>
                    </div>
                }
                showFooter={false}
            />
        </>
    )
}

export default OpenaiWithSelfApiKeyDownNoticeDialog