import React, { useState } from 'react'
import ProtectedAxios from '../api/protectedAxios';
import { toast } from 'react-hot-toast';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { Dialog } from './ui/Dialog';

const ManageAccessModal = ({ showAsIcon = false, selectedUser, setSelectedUser, setUserDetails }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true);

    const [togglingTemplateCreatorStatus, setTogglingTemplateCreatorStatus] = useState(false)

    const toggleTemplateCreatorStatus = async (e) => {
        setTogglingTemplateCreatorStatus(true)
        ProtectedAxios.post('/admin/toggleTemplateCreatorStatus', { selected_user_id: selectedUser.user_id })
            .then(res => {
                if (res.data) {
                    setSelectedUser(prev => { return { ...prev, is_official_template_creator: res.data.updated_access } })
                    setUserDetails(prev => { return { ...prev, is_official_template_creator: res.data.updated_access } })
                }
                setTogglingTemplateCreatorStatus(false)
            })
            .catch(err => {
                console.log(err);
                toast.error(err?.response?.data?.error)
                setTogglingTemplateCreatorStatus(false)
            })
    }
    return (
        <Dialog
            open={show}
            onOpenChange={handleClose}
            title='Manage access'
            hideFooter
            hideTrigger={false}
            trigger={
                showAsIcon
                    ?
                    <button className='edit-btn' id='share-button' title='Manage Access' onClick={e => { handleShow() }}><RiDeleteBin5Line className='edit-icon' /></button>
                    :
                    <div
                        onClick={handleShow}
                    >
                        Manage Access
                    </div>
            }
            body={
                <div className='px-2'>
                    <p>
                        <br />
                        Name: {selectedUser?.name}
                        <br />
                        Username: {selectedUser?.username}
                        <br />

                        <div className='my-5'>
                            <div>
                                <div className='form-check d-sm-block'>
                                    <input className='form-check-input p-0 m-0' disabled={togglingTemplateCreatorStatus} id='templateCreatorStatusCheckbox' type='checkbox' checked={selectedUser?.is_official_template_creator} onClick={toggleTemplateCreatorStatus} />
                                    <label className="form-check-label m-0 p-0 mx-2" htmlFor="templateCreatorStatusCheckbox" style={{ marginLeft: "5px" }}>
                                        Can create official templates

                                        {togglingTemplateCreatorStatus
                                            &&
                                            <div className="mx-2 spinner-border spinner-border-sm" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        }
                                    </label>
                                </div>
                            </div>
                        </div>
                    </p>
                </div>
            }
        />
    )
}

export default ManageAccessModal;